export const EnglishOptions = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
    },
    title: {
        text: "外語自學累計次數",
    },
    lang: {
        noData: "無資料"
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
        }
    },
    tooltip: {
        pointFormat: "{series.name}: <b>{point.y:.1f} 次</b>",
    },
    accessibility: {
        point: {
            valueSuffix: "%",
        },
    },
    plotOptions: {
        pie: {
            showInLegend: true,
            allowPointSelect: true,
            cursor: "pointer",
            colors: ["#999EA2", "#B7B7BD","#aeafaf","#cccccc","#b3b6b7","#979899","#b1b3b5","#bfc6cc"],
            dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b><br>{point.y:.1f} 次",
                distance: -50,
                filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                },
            },
        },
    },
    series: [{
        name: "外語自學次數",
        colorByPoint: true,
        data: [],
    }, ],
}