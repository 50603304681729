<template>
    <div class="block p-6 rounded-lg shadow-lg bg-white max-w-md">
        <img :src="blobUrl">
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
export default {
    props: {
        image_blob: Blob
    },
    setup(props) {
        const blobUrl = ref("")
        onMounted(() => {
            blobUrl.value = URL.createObjectURL(props.image_blob)
        })

        return { blobUrl }
    },
}
</script>