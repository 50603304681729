<template>
    <div class="block p-6 rounded-lg shadow-lg bg-white max-w-lg">
        已受理您的預約申請，待課業守護回覆確認後，方完成預約程序。
        <br>
        <b>{{ count }}</b> 秒後關閉頁面。
    </div>
</template>

<script>
import { ref } from 'vue';
import { closeModal } from "jenesius-vue-modal";

export default {
    props: {
        ReserveData: Object
    },
    setup() {
        const count = ref(3);

        const intervalId = setInterval(() => {
            count.value--;
            if (count.value == 0) {
                clearInterval(intervalId);
                closeModal()
            }
        }, 1000);

        return { count }
    }
}
</script>