import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";

import { home_route } from "./main";
import { admin_route } from "./sladmin";
import { group_route } from "./studygroup";
import { wishlist_route } from "./wishlist";

const routes = [
  home_route,
  admin_route,
  group_route,
  wishlist_route,
  { path: "/:pathMatch(.*)*", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
