<template>
    <center class="bg-gray-200 p-3">
        <div class="flex">
            <label class="block mt-2 text-sm font-medium text-gray-900 dark:text-gray-400 text-lg w-64">系所</label>
            <select v-model="selectOption" @change="updateList()"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value=""></option>
                <option v-for="(option, index) in DepartmentList" :value="option" :key="index">
                    {{ option }}
                </option>
            </select>
        </div>
    </center>
    <DataTable class="p-5 pt-0" :value="ReserveList" :paginator="true" :rows="10" :filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20]" responsiveLayout="scroll" currentPageReportTemplate="">
        <Column field="department" header="系所" sortable style="width: 11%"></Column>
        <Column field="className" header="課程" style="width: 25%"></Column>
        <Column field="classTeacher" header="授課教師" style="width: 12%"></Column>
        <Column field="consultTime1" header="時間一" style="width: 15%" class="bg-gray-100"></Column>
        <Column field="consultTime2" header="時間二" style="width: 15%" class="bg-gray-200"></Column>
        <Column field="tutorName" header="課守天使"></Column>
        <Column header="#" headerStyle="width: 6rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{ data }">
                <button class="text-blue-600 underline decoration-solid" v-if="data.status == 0"
                    @click="renderModel(data.seq, data.ThisWeekAndTime)">
                    尚可預約
                </button>
                <p v-else class="text-red-600 font-bold underline decoration-solid" style="cursor: not-allowed">
                    無法預約
                </p>
            </template>
        </Column>
        <template #paginatorstart></template>
        <template #paginatorend></template>
    </DataTable>
    <widget-container-modal />
</template>

<script>
import { openModal, container } from "jenesius-vue-modal";
import { onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import moment from "moment";
import ReserveWindow from "./ReserveWindow.vue";
import _ from "lodash";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default {
    components: {
        WidgetContainerModal: container,
        DataTable,
        Column,
    },
    setup() {
        const thisWeek = ref([]);

        const getCurrentWeek = () => {
            const now = moment();
            const todayWeek = moment(now).day();

            var currentWeek = [];
            if (todayWeek >= 5) {
                for (let i = 1; i < 6; i++) {
                    currentWeek.push(
                        moment().add(1, "weeks").weekday(i).format("YYYY-MM-DD")
                    );
                }
            } else {
                for (let i = 1; i < 6; i++) {
                    currentWeek.push(moment().weekday(i).format("YYYY-MM-DD"));
                }
            }

            return currentWeek;
        };
        thisWeek.value = getCurrentWeek();

        const selectOption = ref("");

        const ReserveList = ref([]);
        const DepartmentList = ref([]);

        const WeekTimeArray = [
            "08:10~09:00",
            "09:10~10:00",
            "10:10~11:00",
            "11:10~12:00",
            "12:10~13:00",
            "13:10~14:00",
            "14:10~15:00",
            "15:10~16:00",
            "16:10~17:00",
            "17:05~17:55",
            "18:00~18:50",
            "18:55~19:45",
            "19:50~20:40",
            "20:45~21:35",
        ];

        const TimeArray = [
            "0810",
            "0910",
            "1010",
            "1110",
            "1210",
            "1310",
            "1410",
            "1510",
            "1610",
            "1705",
            "1800",
            "1855",
            "1950",
            "2045",
        ];
        const WeekArray = ["(一)", "(二)", "(三)", "(四)", "(五)"];

        onMounted(() => {
            axios
                .post(process.env.VUE_APP_api_url + "/guidance/getClassesInfo")
                .then((response) => {
                    response.data.forEach((classInfo) => {
                        classInfo.ThisWeekAndTime = [];
                        classInfo.ThisWeekAndTime.push({
                            Week: thisWeek.value[
                                WeekArray.indexOf(classInfo.consultTime1.substring(0, 3))
                            ],
                            Time: WeekTimeArray[
                                TimeArray.indexOf(
                                    classInfo.consultTime1.replace(/\(.*?\)/g, "").substring(0, 4)
                                )
                            ],
                            Place: classInfo.place,
                        });

                        if (classInfo.consultTime2 != null) {
                            classInfo.ThisWeekAndTime.push({
                                Week: thisWeek.value[
                                    WeekArray.indexOf(classInfo.consultTime2.substring(0, 3))
                                ],
                                Time: WeekTimeArray[
                                    TimeArray.indexOf(
                                        classInfo.consultTime2
                                            .replace(/\(.*?\)/g, "")
                                            .substring(0, 4)
                                    )
                                ],
                                Place: classInfo.place2,
                            });
                        }

                        classInfo.ThisWeekAndTime = _.filter(
                            classInfo.ThisWeekAndTime,
                            (item) =>
                                !moment(item.Week).isBefore(moment()) &&
                                !moment(item.Week).isSame(moment())
                        );

                        // add three weeks reserve option
                        for (let i = 1; i < 4; i++) {
                            const thisweek =
                                thisWeek.value[
                                WeekArray.indexOf(classInfo.consultTime1.substring(0, 3))
                                ];
                            const nextWeek = moment(thisweek)
                                .add(i, "weeks")
                                .format("YYYY-MM-DD");

                            classInfo.ThisWeekAndTime.push({
                                Week: nextWeek,
                                Time: WeekTimeArray[
                                    TimeArray.indexOf(
                                        classInfo.consultTime1
                                            .replace(/\(.*?\)/g, "")
                                            .substring(0, 4)
                                    )
                                ],
                                Place: classInfo.place,
                            });

                            if (classInfo.consultTime2 != null) {
                                let thisweek2 =
                                    thisWeek.value[
                                    WeekArray.indexOf(classInfo.consultTime2.substring(0, 3))
                                    ];
                                const nextWeek2 = moment(thisweek2)
                                    .add(i, "weeks")
                                    .format("YYYY-MM-DD");
                                classInfo.ThisWeekAndTime.push({
                                    Week: nextWeek2,
                                    Time: WeekTimeArray[
                                        TimeArray.indexOf(
                                            classInfo.consultTime2
                                                .replace(/\(.*?\)/g, "")
                                                .substring(0, 4)
                                        )
                                    ],
                                    Place: classInfo.place2,
                                });
                            }
                        }

                        if (!DepartmentList.value.includes(classInfo.department)) {
                            DepartmentList.value.push(classInfo.department);
                        }
                        ReserveList.value.push(classInfo);
                    });
                    sessionStorage.setItem("ReserveList", JSON.stringify(response.data));
                });
        });

        const updateList = () => {
            if (selectOption.value == "") {
                ReserveList.value = JSON.parse(sessionStorage.getItem("ReserveList"));
            } else {
                let tempdata = JSON.parse(sessionStorage.getItem("ReserveList"));
                ReserveList.value = tempdata.filter(
                    (e) => e.department.substr(0, 2) == selectOption.value.substr(0, 2)
                );
            }
        };

        const renderModel = (seq, weekTimeArray) => {
            openModal(ReserveWindow, { seq: seq, weekTimeArray: weekTimeArray });
        };

        return {
            selectOption,
            ReserveList,
            DepartmentList,
            updateList,
            renderModel,
            thisWeek,
        };
    },
};
</script>
