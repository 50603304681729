<style>
.flex-gap > div {
  margin: 3px;
}
</style>
<template>
  <header class="bg-white">
    <nav
      class="mx-auto grid grid-cols-1 md:max-w-7xl items-center justify-between p-6 lg:px-8 pb-3 md:mx-auto md:flex"
    >
      <div class="flex-none w-64">
        <a href="/wishlist" class="-m-1.5 p-1.5 flex">
          <img class="h-12 w-auto" src="../../../assets/book.gif" alt="" />
          <p class="text-xl" style="margin-left: 5px; margin-top: 14px">
            課程許願池
          </p>
        </a>
      </div>
      <div class="flex-1">
        <span class="p-input-icon-left my-2 w-full md:mt-0">
          <i class="pi pi-search"></i>
          <InputText
            placeholder="Search"
            style="width: 100%; border-radius: 9999px !important"
          />
        </span>
      </div>
      <div class="flex-none w-full md:w-52 md:pl-3 mx-auto">
        <div
          id="wish-button"
          class="rounded-full"
          style="background-color: #54bbbb"
        >
          <button class="mx-auto p-2 text-xl text-white" @click="wishclick()">
            我想許願
          </button>
        </div>
      </div>
    </nav>
  </header>

  <div
    class="mx-auto flex max-w-7xl items-center justify-between p-6 py-2 lg:px-8"
  >
    <div class="flex flex-wrap flex-gap text-gray-900">
      <div
        v-for="(type, index) in type_list"
        :key="index"
        @click="selectType(index)"
        class="text-left cursor-pointer bg-gray-300 rounded-full px-3 py-1 hover:bg-gray-200"
        :id="`typelist-${index}`"
      >
        {{ type.topic }} {{ type.topic_eng }}
      </div>
    </div>
  </div>

  <div class="mx-auto max-w-7xl items-center justify-between p-6 pt-1 lg:px-8">
    <div class="waterfall text-left">
      <div
        class="item block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
        v-for="(wish, index) in wishcard"
        :key="index"
      >
        <h5
          class="flex mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white"
        >
          <img src="../../../assets/user.png" class="h-10" alt="User avatar" />
          <p class="mt-1 ml-2 flex-1">{{ wish.nickname }}</p>

          <div class="flex justify-end">
            <img
              id="like"
              @click="favorite_click(NumToString(wish.seq))"
              v-if="wishs_favorite.includes(NumToString(wish.seq))"
              src="../../../assets/heart.png"
              alt="Favorite"
              class="h-6"
            />
            <img
              v-else
              id="unlike"
              @click="favorite_click(NumToString(wish.seq))"
              src="../../../assets/empty-heart.png"
              class="h-6"
              alt="Not favorite"
            />
            <p class="ml-1">{{ wish.count }}</p>
          </div>
        </h5>
        <h5
          class="mb-1 mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          感興趣的學習坊主題：
        </h5>
        <p class="break-words">{{ wish.q_workshop_topic }}</p>
        <h5
          class="mb-1 mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          推薦的講師：
        </h5>
        <p class="break-words">{{ wish.q_lecturers }}</p>
        <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />

        <div>
          <h5
            class="mb-1 mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
          >
            希望提升的能力：
          </h5>
          <p class="flex flex-wrap gap-1" :id="`skill-${wish.seq}`">
            <span
              v-for="(item, index) in wish.skill"
              :key="index"
              class="bg-blue-100 text-blue-800 text-xs font-medium mr-0.5 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400"
              >{{ item }}</span
            >
          </p>
          <h5
            class="mb-1 mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
          >
            學習坊類型：
          </h5>
          <p class="flex flex-wrap gap-1" :id="`type-${wish.seq}`">
            <span
              v-for="(item, index) in wish.type"
              :key="index"
              class="bg-blue-100 text-blue-800 text-xs font-medium mr-0.5 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400"
              >{{ item }}</span
            >
          </p>
        </div>
      </div>
    </div>

    <button
      class="w-full p-2 text-white mt-5"
      style="background-color: #2db3b3"
    >
      查看更多
    </button>
    <!-- <button class="w-full p-2 text-white" style="background-color: #2db3b3;">查看許願紀錄</button> -->
  </div>
  <a href="/" class="pb-5">返回自主學習平台</a>
  <container />
</template>

<script setup>
import InputText from "primevue/inputtext";
import WishWindow from "./WishWindow.vue";
import WishLogin from "./WishLogin.vue";
import axios from "axios";
import { container, openModal } from "jenesius-vue-modal";
import { onMounted, ref } from "vue";
import { useCookies } from "vue3-cookies";

const type_list = ref([]);
const wishs_favorite = ref([]);

const { cookies } = useCookies();
var cookieToken = cookies.get("isLogin");

const wishclick = () => {
  if (cookieToken) {
    openModal(WishWindow);
  } else {
    openModal(WishLogin);
  }
};

const selectType = (index) => {
  let temp_arr = JSON.parse(sessionStorage.getItem("wishcard"));
  let type_data = document.getElementById(`typelist-${parseInt(index)}`);
  if (type_data.classList.contains("active")) {
    type_data.classList.remove("active");
    wishcard.value = temp_arr;
  } else {
    for (let i = 0; i < type_list.value.length; i++) {
      if (
        document.getElementById(`typelist-${i}`).classList.contains("active")
      ) {
        document.getElementById(`typelist-${i}`).classList.remove("active");
      }
    }
    type_data.classList.add("active");
    let keyword = type_list.value[index].topic;
    let wishcard_filter = [];
    const regex = /[\u4e00-\u9fa5]+/g; // Matches one or more Chinese characters
    temp_arr.forEach((item) => {
      item.type.forEach((type) => {
        const match = type.match(regex);
        if (match == keyword) {
          wishcard_filter.push(item);
        }
      });
    });
    wishcard.value = wishcard_filter;
  }
};

const favorite_click = (index) => {
  if (!wishs_favorite.value.includes(index)) {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/like", { wish_id: index })
      .then((response) => {
        const wishcard_index = wishcard.value.findIndex((item) => {
          return index === NumToString(item.seq);
        });

        wishcard.value[wishcard_index].count = response.data.count;

        wishs_favorite.value.push(index);
        localStorage.setItem("wishs_favorite", wishs_favorite.value);
      });
  } else {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/unlike", {
        wish_id: index,
      })
      .then((response) => {
        const wishcard_index = wishcard.value.findIndex((item) => {
          return index === NumToString(item.seq);
        });

        wishcard.value[wishcard_index].count = response.data.count;

        wishs_favorite.value.splice(
          wishs_favorite.value.findIndex((item) => NumToString(index) === item),
          1
        );
        localStorage.setItem("wishs_favorite", wishs_favorite.value);
      });
  }
};

const NumToString = (num) => {
  return num.toString();
};

const wishcard = ref([]);
onMounted(() => {
  if (localStorage.getItem("wishs_favorite")) {
    wishs_favorite.value = localStorage.getItem("wishs_favorite").split(",");
  }

  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/getwishtopic")
    .then((response) => {
      response.data.forEach((item) => {
        type_list.value.push(item);
      });
    });

  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/getwishlist")
    .then((response) => {
      response.data.forEach((item) => {
        item.type = item.type.replaceAll("&", " ");
        item.skill = item.skill.replaceAll("&", " ");
        if (item.type.split(",").length > 1) {
          item.type_show = true;
        }

        if (item.skill.split(",").length > 1) {
          item.skill_show = true;
        }
        item.type = item.type.split(",");
        item.skill = item.skill.split(",");
        item.skill = item.skill.map((item) => item[0]);
        wishcard.value.push(item);
      });
      sessionStorage.setItem("wishcard", JSON.stringify(wishcard.value));
    });
});
</script>

<style>
.waterfall {
  width: 100%;
  margin: 0px auto;
  column-count: 4;
  column-gap: 10px;
}

.item {
  break-inside: avoid;
  margin: 0 auto 10px;
}

@media (max-width: 1000px) {
  .waterfall {
    display: grid;
    grid-column: 1;
  }

  .item {
    width: 100%;
  }
}
</style>
