import Home from "../views/Home.vue";
import SelfLearning from "../components/pages/SelfLearning.vue";
import StudentProgram from "../components/pages/StudentProgram.vue";
import Counselor from "../components/pages/Counselor.vue";
import ReservePanel from "../components/pages/guidance/ReservePanel.vue";
import ClassReserve from "../components/pages/guidance/venue/Detail.vue";
import Activity from "../components/pages/Activity.vue";
import AwardApply from "../components/pages/AwardApply.vue";
import ProLicense from "../components/pages/awardapply/ProLicense.vue";
import ProLicenseDetail from "../components/pages/awardapply/prolicense/ApplyDetail.vue";
import NotReply from "../components/pages/guidance/venue/NotReply.vue";
import ReservePass from "../components/pages/guidance/venue/ReservePass.vue";
import Wishlist from "../components/pages/wishlist/Home.vue";
import CourseFeedback from "../components/pages/feedback/Course.vue";

export const home_route = {
  path: "/",
  name: "Home",
  component: Home,
  children: [
    {
      path: "/",
      name: "SelfLearning",
      component: SelfLearning,
    },
    {
      path: "/counselor",
      name: "counselor",
      component: Counselor,
    },
    {
      path: "/counselor/notreply",
      name: "counselor_notreply",
      component: NotReply,
    },
    {
      path: "/counselor/reservepass",
      name: "counselor_reservepass",
      component: ReservePass,
    },
    {
      path: "/counselor/reservepanel",
      name: "ReservePanel",
      component: ReservePanel,
    },
    {
      path: "/counselor/class/:id",
      name: "ClassReserve",
      component: ClassReserve,
    },
    {
      path: "/activity",
      name: "Activity",
      component: Activity,
    },
    {
      path: "/program",
      name: "Program",
      component: StudentProgram,
    },
    {
      path: "/awardapply",
      name: "AwardApply",
      component: AwardApply,
    },
    {
      path: "/awardapply/prolicense",
      name: "ProLicense",
      component: ProLicense,
    },
    {
      path: "/awardapply/prolicense/:id",
      name: "AwardApplyInfo",
      component: ProLicenseDetail,
    },
    {
      path: "/wishlist",
      name: "wishlist",
      component: Wishlist,
    },
    {
      path: "/feedback/course",
      name: "feedback_course",
      component: CourseFeedback,
    },
  ],
};
