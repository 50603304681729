<template>
    <!-- TODO: 切開登入頁跟學生頁 -->
   <Home/>
</template>

<script>
import Home from "./Home.vue"
// import Login from "./Login.vue"
export default {
    components: {
        Home
    },
    setup() {

    },
}
</script>
