<template>
  <section id="header" class="header text-left">
    <div id="Info" class="flex inline-block align-middle">
      <div class="flex-1 header-title">姓名：{{ userName }}</div>
      <div class="flex-1 header-title">學號：{{ userId }}</div>
      <div class="flex-1 header-title">單位：{{ userDept }}</div>
      <div class="flex-1" id="logout-btn">
        <button class="logout-button" @click="Logout">登出</button>
      </div>
    </div>
    <div class="flex inline-block align-middle mt-5">
      <div class="flex-1">
        <div v-if="userDouble.length > 0">
          雙主修：<span v-for="item in userDouble" :key="item.sub_dept">
            {{ item.sub_dept_name }}
            <span v-if="index > 0"> / </span>
          </span>
        </div>
        <div v-else>雙主修：<b>無資料</b></div>
      </div>
      <div class="flex-1">
        <div v-if="userMinor.length > 0">
          輔系：<span v-for="(item, index) in userMinor" :key="item.sub_dept">
            {{ item.sub_dept_name }} <span v-if="index > 0"> / </span>
          </span>
        </div>
        <div v-else>輔系：<b>無資料</b></div>
      </div>

    </div>
    <div class="" id="mobile-logout-btn">
      <button class="mobile-logout-button" @click="Logout">登出</button>
    </div>

  </section>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { ref } from "vue";
import axios from "axios";
import store from "@/store/index.js";

export default {
  setup() {
    const userName = ref("");
    const userId = ref("");
    const userDept = ref("");
    const userDouble = ref([]);
    const userMinor = ref([]);

    const Logout = () => {
      store.commit("Logout");
      location.href = "/";
    };

    let windowWidth = ref(window.innerWidth);
    var headerTitle = ref(document.getElementsByClassName("header-title"));
    var routeLink = ref(document.getElementsByClassName("LinkPanel"));
    window.addEventListener("resize", () => {
      windowWidth.value = window.innerWidth;
      if (windowWidth.value < 560) {
        document.getElementById("Info").classList.add("block");
        document.getElementById("logout-btn").classList.add("hide");
        document.getElementById("mobile-logout-btn").classList.remove("hide");
        for (let i = 0; i < headerTitle.value.length; i++) {
          headerTitle.value[i].classList.add("mb-5px");
        }
        for (let i = 0; i < routeLink.value.length; i++) {
          routeLink.value[i].classList.add("flex");
        }
      } else {
        document.getElementById("Info").classList.remove("block");
        document.getElementById("logout-btn").classList.remove("hide");
        document.getElementById("mobile-logout-btn").classList.add("hide");
        for (let i = 0; i < headerTitle.value.length; i++) {
          headerTitle.value[i].classList.remove("mb-5px");
        }
        for (let i = 0; i < routeLink.value.length; i++) {
          routeLink.value[i].classList.remove("flex");
        }
      }
    });

    onMounted(() => {
      if (windowWidth.value < 560) {
        document.getElementById("Info").classList.add("block");
        document.getElementById("logout-btn").classList.add("hide");
        document.getElementById("mobile-logout-btn").classList.remove("hide");
        for (let i = 0; i < headerTitle.value.length; i++) {
          headerTitle.value[i].classList.add("mb-5px");
        }
        for (let i = 0; i < routeLink.value.length; i++) {
          routeLink.value[i].classList.add("flex");
        }
      } else {
        document.getElementById("Info").classList.remove("block");
        document.getElementById("logout-btn").classList.remove("hide");
        document.getElementById("mobile-logout-btn").classList.add("hide");
        for (let i = 0; i < headerTitle.value.length; i++) {
          headerTitle.value[i].classList.remove("mb-5px");
        }
        for (let i = 0; i < routeLink.value.length; i++) {
          routeLink.value[i].classList.remove("flex");
        }
      }

      if (sessionStorage.getItem("stuInfo") == null) {
        axios
          .post(process.env.VUE_APP_api_url + "/getdata/studentInfo")
          .then((d) => {
            if (d.data != "Error") {
              for (let i = 0; i < d.data.stmd_sub.length; i++) {
                if (
                  d.data.stmd_sub[i].sub_sub == "輔" &&
                  d.data.stmd_sub[i].sub_sts == "修習"
                ) {
                  userMinor.value.push(d.data.stmd_sub[i]);
                }

                if (
                  d.data.stmd_sub[i].sub_sub == "雙" &&
                  d.data.stmd_sub[i].sub_sts == "修習"
                ) {
                  userDouble.value.push(d.data.stmd_sub[i]);
                }
              }

              userName.value = d.data.studentInfo.stmd_name;
              userId.value = d.data.studentInfo.stmd_id;
              userDept.value = d.data.studentInfo.stmd_dept_abvi_c;

              sessionStorage.setItem(
                "stuInfo",
                JSON.stringify({
                  userName: d.data.studentInfo.stmd_name,
                  userId: d.data.studentInfo.stmd_id,
                  userDept: d.data.studentInfo.stmd_dept_abvi_c,
                  userDouble: userDouble.value,
                  userMinor: userMinor.value,
                })
              );
            }
          });
      } else {
        let stuInfo = JSON.parse(sessionStorage.getItem("stuInfo"));
        userName.value = stuInfo.userName;
        userId.value = stuInfo.userId;
        userDept.value = stuInfo.userDept;
        userDouble.value = stuInfo.userDouble;
        userMinor.value = stuInfo.userMinor;
      }
    });

    return { userName, userId, userDept, userMinor, userDouble, Logout };
  },
};
</script>
