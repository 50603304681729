export const ProOptions = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
    },
    title: {
        text: "專業自主學習累計時數",
    },
    lang: {
        noData: "無資料"
    },
    noData: {
        style: {
            fontWeight: 'bold',
            fontSize: '15px',
            color: '#303030',
        }
    },
    tooltip: {
        pointFormat: "{series.name}: <b>{point.y:.1f} 小時</b>",
    },
    accessibility: {
        point: {
            valueSuffix: "%",
        },
    },
    plotOptions: {
        pie: {
            showInLegend: true,
            allowPointSelect: true,
            cursor: "pointer",
            colors: ["#DDAB77", "#BB6F32","#d67933","#e8914e","#d68f59","#ef8b3e","#bc8256","#ef761a"],
            dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b><br>{point.y:.1f} 小時",
                distance: -50,
                filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                },
            },
        },
    },
    series: [{
        name: "累計時數",
        colorByPoint: true,
        data: [],
    }, ],
}