<template>
  <div class="common-layout">
    <el-header style="background-color: #2db3b3"></el-header>
    <el-aside width="250px" class="border-r-2"></el-aside>
    <el-main>
      <router-view></router-view>
    </el-main>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  setup() {
    const goBack = () => {
      history.back();
    };

    const router = useRouter();
    const goToVerifyPage = (dest) => {
      router.push({
        path: "/sladmin/" + dest,
      });
    };

    const goToIndex = () => {
      router.push({
        path: "/sladmin",
      });
    };

    const goPage = (route) => {
      switch (route) {
        case 1:
          router.push({ path: "/sladmin/awardlist" });
          break;
        case 2:
          router.push({ path: "/sladmin/guidance" });
          break;
      }
    };

    return { goToVerifyPage, goBack, goPage, goToIndex };
  },
};
</script>

<style>
#logo-admin:hover {
  cursor: pointer;
}

.el-sub-menu__title,
.el-menu-item {
  font-size: 17px !important;
}

.el-menu-item.is-active {
  color: #2db3b3 !important;
}

#admin-header {
  background-color: #2db3b3;
  color: #fff;
  padding: 10px;
  font-size: 20px;
}
</style>
