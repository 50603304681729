<template>
  <div id="loader" class="loader loader-border is-active">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../../../assets/Spin-1s-200px.gif" />
        </div>
        <p id="loader-text" class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>
  <div id="export" class="loader loader-border">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../../../assets/Spin-1s-200px.gif" />
        </div>
        <p class="loader-text">匯出申請表..</p>
      </div>
    </div>
  </div>
  <div id="revokeLoader" class="loader loader-border">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img v-if="revokestate == 0" id="image-src" src="../../../../assets/delete.png" />
          <img v-else-if="revokestate == 1" id="image-src" src="../../../../assets/success_icon.png" />
          <img v-else-if="revokestate == 2" id="image-src" src="../../../../assets/error_icon.png" />
        </div>
        <p v-if="revokestate == 0" class="loader-text">文件撤回中...</p>
        <p v-else-if="revokestate == 1" class="loader-text">撤回成功！</p>
        <p v-else-if="revokestate == 2" class="loader-text">撤回失敗！</p>
      </div>
    </div>
  </div>

  <div class="w-full bg-gray-200 dark:bg-gray-700 p-5">
    <div v-if="stuInfo.State == 0">
      <div
        class="p-4 mb-4 text-lg text-blue-700 bg-blue-300 rounded-lg dark:bg-blue-200 dark:text-blue-800 text-center p-2 leading-none"
        role="alert">
        <div class="flex justify-center">
          <svg class="flex-shrink-0 mr-1 w-5 h-5 text-blue-700 dark:text-blue-800" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clip-rule="evenodd"></path>
          </svg>
          <span class="font-black">審核中</span>
        </div>
      </div>
    </div>
    <div v-else-if="stuInfo.State == 1">
      <div class="p-3 mb-4 text-lg text-green-600 bg-green-300 rounded-lg dark:bg-green-200 p-2 dark:text-green-800"
        role="alert">
        <span class="font-black">審核通過</span>
      </div>
    </div>
    <div v-else-if="stuInfo.State == 2">
      <div class="p-4 mb-4 text-lg bg-red-300 rounded-lg font-black text-red-900 text-center p-2 leading-none"
        style="width: 100%">
        審核未通過
      </div>
    </div>
    <div v-else-if="stuInfo.State == 3">
      <div class="p-4 mb-4 text-lg bg-gray-500 font-black rounded-lg text-white text-center p-2 leading-none"
        style="width: 100%">
        已撤回申請
      </div>
    </div>
    <div class="border rounded-lg text-white p-5 m-5 text-left" style="background-color: #2db3b3">
      <div class="widget widget-info flex" style="padding-bottom: 0">
        <h3 class="widget-title">個人資料 Personal Information</h3>
        <div class="flex-1 text-right">
          <button class="backListBtn rounded-lg text-md rounded-lg p-1.5 pl-3.5 pr-3.5" @click="GoListPage()">
            返回清單
          </button>
        </div>
      </div>

      <div class="grid gap-6 p-5 lg:grid-cols-2">
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>姓名</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="stuInfo.stuName" disabled required />
        </div>

        <div>
          <label for="stuId" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>學號</label>
          <input type="text" id="stuId" name="stuId"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="stuInfo.stuId" disabled required />
        </div>

        <div>
          <label for="stuDept" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>系級</label>
          <input type="text" id="stuDept" name="stuDept"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="stuInfo.stuDept" disabled required />
        </div>

        <div>
          <label for="stuMail" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>電子郵件</label>
          <input type="email" id="stuMail" name="stuMail" v-model="stuInfo.stuMail"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>
        <div>
          <label for="stuPhone" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>聯絡電話</label>
          <input type="text" id="stuPhone" name="stuPhone" v-model="stuInfo.stuPhone"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>

        <div>
          <label for="stuGrade" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-400"><span
              style="color: red"> * </span>學制</label>
          <select id="stuGrade" name="stuGrade" v-model="stuInfo.stuGrade"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled>
            <option value="" selected disabled>請選擇學制</option>
            <option value="大學部">大學部</option>
            <option value="碩士班">碩士班</option>
            <option value="碩專班">碩專班</option>
            <option value="博士班">博士班</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mt-4 border rounded-lg text-black p-5 m-5 text-left" style="background-color: rgb(207, 207, 207)">
      <div class="widget widget-info" style="padding-bottom: 0">
        <h3 class="widget-title-black" style="color: #000 !important">
          證照資料 License Information
        </h3>
      </div>
      <div class="grid gap-6 p-5 lg:grid-cols-2">
        <div>
          <label for="licenseType" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照種類</label>
          <select id="licenseType" name="licenseType" v-model="stuInfo.licenseType"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled>
            <option value="" selected disabled>選擇證照種類</option>
            <option value="政府機關">政府機關</option>
            <option value="教師證書">教師證書</option>
            <option value="國際認證">國際認證</option>
            <option value="其他">其他</option>
          </select>
        </div>
        <div>
          <label for="licenseLevel" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>申請獎勵等級</label>
          <select id="licenseLevel" name="licenseLevel" v-model="stuInfo.licenseLevel"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled>
            <option value="" selected disabled>選擇證照等級</option>
            <option value="甲級">甲級</option>
            <option value="乙級">乙級</option>
            <option value="丙級">丙級</option>
          </select>
        </div>
        <div>
          <label for="licenseTeacher" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照指導老師</label>
          <input type="text" id="licenseTeacher" name="licenseTeacher" v-model="stuInfo.licenseTeacher"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>

        <div>
          <label for="licenseName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照名稱(全名)</label>
          <input type="text" id="licenseName" name="licenseName" v-model="stuInfo.licenseName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>

        <div>
          <label for="licenseFee" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照報名費</label>
          <input type="number" id="licenseFee" name="licenseFee" v-model="stuInfo.licenseFee"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>

        <div>
          <label for="licenseStart" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>核照/生效日期</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"></path>
              </svg>
            </div>
            <input datepicker type="date" id="licenseStart" name="licenseStart" v-model="stuInfo.licenseStart"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="選擇日期" required disabled />
          </div>
        </div>
      </div>
      <div class="widget widget-info" style="padding-bottom: 0">
        <h3 class="widget-title-black" style="color: #000 !important">
          檢附文件 Attachment
        </h3>
      </div>
      <div class="grid gap-6 p-5 pt-0 lg:grid-cols-2">
        <div class="flex" v-for="item in applyFiles" :key="item.seq">
          <i class="pi pi-file mr-1"></i>
          <a href="#" @click.prevent="getImage(item.path, item.fileName)" target="_blank">{{ item.fileName }}</a>
        </div>
      </div>

      <div v-if="stuInfo.VerifyReply">
        <div class="widget widget-info" style="padding-bottom: 0">
          <h3 class="widget-title-black" style="color: #000 !important">
            審核回應 Reply
          </h3>
        </div>
        <div class="grid gap-6 p-5 pt-0">
          <textarea id="message" rows="2" v-model="stuInfo.VerifyReply"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="輸入審核回覆...." disabled></textarea>
        </div>
      </div>

      <div class="flex-1 text-right">
        <button class="exportPdfBtn rounded-lg text-sm rounded-lg px-5 py-3 mr-2 mb-2"
          v-if="stuInfo.State != 2 && stuInfo.State != 3" @click="ExportPdfFile()">
          匯出申請表
        </button>
        <button type="button" v-if="stuInfo.State == 0" @click="RevokeOrder"
          class="focus:outline-none hover:bg-red-800 hover:text-white focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
          style="
                                    border: 2px solid #c81e1e;
                                    font-weight: bolder;
                                    font-size: 18px;
                                    transition: 0.3s;
                                  ">
          撤回申請
        </button>
      </div>
    </div>
  </div>
  <widget-container-modal />
</template>

<script>
import { openModal, container } from "jenesius-vue-modal";
import { useRoute, useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import moment from "moment";
import axios from "axios";
import ProofImage from './ProofImage.vue';

export default {
  components: {
    WidgetContainerModal: container,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const stuInfo = ref({
      seq: route.params.id,
      semCount: null,
      sem: null,
      stuId: null,
      stuName: null,
      stuDept: null,
      stuMail: null,
      stuPhone: null,
      stuGrade: "",
      licenseType: "",
      licenseTeacher: null,
      licenseName: null,
      licenseFee: null,
      licenseStart: null,
      licenseLevel: "",
      ModifyTime: "",
      ApplyTime: "",
      State: 0,
    });

    const applyFiles = ref([]);

    const revokestate = ref(0);

    const getLocalDate = (date) => {
      if (date == undefined) return "";
      return moment(date).local().format("YYYY-MM-DD");
    };

    const GoListPage = () => {
      router.push({
        path: "/awardapply/prolicense",
      });
    };

    const ExportPdfFile = () => {
      const loader = document.getElementById("export");
      loader.classList.add("is-active");
      axios
        .post(
          process.env.VUE_APP_api_url + "/awardapply/pro/exportpdf",
          stuInfo.value
        )
        .then((response) => {
          if (response.data.status) {
            setTimeout(() => {
              axios.get(
                process.env.VUE_APP_api_url + "/" + response.data.path, {
                responseType: "blob"
              },
              )
                .then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = "專業證照申請書.pdf";
                  a.click();
                })
                .catch((error) => {
                  console.error(error);
                });
              loader.classList.remove("is-active");
            }, 1000);
          } else {
            loader.classList.remove("is-active");
            alert("匯出失敗");
            console.log(response.data.msg);
          }
        }).catch((err) => {
          loader.classList.remove("is-active");
          alert("匯出失敗");
          console.log(err);
        });
    };

    const RevokeOrder = () => {
      var yes = confirm("你確定要撤回嗎？");
      const revokeloader = document.getElementById("revokeLoader");

      if (yes) {
        revokeloader.classList.add("is-active");
        axios
          .post(process.env.VUE_APP_api_url + "/awardapply/pro/changeState/", {
            oId: route.params.id,
            state: 3,
          })
          .then(() => {
            revokestate.value = 1;
          })
          .catch(() => {
            revokestate.value = 2;
          })
          .finally(() => {
            setTimeout(() => {
              revokeloader.classList.remove("is-active");
            }, "3000");

            setTimeout(() => {
              GoListPage();
            }, "1500");
          });
      } else {
        revokestate.value = 2;
        revokeloader.classList.add("is-active");

        setTimeout(() => {
          revokeloader.classList.remove("is-active");
        }, "2000");

        setTimeout(() => {
          GoListPage();
        }, "1500");
      }
    };

    const stu_data = ref({});
    onMounted(() => {
      stu_data.value = JSON.parse(sessionStorage.getItem("stuInfo"));
      axios
        .post(
          process.env.VUE_APP_api_url +
          "/awardapply/pro/selectfiles/" +
          route.params.id
        )
        .then((d) => {
          d.data.forEach((e) => {
            e.fileName = e.path.substring(e.path.lastIndexOf("/") + 1);
            applyFiles.value.push(e);
          });
        });

      axios
        .post(
          process.env.VUE_APP_api_url +
          "/awardapply/pro/select/" +
          route.params.id
          , { userId: stu_data.value.userId })
        .then((d) => {
          if (!d.data[0]) {
            alert("請勿修改參數，該筆申請並非您本人！")
            history.back(-1)
          } else {
            Object.assign(stuInfo.value, d.data[0])
            stuInfo.value.licenseStart = getLocalDate(d.data[0].licenseStart);
          }
        });
    });

    setTimeout(() => {
      const loader = document.getElementById("loader");
      loader.classList.remove("is-active");
    }, "1000");

    const getImage = async (image_url) => {
      try {
        const response = await axios.get(image_url, {
          responseType: "blob",
        });
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        openModal(ProofImage, { image_blob: blob });
      } catch (error) {
        alert("檔案發生問題！");
      }
    };

    return {
      stuInfo,
      applyFiles,
      GoListPage,
      RevokeOrder,
      revokestate,
      ExportPdfFile,
      getImage,
    };
  },
};
</script>
