<template>
  <DataTable class="p-5" :value="tables" :paginator="true" :rows="10" :filters="filters"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    responsiveLayout="scroll" currentPageReportTemplate="">
    <template #header>
      <div class="grid grid-cols-4">
        <input class="col-span-3" type="file" name="classtable" id="classtable" @change="previewFiles" />
        <button class="text-white p-2" style="background-color: #2db3b3" @click="importData()" :disabled="!canUpload">
          上傳課表
        </button>
      </div>
    </template>
    <Column field="token" header="序號" style="width: 30%"></Column>
    <Column field="ip" header="IP" style="width: 10%"></Column>
    <Column field="user" header="使用者名稱" style="width: 10%"></Column>
    <Column field="filename" header="檔案名稱" style="width: 10%"></Column>
    <Column field="status" header="狀態" style="width: 10%">
      <template #body="{ data }">
        <i class="pi pi-times-circle text-red-600" v-if="data.status == 0">&nbsp;Disabled</i>
        <i class="pi pi-check-circle text-blue-500" v-else-if="data.status == 1">&nbsp;Active</i>
      </template>
    </Column>
    <Column field="create_time" header="建立時間" sortable></Column>
    <Column header="#">
      <template #body="{ data }">
        <button type="button" v-if="data.status == 0" @click="activeTable(data.token)"
          class="bg-green-500 text-white font-medium rounded-lg text-sm px-2 py-1.5 mr-2 text-center inline-flex items-center">
          <i class="pi pi-check mr-1"></i>
          啟用
        </button>
        <button type="button" v-else-if="data.status == 1" @click="disabledTable(data.token)"
          class="bg-red-500 text-white font-medium rounded-lg text-sm px-2 py-1.5 mr-2 text-center inline-flex items-center">
          <i class="pi pi-times mr-1"></i>
          禁用
        </button>
        <button type="button" @click="showDetail(data.token)"
          class="nopadding-button text-white font-medium rounded-lg text-sm px-2 py-1.5 mr-2 text-center inline-flex items-center">
          <i class="pi pi-eye mr-1"></i>
          檢視
        </button>
      </template>
    </Column>
    <template #paginatorstart></template>
    <template #paginatorend></template>
  </DataTable>
  <widget-container-modal />
</template>

<script>
import { onMounted } from "vue";
import { container, openModal } from "jenesius-vue-modal";
import { ref } from "@vue/reactivity";
import axios from "axios";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import moment from "moment";
import TableDetail from "./TableDetail.vue";

export default {
  components: {
    DataTable,
    Column,
    WidgetContainerModal: container,
  },
  setup() {
    var FileInfo = ref([]);
    const canUpload = ref(false);

    onMounted(() => {
      getTables();
    });

    const importData = async () => {
      const adminId = sessionStorage.getItem("adminId").replace('"', "").replace('"', "");
      const file = FileInfo.value;
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        let blob = new Blob([reader.result], { type: file.type });
        let formData = new FormData();
        formData.append("file", blob, file.name);
        formData.append("userId", adminId);
        try {
          const response = await axios.post(
            process.env.VUE_APP_api_url + "/guidance/addclass",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data) {
            alert("上傳成功");
            getTables();
          }
        } catch (error) {
          console.error(error);
        }
      };
    };

    const tables = ref([]);
    const getTables = () => {
      tables.value = []
      axios
        .post(process.env.VUE_APP_api_url + "/guidance/gettable")
        .then((response) => {
          response.data.forEach((item) => {
            item.create_time = moment(item.create_time).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            tables.value.push(item);
          });
        });
    };

    const activeTable = (token) => {
      let haveActive = false;
      let tokenlist = []
      tables.value.forEach((item) => {
        if (item.token != token && item.status == 1) {
          haveActive = true;
          tokenlist.push(item.token)
        }
      })

      if (haveActive) {
        let yes = confirm("啟用課表會將其他課表禁用，同時間只能有一個課表啟用！")
        if (yes) {
          axios.post(process.env.VUE_APP_api_url + "/guidance/updateTable", { token: token, status: 1 }).then((response) => {
            if (response.status == 200) {
              tokenlist.forEach((item) => {
                autoDisabledTable(item)
              })
              getTables()
            }
          })
        }
      } else {
        axios.post(process.env.VUE_APP_api_url + "/guidance/updateTable", { token: token, status: 1 }).then((response) => {
          if (response.status == 200) {
            getTables()
          }
        })
      }
    }

    const autoDisabledTable = (token) => {
      axios.post(process.env.VUE_APP_api_url + "/guidance/updateTable", { token: token, status: 0 }).then((response) => {
        if (response.status != 200) {
          alert("disable error" + token)
        }
      })
    }

    const disabledTable = (token) => {
      axios.post(process.env.VUE_APP_api_url + "/guidance/updateTable", { token: token, status: 0 }).then((response) => {
        if (response.status == 200) {
          getTables()
        }
      })
    }

    const previewFiles = (event) => {
      if (
        event.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        event.target.files[0].type != "text/csv"
      ) {
        alert("檔案類型錯誤！");
        document.getElementById("classtable").value = "";
      } else {
        FileInfo.value = event.target.files[0];
        canUpload.value = true;
      }
    };

    const showDetail = (token) => {
      openModal(TableDetail, { token: token });
    };

    return {
      activeTable,
      disabledTable,
      previewFiles,
      showDetail,
      importData,
      FileInfo,
      canUpload,
      tables,
    };
  },
};
</script>

<style>
#sampleTable {
  border: 1px solid #000;
}

#sampleTable tr,
#sampleTable td,
#sampleTable th {
  padding: 5px;
  border: 1px solid #000;
}
</style>
