<template>
  <div class="w-full h-full my-0 p-1" style="z-index: -20">
    <div
      class="m-1 p-1 rounded-2xl"
      style="z-index: -10; max-width: 1060px; margin: auto"
    >
      <div
        class="m-3 grid grid-cols-2 gap-6 items-start"
        style="grid-template-columns: 0.7fr 2fr"
      >
        <div class="grid gap-6">
          <div class="p-2 px-4 bg-basic-100 font-bold rounded-2xl shadow">
            <div class="w-full text-center">
              <img
                src="../../../assets/group/user.png"
                width="54"
                height="54"
                class="my-4 m-auto"
                alt=""
              />
            </div>
            <div v-for="stu in student" :key="stu.id" class="text-left text-lg">
              <div>
                Hi~ <span class="text-main-link">{{ stu.name }}</span> 同學
              </div>
              <div>歡迎報名同儕精進讀書會！</div>
            </div>
            <a href="/group/login">
              <button
                class="w-full rounded-xl mt-3 p-1 bg-main-second text-white font-bold"
              >
                登出
              </button>
            </a>
          </div>
          <div class="p-4 bg-basic-100 rounded-2xl shadow">
            <div class="m-2 text-lg font-black text-black">注意事項</div>
            <div class="p-2 px-4 bg-white text-left font-bold rounded-2xl">
              <div
                v-for="notice in notices"
                :key="notice.order"
                class="py-2"
                v-html="notice.content"
              ></div>
            </div>
          </div>
        </div>

        <div class="p-4 bg-basic-100 rounded-2xl shadow">
          <div class="grid grid-cols-2 gap-4">
            <div
              v-for="sem in semester"
              :key="sem.order"
              class="p-2 col-span-2 bg-white rounded-xl font-bold text-main-primary text-xl"
            >
              中原大學{{ sem.content }} 同儕精進讀書會 報名申請
            </div>
            <div class="p-4 bg-white rounded-xl">
              <div class="font-bold m-2">
                讀書會名稱<span class="text-red-400"
                  >({{ groupName_error }})</span
                >
              </div>
              <div>
                <input
                  type="text"
                  name="groupName"
                  id="groupName"
                  v-model="apply_info.group_name"
                  class="rounded-xl p-1 px-2 border-gray-400 w-11/12"
                  placeholder="6個中文字為限"
                />
              </div>

              <div class="font-bold m-2 mt-4">指導老師</div>
              <div>
                <input
                  class="rounded-xl p-1 px-2 border-gray-400 w-11/12"
                  type="text"
                  v-model="apply_info.teacher"
                  placeholder="請輸入指導老師姓名"
                />
              </div>
            </div>
            <div class="p-4 bg-white rounded-xl">
              <div class="font-bold m-2">讀書會性質(可複選)</div>
              <div class="grid grid-cols-2 gap-2">
                <label
                  for="type1"
                  class="rounded-xl p-1 w-11/12"
                  style="border: 1px solid #9ca3af"
                >
                  <input
                    class="rounded p-1"
                    type="checkbox"
                    v-model="apply_info.group_type"
                    value="學術專業"
                    id="type1"
                  />
                  學術專業
                </label>

                <label
                  for="type2"
                  class="rounded-xl p-1 w-11/12"
                  style="border: 1px solid #9ca3af"
                >
                  <input
                    class="rounded p-1"
                    type="checkbox"
                    v-model="apply_info.group_type"
                    value="品格培養"
                    id="type2"
                  />
                  品格培養
                </label>

                <label
                  for="type3"
                  class="rounded-xl p-1 w-11/12"
                  style="border: 1px solid #9ca3af"
                >
                  <input
                    class="rounded p-1"
                    type="checkbox"
                    v-model="apply_info.group_type"
                    value="創意思考"
                    id="type3"
                  />
                  創意思考
                </label>

                <label
                  for="type4"
                  class="rounded-xl p-1 w-11/12"
                  style="border: 1px solid #9ca3af"
                >
                  <input
                    class="rounded p-1"
                    type="checkbox"
                    v-model="apply_info.group_type"
                    value="國際世界觀"
                    id="type4"
                  />
                  國際世界觀
                </label>

                <label
                  for="type5"
                  class="rounded-xl p-1 w-11/12"
                  style="border: 1px solid #9ca3af"
                >
                  <input
                    class="rounded p-1"
                    type="checkbox"
                    v-model="apply_info.group_type"
                    value="跨域學習"
                    id="type5"
                  />
                  跨域學習
                </label>
              </div>
            </div>

            <div class="p-4 bg-white rounded-xl">
              <div class="font-bold m-2">選讀書籍/資料(至少3項)</div>
              <div class="grid gap-4">
                <input
                  class="rounded-xl p-1 px-2 border-gray-400 w-11/12 m-auto"
                  type="text"
                  placeholder="1."
                  name="book1"
                  v-model="apply_info.booklist[0]"
                />
                <input
                  class="rounded-xl p-1 px-2 border-gray-400 w-11/12 m-auto"
                  type="text"
                  placeholder="2."
                  name="book2"
                  v-model="apply_info.booklist[1]"
                />
                <input
                  class="rounded-xl p-1 px-2 border-gray-400 w-11/12 m-auto"
                  type="text"
                  placeholder="3."
                  name="book3"
                  v-model="apply_info.booklist[2]"
                />
                <input
                  class="rounded-xl p-1 px-2 border-gray-400 w-11/12 m-auto"
                  type="text"
                  placeholder="其他"
                  name="book4"
                  v-model="apply_info.booklist[3]"
                />
              </div>
            </div>
            <div class="p-4 bg-white rounded-xl">
              <div class="font-bold m-2">讀書會目標(50字以上)</div>
              <div>
                <textarea
                  v-model="apply_info.group_goal"
                  class="w-full h-40 rounded-lg border-gray-400"
                  placeholder="請簡述讀書會宗旨。"
                ></textarea>
              </div>
              <div class="text-right text-sm text-red-400">
                目前字數：{{ apply_info.group_goal.length }}字
              </div>
            </div>

            <div class="p-4 col-span-2 bg-white rounded-xl">
              <div class="font-bold m-2">成員名單(5~8人一組)</div>

              <div v-for="(member, index) in memberRow" :key="index">
                <div
                  v-if="index <= rowCount"
                  class="p-4 py-6 flex items-center"
                  style="border-bottom: 1px solid #d1d5db"
                >
                  <div class="m-4 ml-0 font-bold" style="flex-shrink: 0">
                    {{ member.identity }}
                  </div>
                  <div class="grid grid-cols-4 gap-4">
                    <input
                      v-model="member_info.memberId[index]"
                      class="rounded-xl p-1 px-2 border-gray-400 w-full"
                      type="text"
                      placeholder="學號"
                    />

                    <!-- <SelectInfo></SelectInfo> -->

                    <select
                      v-model="member_info.memberMajor[index]"
                      class="rounded-xl p-1 px-2 border-gray-400 w-full"
                      placeholder="請選擇學系"
                    >
                      <option disabled selected value="">請選擇學系</option>
                      <optgroup
                        v-for="(col, index) in college"
                        :key="index"
                        :label="col"
                      >
                        <option
                          v-for="(dept, index) in alldept.filter(
                            (dept) => dept.college === col && dept.abbrev
                          )"
                          :key="index"
                          class="hover:bg-orange-500"
                          :value="dept.abbrev"
                        >
                          {{ dept.abbrev }}
                        </option>
                      </optgroup>
                    </select>

                    <select
                      class="rounded-xl p-1 px-2 border-gray-400 w-full"
                      placeholder="請選擇學系"
                    >
                      <option disabled selected>請選擇年級</option>
                      <option value="一">一</option>
                      <option value="二">二</option>
                      <option value="三">三</option>
                      <option value="四">四</option>
                    </select>

                    <select class="rounded-xl p-1 px-2 border-gray-400 w-full">
                      <option disabled selected>請選擇班級</option>
                      <option value="甲">甲</option>
                      <option value="乙">乙</option>
                      <option value="丙">丙</option>
                      <option value="丁">丁</option>
                    </select>

                    <input
                      v-model="member_info.memberName[index]"
                      class="rounded-xl p-1 px-2 border-gray-400 w-full"
                      type="text"
                      placeholder="姓名"
                    />
                    <input
                      v-model="member_info.memberPhone[index]"
                      class="rounded-xl p-1 px-2 border-gray-400 w-full"
                      type="text"
                      placeholder="手機"
                    />
                    <input
                      v-model="member_info.memberEmail[index]"
                      class="rounded-xl p-1 px-2 border-gray-400 w-full col-span-2"
                      type="text"
                      placeholder="Email"
                    />
                  </div>
                  <!-- <div class="m-4 mr-0" style="flex-shrink: 0;">
                    <button class="w-full rounded-xl mt-3 p-1 text-basic-300 font-bold"><i class="pi pi-times"
                        style="font-size: 24px"></i></button>
                  </div> -->
                </div>
              </div>

              <div class="m-4 mr-0 flex" style="flex-shrink: 0">
                <button
                  id="aleardyreserveBtn"
                  @click="delRow()"
                  class="w-full rounded-xl p-1 text-basic-300"
                  aria-label="刪除"
                >
                  <i class="pi pi-minus-circle" style="font-size: 32px"></i>
                </button>
                <button
                  aria-label="新增"
                  id="aleardyreserveBtn"
                  @click="addRow()"
                  class="w-full rounded-xl p-1 text-basic-300"
                >
                  <i class="pi pi-plus-circle" style="font-size: 32px"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="flex gap-4">
            <button
              @click="apply_save()"
              class="w-full rounded-xl mt-3 p-1 bg-mark-orange text-white font-bold"
            >
              暫存
            </button>
            <button
              @click="apply_submit"
              class="w-full rounded-xl mt-3 p-1 bg-main-second text-white font-bold"
            >
              送出申請
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
// import SelectInfo from './SelectInfo.vue'
export default {
  // components: {
  //   SelectInfo,
  // },
  setup() {
    const rowCount = ref(1);
    const wordCount = ref(0);
    const student = ref([]);
    const alldept = ref([]);
    const college = ref([]);
    const semester = ref([]);
    const notices = ref([]);

    onMounted(() => {
      const id = JSON.parse(sessionStorage.getItem("stuId"));
      console.log(id);
      axios
        .get(process.env.VUE_APP_api_url + "/studygroup/getdept")
        .then((response) => {
          alldept.value = response.data;
          college.value = [
            ...new Set(alldept.value.map((item) => item.college)),
          ];
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      axios
        .get(process.env.VUE_APP_api_url + "/studygroup/getmember")
        .then((response) => {
          const allmem = response.data;
          student.value = allmem.filter((stu) => stu.id == id);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      axios
        .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
        .then((response) => {
          const allNotices = response.data;
          semester.value = allNotices.filter(
            (notice) => notice.type === "學期"
          );
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      axios
        .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
        .then((response) => {
          const allNotices = response.data;
          notices.value = allNotices.filter(
            (notice) => notice.type === "申請注意事項"
          );
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    });

    function addRow() {
      if (rowCount.value <= 7) {
        rowCount.value++;
      }
    }
    function delRow() {
      if (rowCount.value > 1) {
        rowCount.value--;
      }
    }

    const member_info = ref({
      memberId: [],
      memberMajor: [""],
      memberName: [],
      memberPhone: [],
      memberEmail: [],
    });
    const apply_info = ref({
      group_name: "",
      group_type: [],
      group_goal: "",
      booklist: [],
      teacher: "",
      member_info,
      submit: "",
      submitter: JSON.parse(sessionStorage.getItem("stuId")),
    });

    const memberRow = ref([
      { identity: "組長" },
      { identity: "組員1" },
      { identity: "組員2" },
      { identity: "組員3" },
      { identity: "組員4" },
      { identity: "組員5" },
      { identity: "組員6" },
      { identity: "組員7" },
    ]);

    const apply_save = () => {
      apply_info.value.submit = "0";
      axios
        .post(
          process.env.VUE_APP_api_url + "/studygroup/insertapply",
          apply_info.value
        )
        .then((response) => {
          if (response.data.insert == "finished") {
            alert("新增成功");
            location.reload();
          }
        });
    };

    const apply_submit = () => {
      apply_info.value.submit = "1";
      axios
        .post(
          process.env.VUE_APP_api_url + "/studygroup/insertapply",
          apply_info.value
        )
        .then((response) => {
          if (response.data.insert == "finished") {
            alert("新增成功");
            location.reload();
          }
        });
    };

    return {
      student,
      alldept,
      college,
      semester,
      notices,
      member_info,
      apply_info,
      apply_save,
      apply_submit,
      memberRow,
      addRow,
      delRow,
      rowCount,
      wordCount,
    };
  },
};
</script>
