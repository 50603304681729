<template>
  <div id="loader" class="loader loader-border is-active">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../assets/Spin-1s-200px.gif" />
        </div>
        <p class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>
  <div class="flex mt-10 chart-panel">
    <div class="flex-1">
      <highcharts class="chart" :options="GeneralOptions"></highcharts>
      <button v-if="GeneralCredits.length > 0" class="rounded-full bg-gray-500 pl-4 pr-4 pt-0.5 pb-0.5 mb-5 text-white"
        @click="renderModel('General', GeneralCredits)">
        查看明細
      </button>
      <button v-else class="rounded-full bg-black pl-4 pr-4 pt-0.5 pb-0.5 mb-5 text-white" disabled>
        無任何資料
      </button>
    </div>
    <div class="flex-1">
      <highcharts class="chart" :options="EnglishOptions"></highcharts>
      <button v-if="EnglishCredits.length > 0" class="rounded-full bg-gray-500 pl-4 pr-4 pt-0.5 pb-0.5 mb-5 text-white"
        @click="renderModel('English', EnglishCredits)">
        查看明細
      </button>
      <button v-else class="rounded-full bg-black pl-4 pr-4 pt-0.5 pb-0.5 mb-5 text-white" disabled>
        無任何資料
      </button>
    </div>
    <div class="flex-1">
      <highcharts class="chart" :options="ProOptions"></highcharts>
      <button v-if="ProCredits.length > 0" class="rounded-full bg-gray-500 pl-4 pr-4 pt-0.5 pb-0.5 mb-5 text-white"
        @click="renderModel('Pro', ProCredits)">
        查看明細
      </button>
      <button v-else class="rounded-full bg-black pl-4 pr-4 pt-0.5 pb-0.5 mb-5 text-white" disabled>
        無任何資料
      </button>
    </div>
    <widget-container-modal />
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsNoData from "highcharts-no-data-to-display";
HighchartsNoData(Highcharts);

import { openModal, container } from "jenesius-vue-modal";
import WindowModal from "./detail/LearningDetail";

export default {
  components: { WidgetContainerModal: container },
  setup() {
    const GeneralCredits = ref([]);
    const GeneralSeries = ref([]);
    const GeneralOptions = require("./charts/GeneralOptions").GeneralOptions;
    GeneralOptions.series[0].data = GeneralSeries.value;

    const EnglishCredits = ref([]);
    const EnglishSeries = ref([]);
    const EnglishOptions = require("./charts/EnglishOptions").EnglishOptions;
    EnglishOptions.series[0].data = EnglishSeries.value;

    const ProCredits = ref([]);
    const ProSeries = ref([]);
    const ProOptions = require("./charts/ProOptions").ProOptions;
    ProOptions.series[0].data = ProSeries.value;

    onMounted(() => {
      if (!sessionStorage.getItem("GeneralSeries")) {
        axios
          .post(process.env.VUE_APP_api_url + "/getdata/credit/general")
          .then((d) => {
            const year_term = [];
            if (d.data.certiList.length != 0) {
              for (let i = 0; i < d.data.certiList.length; i++) {
                const credit = {
                  act_end: d.data.certiList[i].act_end,
                  act_name: d.data.certiList[i].act_name,
                  act_sn: d.data.certiList[i].act_sn,
                  act_start: d.data.certiList[i].act_start,
                  certi_points: d.data.certiList[i].certi_points,
                  certi_unit_sn: d.data.certiList[i].certi_unit_sn,
                  stmd_id: d.data.certiList[i].stmd_id,
                  year_term: d.data.certiList[i].year_term,
                };
                GeneralCredits.value.push(credit);

                if (!year_term.includes(d.data.certiList[i].year_term)) {
                  year_term.push(d.data.certiList[i].year_term);
                  const series = {
                    name: d.data.certiList[i].year_term,
                    y: d.data.certiList[i].certi_points,
                  };
                  GeneralSeries.value.push(series);
                } else {
                  GeneralSeries.value.forEach((e) => {
                    if (e.name == d.data.certiList[i].year_term) {
                      e.y += d.data.certiList[i].certi_points;
                    }
                  });
                }
              }
            }
            sessionStorage.setItem(
              "GeneralSeries",
              JSON.stringify(GeneralSeries.value)
            );

            sessionStorage.setItem(
              "GeneralCredits",
              JSON.stringify(GeneralCredits.value)
            );
          });
      } else {
        let tempData = JSON.parse(sessionStorage.getItem("GeneralSeries"));
        tempData.forEach((e) => {
          GeneralSeries.value.push(e);
        });

        tempData = JSON.parse(sessionStorage.getItem("GeneralCredits"));
        tempData.forEach((e) => {
          GeneralCredits.value.push(e);
        });
      }

      if (!sessionStorage.getItem("EnglishSeries")) {
        axios
          .post(process.env.VUE_APP_api_url + "/getdata/credit/english")
          .then((d) => {
            const year_term = [];
            if (d.data.certiList.length != 0) {
              for (let i = 0; i < d.data.certiList.length; i++) {
                const credit = {
                  act_end: d.data.certiList[i].act_end,
                  act_name: d.data.certiList[i].act_name,
                  act_sn: d.data.certiList[i].act_sn,
                  act_start: d.data.certiList[i].act_start,
                  certi_points: d.data.certiList[i].certi_points + 1,
                  certi_unit_sn: d.data.certiList[i].certi_unit_sn,
                  stmd_id: d.data.certiList[i].stmd_id,
                  year_term: d.data.certiList[i].year_term,
                };
                EnglishCredits.value.push(credit);

                if (!year_term.includes(d.data.certiList[i].year_term)) {
                  year_term.push(d.data.certiList[i].year_term);
                  if (d.data.certiList[i].certi_points == 0) {
                    d.data.certiList[i].certi_points = 1;
                  }

                  const series = {
                    name: d.data.certiList[i].year_term,
                    y: d.data.certiList[i].certi_points,
                  };

                  EnglishSeries.value.push(series);
                } else {
                  EnglishSeries.value.forEach((e) => {
                    if (e.name == d.data.certiList[i].year_term) {
                      e.y += 1;
                    }
                  });
                }
              }
            }
            sessionStorage.setItem(
              "EnglishSeries",
              JSON.stringify(EnglishSeries.value)
            );

            sessionStorage.setItem(
              "EnglishCredits",
              JSON.stringify(EnglishCredits.value)
            );
          });
      } else {
        let tempData = JSON.parse(sessionStorage.getItem("EnglishSeries"));
        tempData.forEach((e) => {
          EnglishSeries.value.push(e);
        });

        tempData = JSON.parse(sessionStorage.getItem("EnglishCredits"));
        tempData.forEach((e) => {
          EnglishCredits.value.push(e);
        });
      }

      if (!sessionStorage.getItem("ProSeries")) {
        axios
          .post(process.env.VUE_APP_api_url + "/getdata/credit/professional")
          .then((d) => {
            const year_term = [];
            if (d.data.certiList.length != 0) {
              for (let i = 0; i < d.data.certiList.length; i++) {
                const credit = {
                  act_end: d.data.certiList[i].act_end,
                  act_name: d.data.certiList[i].act_name,
                  act_sn: d.data.certiList[i].act_sn,
                  act_start: d.data.certiList[i].act_start,
                  certi_points: d.data.certiList[i].certi_points,
                  certi_unit_sn: d.data.certiList[i].certi_unit_sn,
                  stmd_id: d.data.certiList[i].stmd_id,
                  year_term: d.data.certiList[i].year_term,
                };
                ProCredits.value.push(credit);

                if (!year_term.includes(d.data.certiList[i].year_term)) {
                  year_term.push(d.data.certiList[i].year_term);

                  const series = {
                    name: d.data.certiList[i].year_term,
                    y: d.data.certiList[i].certi_points,
                  };

                  ProSeries.value.push(series);
                } else {
                  ProSeries.value.forEach((e) => {
                    if (e.name == d.data.certiList[i].year_term) {
                      e.y += d.data.certiList[i].certi_points;
                    }
                  });
                }
              }
            }
            sessionStorage.setItem(
              "ProSeries",
              JSON.stringify(ProSeries.value)
            );
            sessionStorage.setItem(
              "ProCredits",
              JSON.stringify(ProCredits.value)
            );
          });
      } else {
        let tempData = JSON.parse(sessionStorage.getItem("ProSeries"));
        tempData.forEach((e) => {
          ProSeries.value.push(e);
        });

        tempData = JSON.parse(sessionStorage.getItem("ProCredits"));
        tempData.forEach((e) => {
          ProCredits.value.push(e);
        });
      }

      setTimeout(() => {
        const loader = document.getElementById("loader");
        loader.classList.remove("is-active");
      }, "1000");
    });

    return {
      GeneralCredits,
      EnglishCredits,
      ProCredits,
      GeneralOptions,
      EnglishOptions,
      ProOptions,
    };
  },
  methods: {
    renderModel: async function (type, credits) {
      openModal(WindowModal, { type: type, credits: credits });
    },
  },
};
</script>

<style>
.highcharts-credits {
  display: none;
}
</style>
