<template>
  <div class="mx-auto max-w-7xl items-center justify-between p-6 pt-1 lg:px-8">
    <div class="grid grid-cols-1 gap-6 text-left md:grid-cols-4 mt-8">
      <div
        v-for="(wish, index) in wishcard"
        :key="index"
        class="relative block max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <Badge
          class="mt-2 w-full"
          style="background-color: rgb(188, 188, 188); border-radius: 20px"
          >{{
            moment(wish.create_time).local().format("YYYY/MM/DD HH:mm:ss")
          }}</Badge
        >

        <div class="grid grid-cols-4 gap-2 my-2 rounded-lg">
          <Tag class="col-span-2" style="border-radius: 20px" severity="info"
            >系所:{{ wish.stuDept }}</Tag
          >
          <Tag class="col-span-2" style="border-radius: 20px" severity="info"
            >學號:{{ wish.stuId }}</Tag
          >
          <Tag
            style="border-radius: 20px; text-align: left !important"
            class="col-span-4 mx-0 cut-text"
            >姓名: {{ wish.nickname }}</Tag
          >
        </div>

        <h5
          class="mb-1 underline mt-3 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          培育能力：
        </h5>

        <p class="break-words">
          {{ wish.skill }}
        </p>

        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          學習坊類型：
        </h5>

        <p class="break-words">
          {{ wish.type }}
        </p>

        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          感興趣的學習坊主題：
        </h5>
        <p class="break-words">
          {{ wish.q_workshop_topic }}
        </p>
        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          推薦的講師：
        </h5>
        <p class="break-words">
          {{ wish.q_lecturers }}
        </p>

        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          推薦人信箱：
        </h5>
        <p class="break-words">
          {{ wish.q_email }}
        </p>

        <div class="text-right text-sm mt-3 col-span-2 my-3">
          來源:
          <span class="underline">{{ wish.ip }}</span>
        </div>

        <div class="grid grid-cols-2 gap-2">
          <button
            @click="passwish(wish.seq)"
            class="button focus:outline-none dark:focus:ring-green-700 focus:ring-4 focus:ring-green-200 text-white rounded-lg hover:bg-green-400"
          >
            通過
          </button>
          <button
            @click="cancelwish(wish.seq)"
            class="w-full text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
          >
            刪除
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "axios";
import Tag from "primevue/tag";
import Badge from "primevue/badge";
import moment from "moment";

const passwish = (wish_id) => {
  ElMessageBox.confirm("確定要通過這個願望清單嗎？", "通過", {
    confirmButtonText: "通過",
    cancelButtonText: "取消",
    type: "success",
  }).then(() => {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/verify", {
        wish_id: wish_id,
        status: 1,
      })
      .then((response) => {
        if (response.data) {
          ElMessage({
            type: "success",
            message: "通過成功！",
          });
          getwishcard();
        }
      });
  });
};

const cancelwish = (wish_id) => {
  ElMessageBox.confirm("確定要刪除這個願望清單嗎？", "刪除", {
    confirmButtonText: "刪除",
    cancelButtonText: "取消",
    type: "error",
  }).then(() => {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/verify", {
        wish_id: wish_id,
        status: 2,
      })
      .then((response) => {
        if (response.data) {
          ElMessage({
            type: "success",
            message: "刪除成功！",
          });
          getwishcard();
        }
      });
  });
};

const wishcard = ref([]);

const getwishcard = () => {
  wishcard.value = [];
  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/getallwish")
    .then((response) => {
      response.data.forEach((element) => {
        if (element.status == 0) {
          let skill_arr = element.skill.split(",");
          if (skill_arr.length > 1) {
            let temp_arr = [];
            skill_arr.forEach((str) => {
              let index = str.indexOf("&");
              let substr = str.substring(0, index);
              substr = substr.replace(/&+[a-zA-Z]/g, "");
              temp_arr.push(substr);
            });
            element.skill = temp_arr.toString();
          } else {
            let index = element.skill.indexOf("&");
            let substr = element.skill.substring(0, index);
            element.skill = substr;
          }

          let type_arr = element.type.split(",");
          if (type_arr.length > 1) {
            let temp_arr = [];
            type_arr.forEach((str) => {
              let index = str.indexOf("&");
              let substr = str.substring(0, index);
              substr = substr.replace(/[a-zA-Z]/g, "");
              temp_arr.push(substr);
            });
            element.type = temp_arr.toString();
          } else {
            let index = element.type.indexOf("&");
            let substr = element.type.substring(0, index);
            element.type = substr;
          }
          wishcard.value.push(element);
        }
      });
    });
};

onMounted(() => {
  getwishcard();
});
</script>

<style>
.cut-text {
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
