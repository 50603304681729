<template>
    <div class="bg-gray-100 m-5 rounded-md p-4">
        <div
            class="flex text-xl p-6 bg-white rounded-lg border border-gray-200 mr-3 shadow-md dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <div class="flex-1 text-left mt-2.5 font-bold">課業守護天使 | 學生預約管理</div>
            <div class="flex-1 text-right">
                <button class="next-apply px-4 py-2" @click="goBack()">返回清單</button>
            </div>
        </div>

        <DataTable class="pt-3 p-3" :value="replysuccesslist" :paginator="true" :rows="10" :filters="filters"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 20]" responsiveLayout="scroll" currentPageReportTemplate="">
            <Column field="subject" header="課程名稱" sortable></Column>
            <Column field="ReserveDate" header="日期"></Column>
            <Column field="ReserveTime" header="時間"></Column>
            <Column field="stuId" header="學號"></Column>
            <Column field="stuName" header="學生姓名"></Column>
            <Column field="place" header="預約地點"></Column>
            <Column header="#" style="width: 5%">
                <template #body="slotProps">
                    <div v-if="slotProps.data.status == 0">
                        <button type="button" @click="openReplyWindow(slotProps.data, slotProps.index)"
                            class="text-white reserveReplybutton font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2">
                            <i class="pi pi-reply py-1"></i>
                        </button>
                    </div>
                    <div v-else>
                        <button @click="openReplyWindow(slotProps.data, slotProps.index)" type="button"
                            class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                            <i class="pi pi-eye py-1"></i>
                        </button>
                    </div>
                </template>
            </Column>
            <template #paginatorstart></template>
            <template #paginatorend></template>
            <template #empty> 無預約紀錄 </template>
        </DataTable>
    </div>
    <widget-container-modal />
</template>

<script>
import { openModal, container } from "jenesius-vue-modal";
import { onMounted, ref } from '@vue/runtime-core';
import { useRouter } from "vue-router";
import axios from 'axios';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import replyWindow from "./Reply.vue";

export default {
    components: {
        WidgetContainerModal: container,
        DataTable,
        Column,
    },
    setup() {
        const router = useRouter();

        const goBack = () => {
            router.push({
                path: "/counselor"
            });
        };

        const replysuccesslist = ref([])
        const stuInfo = ref({})
        onMounted(() => {
            stuInfo.value = JSON.parse(sessionStorage.getItem("stuInfo"))
            axios
                .post(process.env.VUE_APP_api_url + "/guidance/reservepasslist", {
                    tutorId: stuInfo.value.userId,
                })
                .then((response) => {
                    response.data.forEach((element) => {
                        replysuccesslist.value.push(element)
                    });
                });
        })

        const openReplyWindow = async (data, index) => {
            const modal = await openModal(replyWindow, {
                index: index + 1,
                data,
            });

            modal.onclose = () => {
                if (sessionStorage.getItem("reply")) {
                    location.reload();
                    sessionStorage.removeItem("reply")
                }
            }
        };


        return { goBack, replysuccesslist, openReplyWindow }
    },
}
</script>