<template>
    <div class="common-layout">
        <el-container>
            <el-aside width="250px" class="border-2">
                <div class="mt-5 px-4 py-6 rounded-lg">
                    <i class="pi pi-file mb-4" style="font-size: 3rem"></i>
                    <h2 class="title-font font-medium text-2xl text-gray-900">登入記錄</h2>
                    <p class="leading-relaxed">Login Record</p>
                </div>
                <ul>
                    <li class="w-full">
                        <a @click="goBack()"
                            class="adminLink text-lg inline-block p-4 w-full bg-gray-100 hover:text-gray-70 rounded-r-lg  hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">返回上一頁</a>
                    </li>
                </ul>
            </el-aside>
            <el-main>
                <DataTable class="p-5 pt-0" :value="loginRecord" :paginator="true" :rows="15" :filters="filters"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    responsiveLayout="scroll" currentPageReportTemplate="">
                    <Column field="ip_addr" header="IP" style="width:20%;"></Column>
                    <Column field="userid" header="使用者帳號"></Column>
                    <Column field="username" header="使用者姓名"></Column>
                    <Column field="type" header="類型">
                        <template #body="{ data }">
                            <span v-if="data.type == 'LOGIN'" class="text-green-500 font-bold">一般登入</span>
                            <span v-else class="text-red-500 font-bold">管理員登入</span>
                        </template>
                    </Column>
                    <Column field="status" header="登入狀態">
                        <template #body="{ data }">
                            <span v-if="data.status != 'FAILED'" class="text-black">登入成功</span>
                            <span v-else class="text-black">登入失敗</span>
                        </template>
                    </Column>
                    <Column field="time" header="登入時間" style="width: 25%"></Column>
                    <template #paginatorstart></template>
                    <template #paginatorend></template>
                </DataTable>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from 'axios';
import moment from "moment"
import { onMounted, ref } from 'vue';

export default {
    components: {
        DataTable,
        Column,
    },
    setup() {
        const goBack = () => {
            location.href = "/sladmin/";
        };

        onMounted(() => {
            GetLogin();
        })

        const loginRecord = ref([]);
        const GetLogin = () => {
            axios.post(process.env.VUE_APP_api_url + "/admin/getrecord").then((data) => {
                data.data.forEach((item) => {
                    item.time = moment(item.time).format("YYYY-MM-DD HH:mm:ss")
                    loginRecord.value.push(item)
                })
            })
        }

        return {
            goBack, loginRecord
        };
    },
};
</script>
