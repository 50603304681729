<template>
  <div
    class="border rounded-lg text-white p-5 m-5 mt-8 text-left"
    style="background-color: #2db3b3"
  >
    <div class="widget widget-info" style="padding-bottom: 0">
      <h3 class="widget-title">分類設定 Category Manage</h3>
    </div>

    <table
      class="bg-gray-50 w-full text-black rounded-lg text-lg"
      style="table-layout: fixed"
    >
      <tr class="text-lg font-bold border-b-2" style="font-size: 20px">
        <th class="py-3 px-6" style="width: 70px">#</th>
        <th class="py-3 px-6">中文名稱</th>
        <th class="py-3 px-6">英文名稱</th>
        <th class="py-3 px-6">類型</th>
        <th class="py-3 px-6">操作</th>
      </tr>
      <tr v-for="(item, index) in type_list" :key="index">
        <td class="py-3 px-6" style="width: 20px">{{ index + 1 }}</td>
        <td class="py-3 px-6">
          <input
            :id="`topic-${item.id}`"
            :disabled="item.disabled"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="item.topic"
            type="text"
          />
        </td>
        <td class="py-3 px-6">
          <input
            :id="`topic-eng-${item.id}`"
            :disabled="item.disabled"
            v-model="item.topic_eng"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </td>
        <td class="py-3 px-6">
          <select
            :id="`type-${item.id}`"
            :disabled="item.disabled"
            v-model="item.type"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            name="type"
          >
            <option value="topic">學習坊主題</option>
            <option value="skill">培育能力</option>
          </select>
        </td>
        <td class="py-3 px-6">
          <button
            type="button"
            :id="`btn-update-${item.id}`"
            @click="update_item(item)"
            class="text-white p-highlight focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2"
            style="width: 45%"
          >
            編輯
          </button>
          <button
            type="button"
            @click="delete_item(item.id)"
            class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            style="width: 45%"
          >
            移除
          </button>
        </td>
      </tr>

      <tr id="addNewType" style="visibility: collapse">
        <td class="py-3 px-6">{{ type_list.length + 1 }}</td>
        <td class="py-3 px-6">
          <input
            type="text"
            v-model="newType.topic"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="請輸入中文名稱"
            required
          />
        </td>
        <td class="py-3 px-6">
          <input
            type="text"
            id="userId"
            v-model="newType.topic_eng"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="請輸入英文名稱"
            required
          />
        </td>
        <td class="py-3 px-6">
          <select
            id="userType"
            v-model="newType.type"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="topic" selected>學習坊主題</option>
            <option value="skill">培育能力</option>
          </select>
        </td>
        <td class="py-3 px-6">
          <button
            type="button"
            style="width: 45%"
            @click="addNewType()"
            class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            新增
          </button>
          <button
            type="button"
            style="width: 45%"
            @click="showAddNewArea()"
            class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
          >
            取消
          </button>
        </td>
      </tr>
      <tr>
        <td colspan="5" class="text-center py-2">
          <button class="py-0.5 px-2" @click="showAddNewArea()">
            <i class="pi pi-plus"></i>
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";
import { ElNotification, ElMessageBox } from "element-plus";
import _ from "lodash";

const type_list = ref([]);
var update_class =
  "text-white p-highlight focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2";
var success_class =
  "text-white bg-gray-500 hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800";

onMounted(() => {
  gettypelist();
});

const addNewType = () => {
  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/addtype", newType.value)
    .then((response) => {
      if (response.data.status) {
        ElNotification({
          title: "成功",
          message: "新增成功。",
          type: "success",
        });
        newType.value = {};
        showAddNewArea();
        gettypelist();
      } else {
        ElNotification({
          title: "錯誤",
          message: "新增失敗",
          type: "error",
        });
      }
    });
};

const showAddNewArea = () => {
  var show = document.getElementById("addNewType").style.visibility;
  if (show == "collapse") {
    document.getElementById("addNewType").style.visibility = "visible";
  } else {
    document.getElementById("addNewType").style.visibility = "collapse";
  }
};

const sorttype = () => {
  let temp_arr = type_list.value;
  temp_arr = _.orderBy(temp_arr, ["type"], ["desc"]);
  type_list.value = temp_arr;
};

const gettypelist = () => {
  type_list.value = [];
  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/getwishtype")
    .then((response) => {
      response.data.forEach((item) => {
        item.disabled = true;
        type_list.value.push(item);
      });
      sorttype();
    });
};

const newType = ref({
  topic: "",
  topic_eng: "",
  type: "",
});

const delete_item = (type_id) => {
  ElMessageBox.confirm("確定要刪除嗎？", "刪除", {
    confirmButtonText: "刪除",
    cancelButtonText: "取消",
    type: "error",
  }).then(() => {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/delete", {
        id: type_id,
      })
      .then((response) => {
        if (response.data.status) {
          ElNotification({
            title: "成功",
            message: "刪除成功。",
            type: "success",
          });
          gettypelist();
        } else {
          ElNotification({
            title: "錯誤",
            message: "刪除失敗",
            type: "error",
          });
        }
      });
  });
};
const update_item = (item) => {
  if (
    document.getElementById(`btn-update-${item.id}`).innerHTML.trim() == "編輯"
  ) {
    document.getElementById(`btn-update-${item.id}`).classList = success_class
      .split(" ")
      .toString()
      .replaceAll(",", " ");
    document.getElementById(`btn-update-${item.id}`).innerHTML = "完成";
    document.getElementById(`topic-${item.id}`).disabled = false;
    document.getElementById(`topic-eng-${item.id}`).disabled = false;
    document.getElementById(`type-${item.id}`).disabled = false;
  } else {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/update", item)
      .then((response) => {
        if (response.data.status) {
          document.getElementById(`btn-update-${item.id}`).classList =
            update_class.split(" ").toString().replaceAll(",", " ");
          ElNotification({
            title: "成功",
            message: "修改成功。",
            type: "success",
          });
          document.getElementById(`btn-update-${item.id}`).innerHTML = "編輯";
          document.getElementById(`topic-${item.id}`).disabled = true;
          document.getElementById(`topic-eng-${item.id}`).disabled = true;
          document.getElementById(`type-${item.id}`).disabled = true;
        }
      });
  }
};
</script>
