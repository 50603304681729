<template>
    <div class="block p-6 rounded-lg shadow-lg bg-white max-w-md">
        <form>
            <div class="grid grid-cols-2 gap-2">
                <div>
                    <label for="exampleInputEmail1"
                        class="reserveTitle form-label ml-0.5 inline-block text-gray-700">諮詢日期</label>
                    <div class="form-group mb-3">
                        <select placeholder="諮詢日期" @change="changeTime()" v-if="weekAndTime.length != 0" id="tutorTime"
                            v-model="selectIndex"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-100 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option v-for="(option, index) in weekAndTime" :key="index" :value="index">
                                {{ option.Week }} {{ option.Time }}
                            </option>
                        </select>
                        <input type="text" v-else id="tutorTimeText"
                            class="form-control block text-sm w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            v-model="clsDetail.thisWeekDay" disabled />
                    </div>
                </div>
                <div>
                    <label for="exampleInputEmail1"
                        class="reserveTitle form-label ml-0.5 inline-block text-gray-700">諮詢科目</label>
                    <div class="form-group mb-3">
                        <input type="text"
                            class="form-control block text-md w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            v-model="clsDetail.className" disabled />
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-2">
                <div>
                    <label for="exampleInputEmail1"
                        class="reserveTitle form-label ml-0.5 inline-block text-gray-700">諮詢地點</label>
                    <div class="form-group mb-3">
                        <input type="text" v-if="place"
                            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            :value="place" disabled />

                        <input type="text" v-else
                            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            v-model="thisplace" disabled />
                    </div>
                </div>
                <div>
                    <label for="exampleInputEmail1"
                        class="reserveTitle form-label ml-0.5 inline-block text-gray-700">課業守護天使</label>
                    <div class="form-group mb-3">
                        <input type="text"
                            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            v-model="clsDetail.tutorName" disabled />
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-2">
                <div>
                    <label for="exampleInputEmail1"
                        class="reserveTitle form-label ml-0.5 inline-block text-gray-700">學生聯絡電話</label>
                    <div class="relative">
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" focusable="false" class="icon" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512" width="1em" height="1em">
                                <path
                                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
                            </svg>
                        </div>
                        <input type="text" v-model="contactData.stuPhone" maxlength="10"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-100 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="請輸入您的電話" />
                    </div>
                </div>
                <div>
                    <label for="stuEmail" class="reserveTitle form-label ml-0.5 inline-block text-gray-700">學生電子郵件</label>
                    <div class="relative">
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg aria-hidden="true" class="w-5 h-5 text-gray-700 dark:text-gray-400" fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                            </svg>
                        </div>
                        <input type="text" v-model="contactData.stuEmail"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-100 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="請輸入您的信箱" />
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-1 mt-3">
                <div>
                    <label for="Description" class="reserveTitle form-label ml-0.5 inline-block text-gray-700">問題描述</label>
                    <div class="form-group mb-3">
                        <textarea id="message" rows="3" v-model="contactData.description"
                            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-100 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                    </div>
                </div>
            </div>

            <button type="button" id="reserveBtn" @click="ReserveClass"
                class="w-full px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
                預約
            </button>
        </form>
    </div>
</template>

<script>
import { ElNotification } from "element-plus";
import { closeModal, pushModal } from "jenesius-vue-modal";
import { onMounted, ref } from "@vue/runtime-core";
import ReserveWarning from "./ReserveWarning.vue";
import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
    props: {
        seq: Number,
        place: String,
        thisWeekDay: String,
        weekTimeArray: Array,
    },
    setup(props) {
        const clsDetail = ref({});
        const weekAndTime = ref([]);
        const loadingState = ref(0);

        const selectIndex = ref(0);
        const thisplace = ref("");

        onMounted(() => {
            axios
                .post(process.env.VUE_APP_api_url + "/guidance/clsDetail", {
                    id: props.seq,
                })
                .then((response) => {
                    clsDetail.value = response.data[0];
                    if (props.thisWeekDay) {
                        clsDetail.value.thisWeekDay = props.thisWeekDay;
                    }

                    if (props.weekTimeArray) {
                        weekAndTime.value = _.filter(
                            JSON.parse(JSON.stringify(props.weekTimeArray)),
                            (item) => !moment(item.Week).isBefore(moment())
                        );
                        thisplace.value = weekAndTime.value[selectIndex.value].Place;
                    }
                });
        });

        const changeTime = () => {
            thisplace.value = weekAndTime.value[selectIndex.value].Place;
        };

        const contactData = {
            stuPhone: "",
            stuEmail: "",
            description: "",
        };

        const ReserveClass = () => {
            const tutorTime = document.getElementById("tutorTime");
            const tutorTimeText = document.getElementById("tutorTimeText");

            var reserveTime = "";
            if (tutorTime != null) {
                if (tutorTime.options[tutorTime.selectedIndex].text == undefined) {
                    reserveTime = tutorTime.value;
                } else {
                    reserveTime = tutorTime.options[tutorTime.selectedIndex].text;
                }
            } else {
                reserveTime = tutorTimeText.value;
            }

            const stuInfo = JSON.parse(sessionStorage.getItem("stuInfo"));

            const TimeAndWeekArray = _.split(reserveTime, " ");
            const ReserveData = {
                ReserveTime: TimeAndWeekArray[1],
                ReserveDate: TimeAndWeekArray[0],
                subject: clsDetail.value.className,
                place: clsDetail.value.place,
                tutorId: clsDetail.value.tutorId,
                tutorEmail: clsDetail.value.tutorEmail,
                tutorName: clsDetail.value.tutorName,
                stuId: stuInfo.userId,
                stuName: stuInfo.userName,
                stuPhone: contactData.stuPhone,
                stuEmail: contactData.stuEmail,
                description: contactData.description,
                token: clsDetail.value.token,
            };

            if (props.place == undefined) {
                ReserveData.place = thisplace.value;
            } else {
                ReserveData.place = props.place;
            }

            let submitCheck = true;
            let errorMessage = [];
            if (contactData.stuPhone == "") {
                submitCheck = false;
                errorMessage.push("學生聯絡電話");
            }

            if (contactData.stuEmail == "") {
                submitCheck = false;
                errorMessage.push("學生電子郵件");
            }

            if (contactData.description == "") {
                submitCheck = false;
                errorMessage.push("問題描述");
            }

            if (submitCheck) {
                if (ReserveData.tutorName == ReserveData.stuName) {
                    ElNotification({
                        title: "錯誤",
                        message: "無法預約自己的課程。",
                        type: "error",
                    });
                } else {
                    axios
                        .post(
                            process.env.VUE_APP_api_url + "/guidance/insertReserve",
                            ReserveData
                        )
                        .then((state) => {
                            if (!state.data.error) {
                                closeModal();
                                pushModal(ReserveWarning, { ReserveData: ReserveData });
                            } else if (state.data.error == "exist") {
                                ElNotification({
                                    title: "注意",
                                    message: "已有申請該時段紀錄，無法送出！",
                                    type: "warning",
                                });
                            } else if (state.data.error == "full") {
                                ElNotification({
                                    title: "注意",
                                    message: "該課程預約已額滿，請選擇其他時間！",
                                    type: "warning",
                                });
                            } else {
                                ElNotification({
                                    title: "錯誤",
                                    message: "無法送出！",
                                    type: "error",
                                });
                            }
                        })
                        .catch(() => {
                            ElNotification({
                                title: "錯誤",
                                message: "無法送出！",
                                type: "error",
                            });
                        });
                }
            } else {
                ElNotification({
                    title: "錯誤",
                    message: errorMessage.join("、") + "，不可為空！",
                    type: "error",
                });
            }
        };

        return {
            loadingState,
            clsDetail,
            weekAndTime,
            ReserveClass,
            contactData,
            changeTime,
            selectIndex,
            thisplace,
        };
    },
};
</script>
