<template>
    <div class="block p-6 rounded-lg shadow-lg bg-white max-w-md">
        <div class="grid grid-cols-2 gap-2">
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">諮詢日期</label>
                <div class="form-group mb-3">

                    <input type="text" id="tutorTimeText"
                        :value="reserveDetail.ReserveDate + ' ' + reserveDetail.ReserveTime"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">諮詢科目</label>
                <div class="form-group mb-3">
                    <input type="text" :value="reserveDetail.subject"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-2 gap-2">
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">諮詢地點</label>
                <div class="form-group mb-3">

                    <input type="text" id="tutorTimeText" :value="reserveDetail.place"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">課業守護天使</label>
                <div class="form-group mb-3">
                    <input type="text" :value="reserveDetail.tutorName"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 gap-2">
            <div>
                <label for="stuEmail" class="reserveTitle form-label ml-0.5 inline-block text-gray-700">課守電子郵件</label>
                <div class="relative">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-700 dark:text-gray-400" fill="currentColor"
                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                        </svg>
                    </div>
                    <input type="text" v-model="tutorInfo.tutorEmail" disabled
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="mail@sample.com">
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 mt-3">
            <div>
                <label for="Description" class="reserveTitle form-label ml-0.5 inline-block text-gray-700">您的問題</label>
                <div class="form-group mb-3">
                    <textarea id="message" rows="3" :value="reserveDetail.description" disabled
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 mt-3">
            <div>
                <label for="Description"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">課業守護天使回覆</label>
                <div class="form-group mb-3">
                    <textarea id="message" rows="3" :value="reserveDetail.tutorReply" disabled
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                </div>
            </div>
        </div>
        <button type="button" id="reserveBtn" @click="GoBackReserveList"
            class="w-full px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
            返回
        </button>
    </div>

</template>

<script>
import axios from "axios"
import { closeModal } from 'jenesius-vue-modal'
import { onMounted, ref } from '@vue/runtime-core';

export default {
    props: {
        reserveDetail: Object
    },
    setup(props) {
        const GoBackReserveList = () => {
            closeModal();
        }

        const tutorInfo = ref({})

        onMounted(() => {
            axios.post(process.env.VUE_APP_api_url + "/guidance/tutorInfo", {
                tutorId: props.reserveDetail.tutorId
            }).then((response) => {
                Object.assign(tutorInfo.value, response.data[0]);
            })
        })

        return { GoBackReserveList, tutorInfo }
    },
}
</script>