<template>
    <div class="flex text-center">
        <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
        <div class="text-2xl font-black">
            繳交狀況
        </div>
    </div>
    <ul class="ml-6 text-left list-disc">
        <li v-for="notice in notices" :key="notice.order" v-html="notice.content"></li>
    </ul>
    <div class="mt-6 grid grid-cols-2 gap-6">
        <UploadBlock></UploadBlock>
        <!-- <UploadBlock v-for="(row, index) in fake_block_data" :key="index" :data="row"></UploadBlock> -->
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import UploadBlock from "./UploadBlock.vue"
import axios from "axios";

export default {
    components: {
        UploadBlock
    },
    setup() {
        // const fake_block_data = ref([{ count: 1, state: 1 }, { count: 2, state: 2 }, { count: 3, state: 2 }, { count: 4, state: 2 }, { count: 5, state: 3 }, { count: 6, state: 0 }, { count: 7, state: 0 }, { count: 8, state: 0 }])

        const notices = ref([])

        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
                .then((response) => {
                    const allNotices = response.data;
                    notices.value = allNotices.filter((notice) => notice.type === "執行進度表");
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        return {
            // fake_block_data,
            notices,
        }
    }
}
</script>