<template>
    <div class="flex text-center">
        <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
        <div class="text-xl font-black">
            組員進度
        </div>
    </div>
    <div class="m-6 grid grid-cols-2 gap-6 ">

        <div class="p-4 bg-basic-200 rounded-xl text-basic-600 text-left">
            <div class="font-black text-xl">執行進度表</div>
            <div v-for="(stu, index) in students" :key="index">
                <div v-if="stu.identity === '組長'" class="mt-2 p-2 rounded-xl text-white text-left flex items-center"
                    :class="{ 'bg-basic-300': progressPass != 8, 'bg-main-second': progressPass == 8 }">
                    <div v-if="stu.identity === '組長'" class="m-2 mr-2 self-start">
                        <img src="../../../assets/group/star-yellow.png" width="20" height="20" alt="">
                    </div>
                    <div v-if="stu.identity === '組員'" class="m-2 mr-2 self-start">
                        <img src="../../../assets/group/star-blue.png" width="20" height="20" alt="">
                    </div>
                    <div class="grow text-xl" style="flex-grow: 1;">
                        <div>{{ stu.id }}</div>
                        <div>{{ stu.name }}</div>
                    </div>
                    <div class="">
                        <div class="text-center w-24 text-3xl">
                            {{ progressPass }} / 8
                        </div>
                        <!-- <div v-else class="text-center w-24 text-3xl">
                            Done
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="p-4 bg-basic-200 rounded-xl text-basic-600 text-left">
            <div class="font-black text-xl">自評表</div>
            <div v-for="(stu, index) in students" :key="index">
                <div v-if="stu.identity === '組長'"
                    class="mt-2 p-2 bg-main-second rounded-xl text-white text-left flex items-center"
                    :class="{ 'bg-basic-300': noSelfeval != 0, 'bg-main-second': noSelfeval == 0 }">
                    <div v-if="stu.identity === '組長'" class="m-2 mr-2 self-start">
                        <img src="../../../assets/group/star-yellow.png" width="20" height="20" alt="">
                    </div>
                    <div v-if="stu.identity === '組員'" class="m-2 mr-2 self-start">
                        <img src="../../../assets/group/star-blue.png" width="20" height="20" alt="">
                    </div>
                    <div class="grow text-xl" style="flex-grow: 1;">
                        <div>{{ stu.id }}</div>
                        <div>{{ stu.name }}</div>
                    </div>
                    <div class="">
                        <div v-if="noSelfeval == 0" class="text-center w-24 text-3xl">
                            Done
                        </div>
                        <div v-else class="text-center w-24 text-3xl">
                            Un-Done
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-4 bg-basic-200 rounded-xl text-basic-600 text-left">
            <div class="font-black text-xl">獎金分配</div>
            <div v-for="(stu, index) in students" :key="index">
                <div v-if="stu.identity === '組長'"
                    class="mt-2 p-2 bg-main-second rounded-xl text-white text-left flex items-center"
                    :class="{ 'bg-basic-300': noReward != 0, 'bg-main-second': noReward == 0 }">
                    <div v-if="stu.identity === '組長'" class="m-2 mr-2 self-start">
                        <img src="../../../assets/group/star-yellow.png" width="20" height="20" alt="">
                    </div>
                    <div v-if="stu.identity === '組員'" class="m-2 mr-2 self-start">
                        <img src="../../../assets/group/star-blue.png" width="20" height="20" alt="">
                    </div>
                    <div class="grow text-xl" style="flex-grow: 1;">
                        <div>{{ stu.id }}</div>
                        <div>{{ stu.name }}</div>
                    </div>
                    <div class="">
                        <div v-if="noReward == 0" class="text-center w-24 text-3xl">
                            Done
                        </div>
                        <div v-else class="text-center w-24 text-3xl">
                            Un-Done
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-4 bg-basic-200 rounded-xl text-basic-600 text-left row-span-2">
            <div class="font-black text-xl">心得分享</div>

            <div v-for="(stu, index) in students" :key="index"
                class="mt-2 p-2 bg-main-second rounded-xl text-white text-left flex items-center"
                :class="{ 'bg-basic-300': !feedbackPass[0].includes(stu.id.toString()), 'bg-main-second': feedbackPass[0].includes(stu.id.toString()) }">
                <div v-if="stu.identity === '組長'" class="m-2 mr-2 self-start">
                    <img src="../../../assets/group/star-yellow.png" width="20" height="20" alt="">
                </div>
                <div v-if="stu.identity === '組員'" class="m-2 mr-2 self-start">
                    <img src="../../../assets/group/star-blue.png" width="20" height="20" alt="">
                </div>
                <div class="grow text-xl" style="flex-grow: 1;">
                    <div>{{ stu.id }}</div>
                    <div>{{ stu.name }}</div>
                </div>
                <div class="">
                    <div v-if="feedbackPass[0].includes(stu.id.toString())" class="text-center w-24 text-3xl">
                        Done
                    </div>
                    <div v-else class="text-center w-24 text-3xl">
                        Un-Done
                    </div>
                </div>
            </div>
        </div>

        <div class="p-4 bg-basic-200 rounded-xl text-basic-600 text-left row-span-2">
            <div class="font-black text-xl">滿意度問卷</div>

            <div v-for="(stu, index) in students" :key="index"
                class="mt-2 p-2 bg-main-second rounded-xl text-white text-left flex items-center"
                :class="{ 'bg-basic-300': !feedbackPass[1].includes(stu.id.toString()), 'bg-main-second': feedbackPass[1].includes(stu.id.toString()) }">
                <div v-if="stu.identity === '組長'" class="m-2 mr-2 self-start">
                    <img src="../../../assets/group/star-yellow.png" width="20" height="20" alt="">
                </div>
                <div v-if="stu.identity === '組員'" class="m-2 mr-2 self-start">
                    <img src="../../../assets/group/star-blue.png" width="20" height="20" alt="">
                </div>
                <div class="grow text-xl" style="flex-grow: 1;">
                    <div>{{ stu.id }}</div>
                    <div>{{ stu.name }}</div>
                </div>
                <div class="">
                    <div v-if="feedbackPass[1].includes(stu.id.toString())" class="text-center w-24 text-3xl">
                        Done
                    </div>
                    <div v-else class="text-center w-24 text-3xl">
                        Un-Done
                    </div>
                </div>
            </div>

        </div>

        <div class="p-4 bg-basic-200 rounded-xl text-basic-600 text-left">
            <div class="font-black text-xl">符號說明</div>
            <div class="mt-4 grid grid-rows-2 grid-flow-col">
                <div class="flex text-lg items-center font-bold">
                    <img src="../../../assets/group/star-yellow.png" class="m-2 w-7">
                    組長
                </div>

                <div class="flex text-lg items-center font-bold">
                    <img src="../../../assets/group/star-blue.png" class="m-2 w-7">
                    組員
                </div>

                <div class="flex text-lg items-center font-bold">
                    <img src="../../../assets/group/color-green.png" class="m-2 w-7">
                    完成繳交
                </div>

                <div class="flex text-lg items-center font-bold">
                    <img src="../../../assets/group/color-gray.png" class="m-2 w-7">
                    尚未完成
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
    setup() {
        const noSelfeval = ref([])
        const students = ref([])
        const groupId = JSON.parse(sessionStorage.getItem("groupId"))
        const progress = ref([])
        const feedback = ref([])
        const progressPass = ref(0)
        const feedbackPass = ref([[], []])
        const noReward = ref(0)

        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getgroup")
                .then((response) => {
                    const allGroups = response.data;
                    noSelfeval.value = allGroups.filter((group) => group.id == groupId && (group.selfeval == "" || group.selfeval == null));
                    console.log(noSelfeval.value)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getmember")
                .then((response) => {
                    const allmem = response.data;
                    students.value = allmem.filter((stu) => stu.group_id == groupId);
                    noReward.value = (students.value.filter((stu) => stu.reward == null || stu.reward == 0)).length
                    console.log(students)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getprogress")
                .then((response) => {
                    const allprogress = response.data;
                    progress.value = allprogress.filter((pro) => pro.group_id == groupId);
                    progressPass.value = (allprogress.filter((pro) => pro.group_id == groupId && pro.state == 2)).length
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getfeedback")
                .then((response) => {
                    const allfeedback = response.data;
                    feedback.value = allfeedback.filter((back) => back.group_id == groupId);
                    for (let i = 0; i < feedback.value.length; i++) {
                        if (feedback.value[i].reflection != null && feedback.value[i].reflection != "") {
                            feedbackPass.value[0].push(feedback.value[i].student_id)
                        }
                        if (feedback.value[i].satisfaction != null && feedback.value[i].satisfaction != "") {
                            feedbackPass.value[1].push(feedback.value[i].student_id)
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        return {
            noSelfeval,
            students,
            progress,
            feedback,
            progressPass,
            feedbackPass,
            noReward,
        };
    },
}
</script>