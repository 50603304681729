<template>
  <div id="loader" class="loader loader-border is-active">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../assets/Spin-1s-200px.gif" />
        </div>
        <p class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>
  <div class="mt-4 flex flex-wrap justify-center">
    <div v-for="(item, index) in applyList" :key="item.seq"
      class="mt-5 mr-4 p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 text-left">
      <h5 class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
        {{ item[index].name }}
      </h5>
      <span class="text-sm text-gray-500 dark:text-gray-400 font-medium">可申請時間：{{ getLocalDate(item[index].startDate) }}
        ~
        {{ getLocalDate(item[index].endDate) }}</span>

      <div id="description" class="mb-3 font-normal text-gray-700 dark:text-gray-400">
        {{ item[index].description }}
      </div>
      <a v-if="item[index].status == 2" style="cursor: pointer"
        class="cant-apply inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg">
        <i class="pi pi-times"></i>&nbsp;系統維護中
      </a>
      <a @click="goToApplyPage(item[index].eng_name)" style="cursor: pointer"
        class="next-apply inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg">
        前往申請
        <svg class="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clip-rule="evenodd"></path>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { useRouter } from "vue-router";
import { onMounted, ref } from "@vue/runtime-core";

export default {
  setup() {
    const applyList = ref([]);
    const router = useRouter();

    const goToApplyPage = (dest) => {
      router.push({
        path: "/awardapply/" + dest,
      });
    };

    onMounted(() => {
      axios
        .get(process.env.VUE_APP_api_url + "/awardapply/applylist")
        .then((d) => {
          const now = moment().format();
          let awardStatusList = [];
          d.data.forEach((e) => {
            if (e.status == 0) {
              let status = moment(now).isBetween(e.startDate, e.endDate);
              e.status = status;
            }

            awardStatusList.push({
              startDate: e.startDate, 
              endDate: e.endDate,
              eng_name: e.eng_name,
              status: e.status,
            });
            
            sessionStorage.setItem(
              e.eng_name + "_notice",
              JSON.stringify({
                stepOne: e.stepOne,
                stepTwo: e.stepTwo,
                stepThree: e.stepThree,
                stepFour: e.stepFour,
                submitWarning: e.submitWarning,
              })
            );
          });
          sessionStorage.setItem(
            "AwardApplyStatus",
            JSON.stringify(awardStatusList).toString()
          );
          applyList.value.push(d.data);
        })
        .finally(() => {
          const loader = document.getElementById("loader");
          loader.classList.remove("is-active");
        });
    });

    return { goToApplyPage, applyList };
  },
  methods: {
    getLocalDate: function (date) {
      if (date == undefined) return "";
      return moment(date).local().format("YYYY-MM-DD");
    },
  },
};
</script>

<style>
#description {
  white-space: pre-line;
}
</style>
