<template>
  <center class="mb-3 text-lg">
    本學期開放預約諮詢時間： {{ opendate.start_date }} ~ {{ opendate.end_date }}
  </center>
  <center>
    <div
      class="flex bg-gray-200 p-2.5 mb-3 text-gray-600"
      style="width: 70%; border-radius: 50px"
    >
      <button
        id="tableBtn"
        class="flex-1 nowClick font-semibold reserveBtn mr-3"
        @click="ChangeToTablePage"
        style="border-radius: 30px; vertical-align: middle; font-size: 18px"
      >
        <i class="pi pi-calendar mr-1"></i>本週課程
      </button>
      &nbsp;
      <button
        type="button"
        id="searchBtn"
        class="flex-1 font-semibold reserveBtn mr-3"
        @click="ChangeToSearchPage"
        style="border-radius: 30px; vertical-align: middle; font-size: 18px"
      >
        <i class="pi pi-search mr-1"></i>搜尋課程
      </button>
      <button
        type="button"
        id="aleardyreserveBtn"
        class="flex-1 font-semibold reserveBtn"
        @click="ChangeToReservePage"
        style="border-radius: 30px; vertical-align: middle; font-size: 18px"
      >
        <i class="pi pi-list mr-1"></i>已預約課程
      </button>
    </div>
  </center>

  <ReserveTable v-if="PageControler == 1" />
  <ReserveList v-else-if="PageControler == 0" />
  <ReservePage v-else-if="PageControler == 2" />

  <center class="mb-5 text-lg text-red-600">
    ※提醒您諮詢前請與授課教師、課業守護天使或系辦確認時間地點是否有異動。
  </center>
</template>

<script>
import { ref } from "@vue/reactivity";
import ReserveTable from "./ReserveTable.vue";
import ReserveList from "./ReserveList.vue";
import ReservePage from "./ReservePage.vue";
import { onMounted } from "vue";

export default {
  components: { ReserveTable, ReserveList, ReservePage },
  setup() {
    const PageControler = ref(1);

    const ChangeToSearchPage = () => {
      PageControler.value = 0;
      document.querySelector("#aleardyreserveBtn").classList.remove("nowClick");
      document.querySelector("#tableBtn").classList.remove("nowClick");
      document.querySelector("#searchBtn").classList.add("nowClick");
    };

    const ChangeToTablePage = () => {
      PageControler.value = 1;
      document.querySelector("#tableBtn").classList.add("nowClick");
      document.querySelector("#searchBtn").classList.remove("nowClick");
      document.querySelector("#aleardyreserveBtn").classList.remove("nowClick");
    };

    const ChangeToReservePage = () => {
      PageControler.value = 2;
      document.querySelector("#tableBtn").classList.remove("nowClick");
      document.querySelector("#searchBtn").classList.remove("nowClick");
      document.querySelector("#aleardyreserveBtn").classList.add("nowClick");
    };

    const opendate = ref({});
    onMounted(() => {
      opendate.value = JSON.parse(sessionStorage.getItem("guidance_date"));
    });

    return {
      opendate,
      PageControler,
      ChangeToSearchPage,
      ChangeToTablePage,
      ChangeToReservePage,
    };
  },
};
</script>
