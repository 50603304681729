<template>
    <div class="p-6 px-8 bg-white h-full rounded-r-xl">
        <div
            class="h-20 p-6 px-8 mb-6 text-2xl text-basic-600 bg-basic-100 font-bold rounded-2xl shadow-md text-left flex items-center">
            <div class="flex" style="flex-grow: 1;">
                <i class="pi pi-pencil mr-2" style="font-size: 28px;"></i>
                <div>審核查詢</div>
            </div>
            <div class="flex">
                <div class="text-xl">待審核：5 筆</div>
            </div>
        </div>

        <div>


            <DataTable class="p-5" :value="apply" :paginator="true" :rows="10"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :rowsPerPageOptions="[10, 20, 50]" responsiveLayout="scroll" currentPageReportTemplate="">


                <Column field="id" style="width: 5%" header="#"></Column>
                <Column field="group_name" style="width: 10%" header="讀書會名稱"></Column>
                <Column field="id" style="width: 7%" header="人數"></Column>
                <Column field="submitter" style="width: 10%" header="組長姓名"></Column>
                <Column field="booklist" style="width: 10%" header="組長系級"></Column>
                <Column field="booklist" style="width: 10%" header="組長學號"></Column>
                <Column field="teacher" style="width: 12%" header="申請狀態"></Column>
                <!-- <template #body="{ data }">
                            {{ getLocalDate(data.ApplyTime) }}
                        </template> -->
                <Column field="create_time" style="width: 15%" sortable header="提交時間"></Column>
                <Column field="teacher" style="width: 15%" header="查看&審核">
                    <template #body="{ data }">
                        <button @click="openModal('update', value), GoDetailPage(data.seq)" type="button"
                            class="button text-white font-medium rounded-lg font-bold text-md px-3 py-1.5 mr-2 text-center inline-flex items-center">
                            審核
                        </button>
                    </template>
                </Column>
            </DataTable>
        </div>
        <widget-container-modal />

    </div>
</template>
<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { ref, onMounted } from 'vue';
import axios from "axios";
import { container } from "jenesius-vue-modal";

import { openModal } from "jenesius-vue-modal"
import ReviewModal from "./ReviewModal.vue";

openModal(ReviewModal);

export default {
    components: {
        DataTable,
        Column,
        WidgetContainerModal: container,
    },

    setup() {
        const apply = ref([])
        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getgroup")
                .then((response) => {
                    apply.value = response.data
                });
        });

        return {
            openModal,
            apply,
        };

    },
}


</script>