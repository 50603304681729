<template>
  <div class="bg-gray-100 m-5 rounded-md p-4">
    <div
      class="grid grid-cols-1 md:grid-cols-2 text-xl p-6 bg-white rounded-lg border border-gray-200 mr-3 shadow-md dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
      <div class="text-left mt-2.5 font-bold">課業守護天使 | 學生預約管理</div>
      <div class="text-left md:text-right">
        <button class="next-apply px-4 py-2" @click="backToStudent">
          返回學生身份
        </button>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 text-left mt-3">
      <div @click="goReservePass()"
        class="block p-6 bg-white rounded-lg border border-gray-200 mr-3 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <div class="flex flew-row h-full align-center">
          <div class="flex-initial justify-start align-center">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              完成預約
            </h5>
            <p class="text-gray-700 dark:text-gray-400" style="font-size: 43px">
              {{ successReserve }}
            </p>
          </div>
          <div class="flex-1 ml-2 text-center rounded-full">
            <div class="text-right">
              <i class="pi pi-check-circle p-5 bg-gray-200 rounded-full" style="font-size: 4rem"></i>
            </div>
          </div>
        </div>
      </div>
      <div @click="goReply()"
        class="mt-5 md:mt-0 block p-6 bg-white rounded-lg border border-gray-200 mr-3 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
        <div class="flex justify-center flew-row h-full align-center">
          <div class="flex-initial">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              尚未回覆
            </h5>
            <p class="text-gray-700 dark:text-gray-400" style="font-size: 43px">
              {{ noReplyReserve }}
            </p>
          </div>
          <div class="flex-1 ml-2 text-center rounded-full">
            <div class="text-right">
              <i class="pi pi-comments p-5 bg-gray-200 rounded-full" style="font-size: 4rem"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DataTable class="pt-3 p-3" :value="tutorClassList" :paginator="true" :rows="10" :filters="filters"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20]" responsiveLayout="scroll" currentPageReportTemplate="">
      <Column field="department" header="學系" sortable style="width:10%"></Column>
      <Column field="className" header="課程" style="width:15%"></Column>
      <Column field="classTeacher" header="授課教師" style="width:13%"></Column>
      <Column field="place" header="地點" style="width:13%"></Column>
      <Column field="status" header="預約狀態" style="width:15%">
        <template #body="slotProps">
          <div v-if="slotProps.data.status == 0">
            <span class="text-green-600 font-bold">尚可預約</span>
          </div>
          <div v-else-if="slotProps.data.status == 1">
            <span class="text-red-600 font-bold">無法預約</span>
          </div>
        </template>
      </Column>
      <Column field="ReserveSuccess" header="目前預約" sortable></Column>
      <Column header="#">
        <template #body="slotProps">
          <button type="button" @click="openSetting(slotProps.data)"
            class="nopadding-button text-white font-medium rounded-lg text-sm px-2 py-1.5 mr-2 text-center inline-flex items-center">
            <i class="pi pi-cog mr-1"></i>
            設定
          </button>
          <button type="button" @click="enterClassReserve(slotProps.data.seq)"
            class="nopadding-button text-white font-medium rounded-lg text-sm px-2 py-1.5 mr-2 text-center inline-flex items-center">
            <i class="pi pi-eye mr-1"></i>
            進入
          </button>
        </template>
      </Column>
    </DataTable>
  </div>
  <widget-container-modal />
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { openModal, container } from "jenesius-vue-modal";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import axios from "axios";
import store from "../../../../store";
import Setting from "./Setting.vue";

export default {
  components: {
    WidgetContainerModal: container,
    DataTable,
    Column,
  },
  setup() {
    const router = useRouter();

    const tutorClassList = ref([]);
    const successReserve = ref(0);
    const noReplyReserve = ref(0);

    const enterClassReserve = (id) => {
      router.push({
        path: "/counselor/class/" + id,
      });
    };

    const backToStudent = () => {
      store.commit("NotVenue");
    };

    const stuInfo = ref({});

    onMounted(() => {
      stuInfo.value = JSON.parse(sessionStorage.getItem("stuInfo"));
      axios
        .post(process.env.VUE_APP_api_url + "/guidance/classList", {
          tutorId: stuInfo.value.userId,
        })
        .then((classResponse) => {
          classResponse.data.forEach((e) => {
            tutorClassList.value.push(e);
            successReserve.value += e.FinishReserve;
            noReplyReserve.value += e.NoReply;
          });
        });
    });

    const openSetting = (data) => {
      openModal(Setting, { classInfo: data });
    };

    const goReply = () => {
      router.push({
        path: "/counselor/notreply",
      });
    };

    const goReservePass = () => {
      router.push({
        path: "/counselor/reservepass",
      });
    };

    return {
      tutorClassList,
      enterClassReserve,
      successReserve,
      noReplyReserve,
      backToStudent,
      openSetting,
      goReply,
      goReservePass,
    };
  },
};
</script>
