<template>
    <div class="common-layout">
        <el-container>
            <el-aside width="250px" class="border-r-2">
                <div class="mt-5 px-4 py-6 rounded-lg" @click="goToManagerPage('guidance')">
                    <i class="pi pi-file mb-4" style="font-size: 3rem"></i>
                    <h2 class="title-font font-medium text-2xl text-gray-900">獎勵申請</h2>
                    <p class="leading-relaxed">AwardApply</p>
                </div>
                <ul>
                    <li class="w-full" v-for="(item, index) in typeList" :key="item.seq">
                        <a @click="goToVerifyPage(item[index].eng_name)" style="cursor: pointer"
                            class="adminLink text-lg inline-block p-4 w-full text-gray-900 bg-white hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:bg-gray-700 dark:text-white">
                            {{ item[index].name }}
                        </a>
                    </li>
                    <li class="w-full">
                        <a @click="goBack()"
                            class="adminLink text-lg inline-block p-4 w-full bg-gray-100 hover:text-gray-70 rounded-r-lg  hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">返回上一頁</a>
                    </li>
                </ul>
            </el-aside>
            <el-main>
                <div class="mt-6 flex flex-wrap justify-center">
                    <div v-for="(item, index) in typeList" :key="item.seq"
                        class="mt-5 mr-4 p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 text-left">
                        <h5 class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            {{ item[index].name }}
                        </h5>
                        <span class="text-sm text-gray-500 dark:text-gray-400">可申請時間：{{
                            getLocalDate(item[index].startDate)
                        }} ~
                            {{ getLocalDate(item[index].endDate) }}</span>
                        <a @click="goToVerifyPage(item[index].eng_name)" style="cursor: pointer"
                            class="mt-2 next-apply inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white rounded-lg">
                            前往審核
                            <svg class="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { useRouter } from "vue-router";
import { onMounted, ref } from "@vue/runtime-core";

export default {
    setup() {
        const router = useRouter();
        const goToVerifyPage = (dest) => {
            router.push({
                path: "/sladmin/awardlist/" + dest,
            });
        };

        const typeList = ref([]);
        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/awardApply/applylist")
                .then((d) => {
                    typeList.value.push(d.data);
                });
        });

        const goBack = () => {
            router.push({ path: "/sladmin/" });
        };
        
        return { goToVerifyPage, typeList, goBack };
    },
    methods: {
        getLocalDate: function (date) {
            if (date == undefined) return "";
            return moment(date).local().format("YYYY-MM-DD");
        },
    },
};
</script>