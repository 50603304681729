<template>
  <div id="loader" class="loader loader-border is-active">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../assets/Spin-1s-200px.gif" alt="頁面讀取中..." />
        </div>
        <p class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>
  <div style="margin-top: 90px">
    <h1 class="text-4xl">管理員登入</h1>
    <p style="margin-top: -5px">Login</p>

    <div class="LoginPanel">
      <span class="text-red-600">請使用iTouch帳號密碼登入</span>
      <form id="LoginForm" action="https://mobile.cycu.edu.tw/api/sso/itouch_login/login1.jsp">
        <div>
          <input type="text" name="UserNm" v-model="UserNm" class="input-area" placeholder="人事代碼" />
        </div>
        <div>
          <input id="UserPasswd" type="password" class="input-area" name="UserPasswd" v-model="UserPasswd"
            placeholder="密碼" />
          <i @click="togglePassword(showPwd)" id="togglePassword" class="eye-icon pi pi-eye"></i>
        </div>

        <input type="hidden" name="returnPath" value="https://selflearning1.cycu.edu.tw/api/login/adminLogin" />
        <input type="hidden" name="failPath" value="https://selflearning1.cycu.edu.tw/api/login/Error" />

        <button type="button" @click="Login()" class="login-button" id="Login">
          登入
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";

export default {
  setup() {
    var showPwd = ref(true);
    const togglePassword = () => {
      showPwd.value = !showPwd.value;
      if (showPwd.value) {
        document.getElementById("UserPasswd").setAttribute("type", "text");
        document.querySelector(".eye-icon").classList.remove("pi-eye");
        document.querySelector(".eye-icon").classList.add("pi-eye-slash");
      } else {
        document.getElementById("UserPasswd").setAttribute("type", "password");
        document.querySelector(".eye-icon").classList.add("pi-eye");
        document.querySelector(".eye-icon").classList.remove("pi-eye-slash");
      }
    };

    var UserNm = ref("");
    var UserPasswd = ref("");

    const Login = () => {
      axios
        .post(process.env.VUE_APP_api_url + "/login/check", {
          userNm: UserNm.value,
        })
        .then((result) => {
          if (result.data) {
            document.getElementById("Login").innerHTML = "管理員登入中...";
            document.getElementById("Login").disabled = true;
            setTimeout(() => {
              document.getElementById("LoginForm").submit();
            }, "1200");
          } else {
            alert("登入失敗！非管理員請勿登入");
          }
        });
    };

    return { Login, UserNm, UserPasswd, togglePassword, showPwd };
  },
};
</script>
