<template>
  <div
    class="border rounded-lg text-white p-5 m-5 text-left"
    style="background-color: #2db3b3"
  >
    <div class="widget widget-info flex" style="padding-bottom: 0">
      <h3 class="widget-title">資料匯入 Data Import</h3>
    </div>
    <input
      class="col-span-3 bg-white text-black"
      @change="previewFiles"
      type="file"
      name="pro_data"
      id="pro_data"
    />
    <button
      class="text-black p-2 px-5 bg-white ml-2"
      @click="importData()"
      :disabled="!canUpload"
    >
      上傳資料
    </button>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref } from "vue";

const FileInfo = ref([]);
const canUpload = ref(false);

const previewFiles = (event) => {
  if (
    event.target.files[0].type !=
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
    event.target.files[0].type != "text/csv"
  ) {
    alert("檔案類型錯誤！");
    document.getElementById("pro_data").value = "";
  } else {
    FileInfo.value = event.target.files[0];
    canUpload.value = true;
  }
};

const importData = async () => {
  const adminId = sessionStorage
    .getItem("adminId")
    .replace('"', "")
    .replace('"', "");
  const file = FileInfo.value;
  let reader = new FileReader();
  reader.readAsArrayBuffer(file);
  reader.onload = async () => {
    let blob = new Blob([reader.result], { type: file.type });
    let formData = new FormData();
    formData.append("file", blob, file.name);
    formData.append("userId", adminId);
    try {
      const response = await axios.post(
        process.env.VUE_APP_api_url + "/awardapply/pro/insertdata",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data) {
        alert("上傳成功");
      }
    } catch (error) {
      console.error(error);
    }
  };
};
</script>
