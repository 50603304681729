<template>
  <div v-if="!IsLogin" class="inline-block w-full">
    <LoginPanel></LoginPanel>
  </div>
  <div v-else class="home inline-block w-full">
    <StudentInfo @on-change="changeIsLogin"></StudentInfo>
  </div>
</template>

<script>
import StudentInfo from "../components/StudentInfo.vue";
import LoginPanel from "../components/LoginPanel.vue";
import store from "@/store/index.js";
import { onMounted, ref, computed } from "@vue/runtime-core";
import { useCookies } from "vue3-cookies";
import axios from "axios";

export default {
  name: "Home",
  components: {
    StudentInfo,
    LoginPanel,
  },
  setup() {
    const userId = ref("");

    const IsLogin = computed(() => {
      return store.state.IsLogin;
    });

    const { cookies } = useCookies();

    onMounted(async () => {
      var cookieToken = cookies.get("isLogin");
      if (window.location.href.includes("localhost")) {
        store.commit("Login");
        IsLogin.value = true;
      } else if (cookieToken) {
        if (!localStorage.getItem("jwt-token")) {
          await axios
            .get(process.env.VUE_APP_api_url + "/login/register", { stuId: cookies.get("userId") })
            .then((response) => {
              localStorage.setItem("jwt-token", response.data.token);
            });
        }
        store.commit("Login");
      } else {
        store.commit("Logout");
      }
    });

    return { userId, IsLogin };
  },
};
</script>
