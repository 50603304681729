<template>
    <div id="reserve-export" class="loader loader-border">
        <div class="loader-area">
            <div class="loader-inner">
                <div class="loader-image">
                    <img src="../../../assets/Spin-1s-200px.gif" />
                </div>
                <p class="loader-text">匯出Excel中...</p>
            </div>
        </div>
    </div>
    <DataTable class="p-5" :value="classList" :paginator="true" :rows="10" :filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]" responsiveLayout="scroll" currentPageReportTemplate="">
        <template #header>
            <div class="grid grid-nogutter">
                <div class="col-6" style="text-align: left">
                    <span class="p-input-icon-left w-full">
                        <i class="pi pi-search"></i>
                        <InputText v-model="searchKey" @input="searchClass" class="w-full" placeholder="搜尋學生(不限欄位)..." />
                    </span>
                </div>
            </div>
        </template>
        <Column field="count" header="#"></Column>
        <Column field="stuName" header="預約學生"></Column>
        <Column field="subject" header="開課名稱"></Column>
        <Column field="place" header="預約地點"></Column>
        <Column field="tutorName" header="課業守護天使"></Column>
        <Column field="status" header="目前預約狀態">
            <template #body="{ data }">
                <span v-if="data.status == 0"
                    class="bg-blue-100 text-blue-800 text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-blue-200 dark:text-blue-800">
                    <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                            clip-rule="evenodd"></path>
                    </svg>
                    等待回覆
                </span>
                <span v-else-if="data.status == 1"
                    class="bg-green-100 text-green-800 text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-green-200 dark:text-green-800">
                    <i class="pi pi-check mr-1"></i>
                    預約成功
                </span>
                <span v-else-if="data.status == 2"
                    class="bg-red-100 text-red-800 text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-red-200 dark:text-red-800">
                    <i class="pi pi-times mr-1"></i>
                    無法預約
                </span>
                <span v-else-if="data.status == 3"
                    class="bg-yellow-400  text-white text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-red-200 dark:text-red-800">
                    <i class="pi pi-times-circle mr-1"></i>
                    預約失敗
                </span>
                <span v-else-if="data.status == -1"
                    class="bg-gray-100 text-black text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-red-200 dark:text-red-800">
                    <i class="pi pi-refresh mr-1"></i>
                    取消預約
                </span>
            </template>
        </Column>
        <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{ data }">
                <button @click="renderModel(data)" type="button"
                    class="button text-white font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center">
                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path fill-rule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
            </template>
        </Column>
        <template #paginatorstart>
            <button style="background-color: #2db3b3" @click="exportExcel()"
                class="text-white text-bolder p-5 pt-1 pb-1 rounded">
                匯出Excel
            </button>
        </template>
        <template #paginatorend></template>
    </DataTable>
    <widget-container-modal />
</template>

<script>
import { openModal, container } from "jenesius-vue-modal";
import { ref, onMounted } from "@vue/runtime-core";
import ReserveDetail from "./ReserveDetail.vue"
import axios from "axios";
import _ from "lodash";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";

export default {
    components: {
        WidgetContainerModal: container,
        DataTable,
        Column,
        InputText
    },
    setup() {
        const classList = ref([]);
        const searchKey = ref("");

        onMounted(() => {
            axios
                .post(process.env.VUE_APP_api_url + "/guidance/getReserveInfo")
                .then((response) => {
                    let count = 1
                    response.data.forEach((e) => {
                        e.count = count
                        classList.value.push(e);
                        count += 1
                    });
                    sessionStorage.setItem("reserveInfo", JSON.stringify(classList.value));
                }).catch(() => {
                    alert("課表讀取失敗，請至匯入課程頁面啟用課表。")
                });;
        });

        const searchClass = () => {
            const tempdata = JSON.parse(sessionStorage.getItem("reserveInfo"));
            classList.value = _.filter(tempdata, (item) => {
                if (item.subject.includes(searchKey.value)) {
                    return item.subject.includes(searchKey.value);
                } else if (item.stuName.includes(searchKey.value)) {
                    return item.stuName.includes(searchKey.value);
                } else if (item.stuId.includes(searchKey.value)) {
                    return item.stuId.includes(searchKey.value);
                } else if (item.tutorId.includes(searchKey.value)) {
                    return item.tutorId.includes(searchKey.value);
                } else if (item.tutorName.includes(searchKey.value)) {
                    return item.tutorName.includes(searchKey.value);
                } else if (item.stuEmail.includes(searchKey.value)) {
                    return item.stuEmail.includes(searchKey.value);
                } else if (item.place.includes(searchKey.value)) {
                    return item.place.includes(searchKey.value);
                }
            });
        };

        const renderModel = (data) => {
            openModal(ReserveDetail, { reserveDetail: data });
        }

        const exportExcel = () => {
            const exportData = classList.value.slice().reverse();
            axios
                .post(
                    process.env.VUE_APP_api_url + "/guidance/reserve/export",
                    exportData
                )
                .then((response) => {
                    const loader = document.getElementById("reserve-export");
                    loader.classList.add("is-active");
                    setTimeout(() => {
                        const downloadAnchor = document.createElement("a");
                        downloadAnchor.setAttribute(
                            "href",
                            process.env.VUE_APP_api_url +
                            response.data
                        );
                        document.body.appendChild(downloadAnchor);
                        downloadAnchor.click();
                        document.body.removeChild(downloadAnchor);
                        loader.classList.remove("is-active");
                    }, 1500);
                });
        };

        return { classList, searchKey, searchClass, renderModel, exportExcel };
    },
};
</script>
