<template>
  <div class="mx-auto max-w-7xl p-6 pt-1 lg:px-8 mt-8">
    <div ref="waterfall" class="waterfall">
      <div
        v-for="(feedback, index) in pendingFeedbacks"
        :key="index"
        class="item"
      >
        <Badge
          class="mt-2 w-full"
          style="background-color: rgb(188, 188, 188); border-radius: 20px"
        >
          {{
            moment(feedback.submission_time)
              .local()
              .format("YYYY/MM/DD HH:mm:ss")
          }}
        </Badge>

        <h5
          class="mb-2 mt-3 text-xl font-bold tracking-tight text-gray-900 dark:text-white"
        >
          {{ feedback.recommended_course_name }}
        </h5>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 my-2 text-sm">
          <div class="bg-gray-100 p-2 rounded flex flex-col">
            <span class="font-semibold mb-1">開課系所：</span>
            <span class="break-words">{{ feedback.teaching_unit }}</span>
          </div>
          <div class="bg-gray-100 p-2 rounded flex flex-col">
            <span class="font-semibold mb-1">開課老師：</span>
            <span class="break-words">{{ feedback.teacher }}</span>
          </div>
          <div class="bg-gray-100 p-2 rounded flex flex-col">
            <span class="font-semibold mb-1">課程代碼：</span>
            <span class="break-words">{{ feedback.course_code }}</span>
          </div>
          <div class="bg-gray-100 p-2 rounded flex flex-col">
            <span class="font-semibold mb-1">推薦學期：</span>
            <span class="break-words">{{ feedback.recommended_semester }}</span>
          </div>
        </div>

        <div class="my-2 p-2 bg-gray-100 rounded-lg text-sm">
          <p>
            <span class="font-semibold">學生：</span
            >{{ feedback.student_name }} ({{ feedback.student_id }})
          </p>
          <p>
            <span class="font-semibold">系所：</span>{{ feedback.department }}
          </p>
          <p>
            <span class="font-semibold">手機號碼：</span>{{ feedback.phone }}
          </p>
        </div>

        <h5
          class="mb-1 mt-3 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          課程推薦心得：
        </h5>
        <p class="break-words">
          {{ truncateFeedback(feedback.feedback, feedback.showFullFeedback) }}
          <span
            v-if="feedback.feedback.length > 100"
            class="text-blue-500 cursor-pointer"
            @click="toggleFeedback(index)"
          >
            {{ feedback.showFullFeedback ? "收起" : "更多..." }}
          </span>
        </p>
        <span class="text-xs text-gray-500 mt-1 block">
          字數：{{ feedback.feedback.length }}
        </span>

        <div class="grid grid-cols-2 gap-2 mt-4">
          <button
            @click="approveFeedback(feedback.id)"
            class="button focus:outline-none dark:focus:ring-green-700 focus:ring-4 focus:ring-green-200 text-white rounded-lg hover:bg-green-400"
          >
            通過
          </button>
          <button
            @click="rejectFeedback(feedback.id)"
            class="w-full text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
          >
            不通過
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, nextTick } from "vue";
import { ElMessageBox } from "element-plus";
import axios from "axios";
import Badge from "primevue/badge";
import moment from "moment";
import Masonry from "masonry-layout";

const pendingFeedbacks = ref([]);
const waterfall = ref(null);
let msnry = null;

const getPendingFeedbacks = () => {
  axios
    .post(process.env.VUE_APP_api_url + "/feedback/course/feedback")
    .then((response) => {
      pendingFeedbacks.value = response.data
        .filter((item) => item.review_status === "待審核")
        .map((item) => ({
          ...item,
          showFullFeedback: false,
        }));
      sessionStorage.setItem(
        "course_feedback",
        JSON.stringify(pendingFeedbacks.value)
      );

      nextTick(() => {
        initMasonry();
      });
    });

  nextTick(() => {
    initMasonry();
  });
};

const approveFeedback = (feedbackId) => {
  ElMessageBox.confirm("確定要通過這條評價嗎？", "通過", {
    confirmButtonText: "通過",
    cancelButtonText: "取消",
    type: "success",
  }).then(() => {
    axios
      .post(process.env.VUE_APP_api_url + "/feedback/course/update", {
        status: "通過",
        feedback_id: feedbackId,
      })
      .then((response) => {
        if (response.data.id) {
          // 更新成功，重新獲取反饋列表
          getPendingFeedbacks();
        } else {
          // 處理錯誤
          ElMessageBox.alert("更新失敗，請重試", "錯誤", { type: "error" });
        }
      })
      .catch((error) => {
        console.error("更新評價時出錯:", error);
        ElMessageBox.alert("更失敗，請重試", "錯誤", { type: "error" });
      });
  });
};

const rejectFeedback = (feedbackId) => {
  ElMessageBox.confirm("確定刪除這條評價嗎？", "刪除", {
    confirmButtonText: "刪除",
    cancelButtonText: "取消",
    type: "error",
  }).then(() => {
    axios
      .post(process.env.VUE_APP_api_url + "/feedback/course/update", {
        status: "不通過",
        feedback_id: feedbackId,
      })
      .then((response) => {
        if (response.data.id) {
          // 更新成功，重新獲取反饋列表
          getPendingFeedbacks();
        } else {
          // 處理錯誤
          ElMessageBox.alert("更新失敗，請重試", "錯誤", { type: "error" });
        }
      })
      .catch((error) => {
        console.error("更新評價時出錯:", error);
        ElMessageBox.alert("更新失敗，請重試", "錯誤", { type: "error" });
      });
  });
};

const truncateFeedback = (feedback, showFull) => {
  if (showFull || feedback.length <= 100) return feedback;
  return feedback.slice(0, 100) + "...";
};

const toggleFeedback = (index) => {
  pendingFeedbacks.value[index].showFullFeedback =
    !pendingFeedbacks.value[index].showFullFeedback;
  nextTick(() => {
    msnry.layout();
  });
};

const initMasonry = () => {
  msnry = new Masonry(waterfall.value, {
    itemSelector: ".item",
    columnWidth: ".item",
    percentPosition: true,
    gutter: 16, // Added this line to set the gap between items
  });
};

onMounted(() => {
  getPendingFeedbacks();
});
</script>

<style scoped>
.cut-text {
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.waterfall {
  width: 100%;
  margin: 0 auto;
}
.waterfall .item {
  margin-bottom: 16px; /* Modified this line to match the gutter value */
}
.item {
  width: calc(
    100% - 16px
  ); /* Modified the width calculation to account for the gap */
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.item:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media (min-width: 640px) {
  .item {
    width: calc(50% - 16px);
  }
}

@media (min-width: 768px) {
  .item {
    width: calc(33.333% - 16px);
  }
}

@media (min-width: 1024px) {
  .item {
    width: calc(25% - 16px);
  }
}
</style>
