<template>
  <div id="export" class="loader loader-border">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../../assets/Spin-1s-200px.gif" alt="頁面讀取中..." />
        </div>
        <p class="loader-text">匯出Excel中...</p>
      </div>
    </div>
  </div>
  <div class="mx-auto max-w-7xl items-center justify-between p-6 pt-1 lg:px-8">
    <span class="p-input-icon-left mt-8 w-full">
      <i class="pi pi-search"></i>
      <InputText
        v-model="searchkey"
        @input="search"
        placeholder="Search"
        class="w-full rounded-lg"
      />
    </span>
    <div class="mx-0 text-left mt-3">
      <button
        @click="exportExcel()"
        style="background-color: #2db3b3"
        class="text-white text-bolder p-5 pt-1 pb-1 rounded"
      >
        匯出Excel
      </button>
    </div>
    <div class="grid grid-cols-1 gap-6 text-left md:grid-cols-4 mt-4">
      <div
        v-for="(wish, index) in wishcard"
        :key="index"
        style="height: fit-content"
        class="relative block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <Badge
          class="mt-2 w-full"
          style="background-color: rgb(188, 188, 188); border-radius: 20px"
          >{{
            moment(wish.create_time).local().format("YYYY/MM/DD HH:mm:ss")
          }}</Badge
        >

        <div class="grid grid-cols-4 gap-2 my-2 rounded-lg">
          <Tag class="col-span-2" style="border-radius: 20px" severity="info">{{
            wish.stuDept
          }}</Tag>
          <Tag class="col-span-2" style="border-radius: 20px" severity="info">{{
            wish.stuId
          }}</Tag>
          <Tag
            style="border-radius: 20px; text-align: left !important"
            class="col-span-4 mx-0"
            >姓名: {{ wish.nickname }}</Tag
          >
        </div>

        <h5
          class="mb-1 underline mt-3 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          培育能力：
        </h5>

        <p class="break-words">
          {{ wish.skill }}
        </p>

        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          學習坊類型：
        </h5>

        <p class="break-words">
          {{ wish.type }}
        </p>

        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          感興趣的學習坊主題：
        </h5>
        <p class="break-words">
          {{ wish.q_workshop_topic }}
        </p>
        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          推薦的講師：
        </h5>
        <p class="break-words">
          {{ wish.q_lecturers }}
        </p>

        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          推薦人信箱：
        </h5>
        <p class="break-words">
          {{ wish.q_email }}
        </p>

        <div class="text-right text-sm mt-3 col-span-2 my-3">
          來源:
          <span class="underline">{{ wish.ip }}</span>
        </div>

        <button
          v-if="wish.status == 3"
          :disabled="wish.status == 1"
          @click="showcard(wish.seq, index)"
          style="background-color: #2db3b3"
          class="w-full text-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        >
          顯示
        </button>
        <button
          v-else-if="wish.status == 1"
          @click="hidecard(wish.seq, index)"
          class="w-full text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        >
          隱藏
        </button>
        <button
          v-if="wish.status != 2"
          @click="delcard(wish.seq, index)"
          class="w-full text-gray-900 mt-2 bg-gray-300 border border-gray-300 focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        >
          刪除
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from "lodash";
import axios from "axios";
import { onMounted, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import Tag from "primevue/tag";
import Badge from "primevue/badge";
import moment from "moment";
import InputText from "primevue/inputtext";

const searchkey = ref("");
const search = () => {
  const temp_data = JSON.parse(sessionStorage.getItem("wishcard"));
  if (searchkey.value) {
    const filteredData = _.filter(temp_data, (item) => {
      let hasMatch = false;
      for (const [key, value] of Object.entries(item)) {
        if (value !== null && key !== null && typeof value === "string") {
          if (value.toLowerCase().includes(searchkey.value.toLowerCase())) {
            hasMatch = true;
            break;
          }
        }
      }
      return hasMatch;
    });
    wishcard.value = filteredData;
  } else {
    wishcard.value = temp_data;
  }
};

const changebutton = (index, status) => {
  wishcard.value[index].status = status;
};

const showcard = (wish_id, index) => {
  ElMessageBox.confirm("確定要顯示這個願望嗎？", "顯示", {
    confirmButtonText: "顯示",
    cancelButtonText: "取消",
    type: "success",
  }).then(() => {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/verify", {
        wish_id: wish_id,
        status: 1,
      })
      .then((response) => {
        if (response.data) {
          ElMessage({
            type: "success",
            message: "顯示成功！",
          });
          changebutton(index, 1);
        }
      });
  });
};

const delcard = (wish_id, index) => {
  ElMessageBox.confirm("確定要刪除這個願望嗎？", "刪除", {
    confirmButtonText: "刪除",
    cancelButtonText: "取消",
    type: "error",
  }).then(() => {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/verify", {
        wish_id: wish_id,
        status: 2,
      })
      .then((response) => {
        if (response.data) {
          ElMessage({
            type: "success",
            message: "刪除成功！",
          });
          changebutton(index, 2);
          _.remove(wishcard.value, (obj) => obj.status === 2);
        }
      });
  });
};

const hidecard = (wish_id, index) => {
  ElMessageBox.confirm("確定要隱藏這個願望嗎？", "隱藏", {
    confirmButtonText: "隱藏",
    cancelButtonText: "取消",
    type: "error",
  }).then(() => {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/verify", {
        wish_id: wish_id,
        status: 3,
      })
      .then((response) => {
        if (response.data) {
          ElMessage({
            type: "success",
            message: "隱藏成功！",
          });
          changebutton(index, 3);
        }
      });
  });
};

const wishcard = ref([]);
const getwishcard = () => {
  wishcard.value = [];
  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/getallwish")
    .then((response) => {
      response.data.forEach((element) => {
        if (element.status != 2) {
          if (element.status == 1 || element.status == 3) {
            let skill_arr = element.skill.split(",");
            if (skill_arr.length > 1) {
              let temp_arr = [];
              skill_arr.forEach((str) => {
                let index = str.indexOf("&");
                let substr = str.substring(0, index);
                substr = substr.replace(/&+[a-zA-Z]/g, "");
                temp_arr.push(substr);
              });
              element.skill = temp_arr.toString();
            } else {
              let index = element.skill.indexOf("&");
              let substr = element.skill.substring(0, index);
              element.skill = substr;
            }
            let type_arr = element.type.split(",");
            if (type_arr.length > 1) {
              let temp_arr = [];
              type_arr.forEach((str) => {
                let index = str.indexOf("&");
                let substr = str.substring(0, index);
                substr = substr.replace(/[a-zA-Z]/g, "");
                temp_arr.push(substr);
              });
              element.type = temp_arr.toString();
            } else {
              let index = element.type.indexOf("&");
              let substr = element.type.substring(0, index);
              element.type = substr;
            }
            wishcard.value.push(element);
          }
        }
      });
      sessionStorage.setItem("wishcard", JSON.stringify(wishcard.value));
    });
};

onMounted(() => {
  getwishcard();
});

const exportExcel = () => {
  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/export", wishcard.value)
    .then((response) => {
      const loader = document.getElementById("export");
      loader.classList.add("is-active");
      setTimeout(() => {
        const downloadAnchor = document.createElement("a");
        downloadAnchor.setAttribute(
          "href",
          process.env.VUE_APP_api_url + "/uploads/" + response.data.path
        );
        downloadAnchor.setAttribute("download", response.data.fileName);
        document.body.appendChild(downloadAnchor);
        downloadAnchor.click();
        document.body.removeChild(downloadAnchor);
        loader.classList.remove("is-active");
      }, 1500);
    });
};
</script>
