<template>
    <div class="common-layout">
        <el-container>
            <el-aside width="250px" class="border-r-2">
                <div class="mt-5 px-4 py-6 rounded-lg" @click="goToManagerPage('guidance')">
                    <i class="pi pi-file mb-4" style="font-size: 3rem"></i>
                    <h2 class="title-font font-medium text-2xl text-gray-900">線上輔導預約</h2>
                    <p class="leading-relaxed">Guidance</p>
                </div>
                <ul>
                    <li class="w-full">
                        <a @click="changePage(0)" :class="{ active: pageIndex == 0 }"
                            class="adminLink text-lg inline-block p-4 w-full bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                            aria-current="page">課程列表</a>
                    </li>
                    <li class="w-full">
                        <a @click="changePage(3)" :class="{ active: pageIndex == 3 }"
                            class="adminLink text-lg inline-block p-4 w-full bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                            aria-current="page">目前預約清單</a>
                    </li>
                    <li class="w-full">
                        <a @click="changePage(1)" :class="{ active: pageIndex == 1 }"
                            class="adminLink text-lg inline-block p-4 w-full bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                            aria-current="page">匯入課程資訊</a>
                    </li>
                    <li class="w-full">
                        <a @click="changePage(2)" :class="{ active: pageIndex == 2 }"
                            class="adminLink text-lg inline-block p-4 w-full bg-white hover:text-gray-700 hover:bg-gray-50 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">顯示設定</a>
                    </li>
                    <li class="w-full">
                        <a @click="goBack()"
                            class="adminLink text-lg inline-block p-4 w-full bg-gray-100 hover:text-gray-70 rounded-r-lg  hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">返回上一頁</a>
                    </li>
                </ul>
            </el-aside>
            <el-main>
                <List v-if="pageIndex == 0" />
                <Import v-else-if="pageIndex == 1" />
                <Setting v-else-if="pageIndex == 2" />
                <Reserve v-else-if="pageIndex == 3" />
            </el-main>
        </el-container>
    </div>
</template>

<script>
import List from "./List.vue";
import Setting from "./Setting.vue";
import Import from "./Import.vue";
import Reserve from "./Reserve.vue";
import { useRouter } from "vue-router";
import { ref } from '@vue/reactivity';

export default {
    components: {
        List,
        Setting,
        Import,
        Reserve
    },
    setup() {
        const pageIndex = ref(0)
        const router = useRouter();

        const changePage = (index) => {
            pageIndex.value = index;
        }

        const goBack = () => {
            router.push({ path: "/sladmin/" });
        };

        return { goBack, pageIndex, changePage }
    },
}
</script>