<template>
    <div v-for="(pro, index) in  progress " :key="index" class="p-3 bg-basic-100 rounded-xl shadow-md">
        <div class="m-auto flex">
            <img v-if="pro.state == 0" class="mr-2 h-7" src="../../../../assets/group/small-nofile.png" alt="">
            <img v-if="pro.state == 1" class="mr-2 h-7" src="../../../../assets/group/small-fail.png" alt="">
            <img v-if="pro.state == 2" class="mr-2 h-7" src="../../../../assets/group/small-pass.png" alt="">
            <img v-if="pro.state == 3" class="mr-2 h-7" src="../../../../assets/group/small-unknow.png" alt="">
            <div class="bg-white text-sm rounded-xl flex grow" style="flex-grow:1;">
                <div class="mr-auto p-1 px-2 flex">
                    第
                    <div class="mx-1 font-bold">{{ pro.order }}</div>
                    次執行進度表
                </div>
                <div v-if="pro.state == 0" class="p-1 px-2 bg-basic-300 rounded-r-xl flex items-center font-black">
                    尚未上傳
                </div>
                <div v-else-if="pro.state == 1" class="p-1 px-2 bg-red-300 rounded-r-xl flex items-center font-black">
                    審核未過
                </div>
                <div v-else-if="pro.state == 2"
                    class="p-1 px-2 bg-mark-lightGreen rounded-r-xl flex items-center font-black">
                    審核通過
                </div>
                <div v-else class="p-1 px-2 bg-mark-yellow rounded-r-xl flex items-center font-black">
                    待審核
                </div>
            </div>
        </div>

        <div class="mt-3 px-3 bg-white rounded-xl">
            <div class="p-2 flex">
                <img v-if="pro.state != 0 && (pro.file.slice(-3) == 'doc' || pro.file.slice(-4) == 'docx')" class="mr-2 h-6"
                    src="../../../../assets/group/file-doc.png" alt="">
                <img v-if="pro.state != 0 && pro.file.slice(-3) == 'pdf'" class="mr-2 h-6"
                    src="../../../../assets/group/file-pdf.png" alt="">
                <img v-if="pro.file == '' || pro.file == null || pro.state == 0" class="mr-2 h-6"
                    src="../../../../assets/group/file-nofile.png" alt="">
                <form v-if="pro.state != 0 && uploaded[index] == 0" class="font-bold">
                    {{ pro.file }}
                </form>
                <form v-else-if="uploaded[index] == 1" class="font-bold">
                    {{ getFilename[index] }}
                </form>
                <form v-else class="font-bold">
                    No File
                </form>
                <form>
                    <input class="upload w-full hidden" type="file" @change="handleFile(index, $event)">
                </form>
                <!-- <form v-if="pro.state != 0 && uploaded[index] == 1" class="font-bold">
                    <input class="upload w-full" type="file" @change="handleFile(index)">
                </form> -->
                <!-- <form v-else><input class="upload w-full hidden" type="file" @change="handleFile(index)"></form> -->
            </div>
            <hr>
            <div v-if="uploaded[index] == 0" class="p-2 flex">
                <div v-if="pro.state == 0" class="text-left font-bold grow" style="flex-grow:1;">尚未上傳</div>
                <div v-else class="text-left text-mark-green font-bold grow" style="flex-grow:1;">已上傳</div>
                <div v-if="pro.state != 0">{{ formatDate(pro.upload_time, 'YYYY-MM-DD HH:mm:ss') }}</div>
            </div>
            <div v-else class="p-2 flex">
                <div>請點選確認上傳</div>
            </div>
        </div>
        <div class="flex gap-4">
            <button @click="uploadFile(index)" type="file"
                v-if="pro.state == 0 && (progress[index - 1] ? progress[index - 1] : 0).state != 0 && uploaded[index] == 0"
                class="w-full rounded-xl mt-3 p-1 bg-basic-300 text-white" style="background-color:#FDAB7E ;">上傳檔案</button>

            <button @click="cancel(index)" type="file" v-if="uploaded[index] == 1"
                class="w-full rounded-xl mt-3 p-1 bg-basic-300 text-white ">取消</button>
            <!-- <button @click="uploadFile(index)" type="file" v-if="uploaded[index] == 1"
                class="w-full rounded-xl mt-3 p-1 bg-basic-300 text-white ">重新上傳</button> -->
            <button @click="submitFile(index)" type="file" v-if="uploaded[index] == 1"
                class="w-full rounded-xl mt-3 p-1 bg-main-second text-white">確認上傳</button>

            <button v-if="(pro.state == 1 || pro.state == 3) && uploaded[index] == 0" @click="uploadFile(index)"
                class="w-full rounded-xl mt-3 p-1 bg-basic-300 text-white">重新上傳</button>
            <button v-if="pro.state != 0 && pro.state != 3 && uploaded[index] == 0"
                class="w-full rounded-xl mt-3 p-1 bg-main-second text-white">審核結果</button>
            <button @click="location()" v-if="pro.state != 0 && pro.state != 3 && uploaded[index] == 0"
                class="w-full rounded-xl mt-3 p-1 bg-main-second text-white">檢視檔案</button>
        </div>

    </div>
</template>
<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import axios from "axios";
import moment from "moment";

export default {
    // props: {
    //     data: Object
    // }
    setup() {
        const progress = ref([])
        const groupId = JSON.parse(sessionStorage.getItem("groupId"))
        const app = getCurrentInstance().appContext.app;
        const uploaded = ref([])
        const file_data = ref({
            groupId: JSON.parse(sessionStorage.getItem("groupId")),
            file: new FormData(),
            order: 0,
        })
        const getFilename = ref([])

        let formData = new FormData();

        const cancel = (index) => {
            uploaded.value[index] = 0
        }

        const uploadFile = (index) => {
            document.getElementsByClassName("upload")[index].click()
        }

        const location = () => {
            const link = process.env.VUE_APP_api_url + '/progress/group-6/progress-2.pdf'
            window.open(link, '_blank');
        }

        const handleFile = (index, event) => {

            file_data.value.order = index
            const file = document.getElementsByClassName("upload")[index].files[0]
            if (file) {
                uploaded.value.fill(0)
                uploaded.value[index] = 1
                // hidden.value = "upload w-full"
                getFilename.value[index] = file.name
            }
            console.log(file)
            console.log(index)
            // file.originalFileName = decodeURIComponent(originalFileName)
            formData.set('Progress', file)
            formData.set('groupId', file_data.value.groupId);
            formData.set('order', file_data.value.order);

            console.log(formData.getAll('Progress'))
            event.target.value = ''
        }

        const submitFile = () => {


            axios.post(
                process.env.VUE_APP_api_url + "/studygroup/uploadprogress",
                formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: file_data.value
            }
            ).then((response) => {
                if (response.data.insert == "finished") {
                    console.log(response.data)
                    alert("新增成功")
                    location.reload()
                }
            })
            console.log(file_data.value)
            console.log(formData.getAll('Progress'))
        }

        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getprogress")
                .then((response) => {
                    const allmem = response.data;
                    progress.value = allmem.filter((pro) => pro.group_id == groupId);
                    for (let i = 0; i < progress.value.length; i++) {
                        uploaded.value[i] = 0
                        getFilename.value[i] = progress.value[i].file
                    }
                    console.log(uploaded)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        const formatDate = (value, format) => {
            return moment(value).format(format || 'YYYY-MM-DD, HH:mm:ss');
        };

        app.config.globalProperties.formatDate = formatDate;

        return {
            file_data,
            progress,
            cancel,
            uploadFile,
            handleFile,
            submitFile,
            location,
            uploaded,
            getFilename,
        };
    },
}
</script>

<style scoped>
input[type=file]::file-selector-button {
    display: none;
}

input[type=file]::file-selector-button:hover {
    display: none;
}

input[type=file]::file-selector-button:hover {
    display: none;
}
</style>