<template>
  <div class="block p-6 rounded-lg shadow-lg bg-white max-w-md w-full">
    <div class="grid grid-cols-2 gap-2 pb-2 text-left">
      <p>
        順序：<span class="font-bold">{{ index }}</span>
      </p>
      <p>
        姓名：<span class="font-bold">{{ data.stuName }}</span>
      </p>
    </div>

    <div class="grid grid-cols-1 gap-2 pb-2 text-left">
      <p>
        電子郵件：<span class="font-bold">{{ data.stuEmail }}</span>
      </p>
    </div>

    <div class="grid grid-cols-1 gap-2">
      <div>
        <label for="exampleInputEmail1" class="reserveTitle form-label inline-block text-gray-700">問題描述</label>
        <div class="form-group mb-3">
          <textarea id="message" rows="3" disabled :value="data.description"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
        </div>
      </div>
      <div v-if="data.status == 0">
        <label for="exampleInputEmail1" class="reserveTitle form-label inline-block text-gray-700">
          <span style="color: red">*</span>課守回覆</label>
        <div class="form-group mb-3">
          <textarea id="message" rows="3" v-model="tutorReply"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
        </div>
      </div>
      <div v-else>
        <label for="exampleInputEmail1" class="reserveTitle form-label inline-block text-gray-700">課守回覆</label>
        <div class="form-group mb-3">
          <textarea id="message" rows="3" :value="data.tutorReply" disabled
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-2" v-if="data.status == 0">
      <div>
        <label for="exampleInputEmail1" class="reserveTitle form-label inline-block text-gray-700">是否接受預約</label>
        <div class="flex">
          <p class="flex-1 mt-3">否</p>
          <Toggle class="m-3" style="width: 100%" v-model="check" />
          <p class="flex-1 mt-3 ">是</p>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-2 text-left mb-2">
      <p>
        申請時間：<span class="font-bold">{{ FixDateFormat(data.ApplyTime) }}</span>
      </p>
    </div>

    <button v-if="data.status == 0" type="button" id="reserveBtn" @click="saveReply(data.seq)"
      class="w-full px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
      送出
    </button>
    <button v-else type="button" id="reserveBtn" @click="popModal()"
      class="w-full px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
      關閉
    </button>
  </div>
</template>

<script>
import { popModal } from "jenesius-vue-modal";
import { ElMessage } from 'element-plus'
import { ref } from "@vue/reactivity";
import Toggle from "@vueform/toggle";
import axios from "axios";
import moment from "moment";

export default {
  components: { Toggle },
  props: {
    index: String,
    data: Object,
  },
  setup(props) {
    const check = ref(true);

    const tutorReply = ref("");

    const FixDateFormat = (date) => {
      return moment(date).format("YYYY-MM-DD hh:mm:ss");
    };

    const saveReply = (seq) => {
      if (tutorReply.value) {
        const replyData = {
          tutorReply: tutorReply.value,
          status: 1,
          seq: seq,
          stuEmail: props.data.stuEmail
        };

        if (!check.value) {
          replyData.status = 2;
        }

        axios
          .post(process.env.VUE_APP_api_url + "/guidance/replyReserve", replyData)
          .then((response) => {
            if (response.status == 200) {
              sessionStorage.setItem("reply", true)
              ElMessage({
                message: '回覆成功', type: 'success',
              })
              popModal();
            }
          });
      } else {
        alert("回覆欄位不可為空！");
      }
    };

    return {
      check, tutorReply, saveReply, FixDateFormat, popModal
    };
  },
};
</script>
