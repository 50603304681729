<template>
  <div id="loader" class="loader loader-border">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../../../assets/Spin-1s-200px.gif" />
        </div>
        <p id="loader-text" class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>

  <div id="Loader" class="loader loader-border">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img v-if="state == 0 || state == 3" id="image-src" src="../../../../assets/delete.png" />
          <img v-else-if="state == 1 || state == 4" id="image-src" src="../../../../assets/success_icon.png" />
          <img v-else-if="state == 2 || state == 5" id="image-src" src="../../../../assets/error_icon.png" />
        </div>
        <p v-if="state == 0" class="loader-text">文件通過中...</p>
        <p v-else-if="state == 1" class="loader-text">通過成功！</p>
        <p v-else-if="state == 2" class="loader-text">通過失敗！</p>
        <p v-else-if="state == 3" class="loader-text">文件退回中...</p>
        <p v-else-if="state == 4" class="loader-text">退回成功！</p>
        <p v-else-if="state == 5" class="loader-text">退回失敗！</p>
      </div>
    </div>
  </div>

  <!-- <div class="p-5 mb-0">
                                <button id="adminBack" style="background-color: rgb(163 163 163) !important" @click="goBack">
                                  返回上一頁
                                </button>
                              </div> -->

  <div class="bg-white flex">
    <div class="mt-5 border rounded-lg text-white p-5 m-5 mt-0 text-left" style="background-color: #2db3b3">
      <div class="widget widget-info flex" style="padding-bottom: 0">
        <h3 class="widget-title">個人資料 Personal Information</h3>
      </div>

      <div class="grid gap-6 p-5 lg:grid-cols-2">
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>姓名</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="verifyDetail.stuName" disabled required />
        </div>

        <div>
          <label for="stuId" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>學號</label>
          <input type="text" id="stuId" name="stuId"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="verifyDetail.stuId" disabled required />
        </div>

        <div>
          <label for="stuDept" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>系級</label>
          <input type="text" id="stuDept" name="stuDept"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="verifyDetail.stuDept" disabled required />
        </div>

        <div>
          <label for="stuMail" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>電子郵件</label>
          <input type="email" id="stuMail" name="stuMail" v-model="verifyDetail.stuMail"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>
        <div>
          <label for="stuPhone" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>聯絡電話</label>
          <input type="text" id="stuPhone" name="stuPhone" v-model="verifyDetail.stuPhone"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>

        <div>
          <label for="stuGrade" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-400"><span
              style="color: red"> * </span>學制</label>
          <select id="stuGrade" name="stuGrade" v-model="verifyDetail.stuGrade"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled>
            <option value="" selected disabled>請選擇學制</option>
            <option value="大學部">大學部</option>
            <option value="碩士班">碩士班</option>
            <option value="碩專班">碩專班</option>
            <option value="博士班">博士班</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mt-4 border rounded-lg text-black p-5 m-5 text-left" style="background-color: rgb(207, 207, 207)">
      <div class="widget widget-info" style="padding-bottom: 0">
        <h3 class="widget-title-black" style="color: #000 !important">
          證照資料 License Information
        </h3>
      </div>
      <div class="grid gap-6 p-5 lg:grid-cols-2">
        <div>
          <label for="licenseType" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照種類</label>
          <select id="licenseType" name="licenseType" v-model="verifyDetail.licenseType"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled>
            <option value="" selected disabled>選擇證照種類</option>
            <option value="政府機關">政府機關</option>
            <option value="教師證書">教師證書</option>
            <option value="國際認證">國際認證</option>
            <option value="其他">其他</option>
          </select>
        </div>
        <div>
          <label for="licenseLevel" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>申請獎勵等級</label>
          <select id="licenseLevel" name="licenseLevel" v-model="verifyDetail.licenseLevel"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled>
            <option value="" selected disabled>選擇證照等級</option>
            <option value="甲級">甲級</option>
            <option value="乙級">乙級</option>
            <option value="丙級">丙級</option>
          </select>
        </div>
        <div>
          <label for="licenseTeacher" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照指導老師</label>
          <input type="text" id="licenseTeacher" name="licenseTeacher" v-model="verifyDetail.licenseTeacher"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>

        <div>
          <label for="licenseName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照名稱(全名)</label>
          <input type="text" id="licenseName" name="licenseName" v-model="verifyDetail.licenseName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>

        <div>
          <label for="licenseFee" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照報名費</label>
          <input type="number" id="licenseFee" name="licenseFee" v-model="verifyDetail.licenseFee"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required disabled />
        </div>

        <div>
          <label for="licenseStart" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>核照/生效日期</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"></path>
              </svg>
            </div>
            <input datepicker type="date" id="licenseStart" name="licenseStart" v-model="verifyDetail.licenseStart"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="選擇日期" required disabled />
          </div>
        </div>
      </div>
      <div class="widget widget-info" style="padding-bottom: 0">
        <h3 class="widget-title-black" style="color: #000 !important">
          檢附文件 Attachment
        </h3>
      </div>
      <div class="grid gap-6 p-5 pt-0 lg:grid-cols-2">
        <div class="flex" v-for="item in applyFiles" :key="item.seq">
          <i class="pi pi-file mr-1"></i>
          <a href="#" @click.prevent="getImage(item.path, item.fileName)" target="_blank">{{
            item.fileName
          }}</a>
        </div>
      </div>

      <div v-if="verifyDetail.State != 0">
        <div class="widget widget-info" style="padding-bottom: 0">
          <h3 class="widget-title-black" style="color: #000 !important">
            審核回應 Reply
          </h3>
        </div>
        <div class="grid gap-6 p-5 pt-0">
          <textarea id="message" rows="2" v-model="verifyDetail.VerifyReply" disabled
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
        </div>
      </div>

      <div v-else>
        <div class="widget widget-info" style="padding-bottom: 0">
          <h3 class="widget-title-black" style="color: #000 !important">
            審核回應 Reply
          </h3>
        </div>
        <div class="grid gap-6 p-5 pt-0">
          <textarea id="message" rows="2" v-model="reply"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="輸入審核回覆...."></textarea>
        </div>
      </div>


      <div class="w-full text-center mt-5" v-if="verifyDetail.State == 0">
        <button type="button" @click="passOrder"
          class="focus:outline-none border-2 border-blue-700 hover:bg-blue-800 hover:text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-900"
          style="font-weight: bolder; font-size: 18px; transition: 0.3s">
          通過
        </button>
        <button type="button" @click="revokeOrder"
          class="focus:outline-none border-2 border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
          style="font-weight: bolder; font-size: 18px; transition: 0.3s">
          退回
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { closeModal } from 'jenesius-vue-modal';
import axios from "axios";
import moment from "moment"

export default {
  props: {
    id: Number
  },
  setup(props) {
    const getLocalDate = (date) => {
      if (date == undefined) return "";
      return moment(date).local().format("YYYY-MM-DD");
    };

    const verifyDetail = ref({});
    const applyFiles = ref([]);

    const state = ref(0);
    const reply = ref("");

    onMounted(() => {
      axios
        .post(
          process.env.VUE_APP_api_url + "/awardapply/pro/selectfiles/" +
          props.id
        )
        .then((d) => {
          d.data.forEach((e) => {
            e.fileName = e.path.substring(e.path.lastIndexOf("/") + 1);
            applyFiles.value.push(e);
          });
        });

      axios
        .post(
          process.env.VUE_APP_api_url + "/awardapply/pro/verify/" +
          props.id
        )
        .then((data) => {
          data.data[0].licenseStart = getLocalDate(data.data[0].licenseStart)
          Object.assign(verifyDetail.value, data.data[0]);
        });
    });

    const GoListPage = () => {
      closeModal();
      location.reload();
    };

    const passOrder = () => {
      var yes = confirm("你確定要通過嗎？");
      const loader = document.getElementById("Loader");

      if (yes) {
        loader.classList.add("is-active");
        axios
          .post(
            process.env.VUE_APP_api_url + "/awardapply/pro/changeState/",
            {
              oId: props.id,
              reply: reply.value,
              state: 1,
            }
          )
          .then(() => {
            state.value = 1;
          })
          .catch(() => {
            state.value = 2;
          })
          .finally(() => {
            setTimeout(() => {
              loader.classList.remove("is-active");
            }, "3000");

            setTimeout(() => {
              GoListPage();
            }, "1500");
          });
      } else {
        state.value = 2;
        loader.classList.add("is-active");
        setTimeout(() => {
          loader.classList.remove("is-active");
        }, "2000");

        setTimeout(() => {
          GoListPage();
        }, "1500");
      }
    };

    const revokeOrder = () => {
      var yes = confirm("你確定要撤回嗎？");
      const loader = document.getElementById("Loader");

      if (yes) {
        loader.classList.add("is-active");
        state.value = 3;
        axios
          .post(
            process.env.VUE_APP_api_url + "/awardapply/pro/changeState/",
            {
              oId: props.id,
              reply: reply.value,
              state: 2,
            }
          )
          .then(() => {
            state.value = 4;
          })
          .catch(() => {
            state.value = 5;
          })
          .finally(() => {
            setTimeout(() => {
              loader.classList.remove("is-active");
            }, "3000");

            setTimeout(() => {
              GoListPage();
            }, "1500");
          });
      } else {
        state.value = 5;
        loader.classList.add("is-active");
        setTimeout(() => {
          loader.classList.remove("is-active");
        }, "2000");

        setTimeout(() => {
          GoListPage();
        }, "1500");
      }
    };

    const goBack = () => {
      location.href = "/sladmin/awardlist/prolicense";
    };

    const getImage = async (image_url, filename) => {
      try {
        const response = await axios.get(image_url, {
          responseType: "blob"
        });
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const objectURL = URL.createObjectURL(blob);
        window.open(objectURL, filename);
      } catch (error) {
        alert("檔案發生問題！")
      }
    }

    return { verifyDetail, applyFiles, passOrder, revokeOrder, state, goBack, reply, getImage };
  },
};
</script>
