<template>
  <div id="nextLoader" class="loader loader-border">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image" style="width: 50px; height: 50px">
          <img id="image-src" src="../../../../assets/error_icon.png" />
        </div>
        <p class="loader-text" style="font-size: 14px; margin-top: 15px">
          欄位請填寫完整<br />並附上檔案
        </p>
      </div>
    </div>
  </div>
  <div style="margin: 0 auto; width: 100%" class="bg-gray-200 pb-5">
    <div style="
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 23px;
        font-weight: bolder;
        color: #eb3434;
        max-width: 300px;
        margin: 0px auto;
      ">
    </div>
    <div class="border rounded-lg text-white p-5 m-5 text-left" style="background-color: #2db3b3">
      <div class="widget widget-info" style="padding-bottom: 0">
        <h3 class="widget-title">個人資料 Personal Information</h3>
      </div>

      <div class="grid gap-6 p-5 lg:grid-cols-2">
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>姓名</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="stuInfo.stuName" disabled required />
        </div>

        <div>
          <label for="stuId" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>學號</label>
          <input type="text" id="stuId" name="stuId"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="stuInfo.stuId" disabled required />
        </div>

        <div>
          <label for="stuDept" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>系級</label>
          <input type="text" id="stuDept" name="stuDept"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="stuInfo.stuDept" disabled required />
        </div>

        <div>
          <label for="stuMail" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>電子郵件</label>
          <input type="email" id="stuMail" name="stuMail" v-model="stuInfo.stuMail"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required />
        </div>
        <div>
          <label for="stuPhone" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>聯絡電話</label>
          <input type="text" id="stuPhone" name="stuPhone" v-model="stuInfo.stuPhone"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required />
        </div>

        <div>
          <label for="stuGrade" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-400"><span
              style="color: red"> * </span>學制</label>
          <select id="stuGrade" name="stuGrade" v-model="stuInfo.stuGrade"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required>
            <option value="" selected disabled>請選擇學制</option>
            <option value="大學部">大學部</option>
            <option value="碩士班">碩士班</option>
            <option value="碩專班">碩專班</option>
            <option value="博士班">博士班</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mt-4 border rounded-lg text-black p-5 m-5 text-left" style="background-color: rgb(207, 207, 207)">
      <div class="widget widget-info" style="padding-bottom: 0">
        <h3 class="widget-title-black" style="color: #000 !important">
          證照資料 License Information
        </h3>
      </div>
      <div class="grid gap-6 p-5 lg:grid-cols-2">
        <div>
          <label for="licenseType" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照種類</label>
          <select id="licenseType" name="licenseType" v-model="stuInfo.licenseType"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required>
            <option value="" selected disabled>選擇證照種類</option>
            <option value="政府機關">政府機關</option>
            <option value="教師證書">教師證書</option>
            <option value="國際認證">國際認證</option>
            <option value="其他">其他</option>
          </select>
        </div>
        <div>
          <label for="licenseLevel" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>申請獎勵等級</label>
          <select id="licenseLevel" name="licenseLevel" v-model="stuInfo.licenseLevel"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required>
            <option value="" selected disabled>選擇證照等級</option>
            <option value="甲級">甲級</option>
            <option value="乙級">乙級</option>
            <option value="丙級">丙級</option>
          </select>
        </div>
        <div>
          <label for="licenseTeacher" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照指導老師</label>
          <input type="text" id="licenseTeacher" name="licenseTeacher" v-model="stuInfo.licenseTeacher"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required />
        </div>

        <div>
          <label for="licenseName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照名稱(全名)</label>
          <input type="text" id="licenseName" name="licenseName" v-model="stuInfo.licenseName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required />
        </div>

        <div>
          <label for="licenseFee" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>證照報名費</label>
          <input type="number" id="licenseFee" name="licenseFee" v-model="stuInfo.licenseFee"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required />
        </div>

        <div>
          <label for="licenseStart" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>核照/生效日期</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                  clip-rule="evenodd"></path>
              </svg>
            </div>
            <input datepicker type="date" id="licenseStart" name="licenseStart" v-model="stuInfo.licenseStart"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="選擇日期" required />
          </div>
        </div>
      </div>
      <div class="grid gap-6 p-5 pt-0 lg:grid-cols-1">
        <div>
          <label for="FileUpload" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>檔案上傳</label>
          <file-pond class="filepond-list" name="FileUpload" ref="pond"
            label-idle="將檔案拉入此區塊 或 <span class='filepond--label-action'>瀏覽</span><br>(請勿超過20MB)" allow-multiple="true"
            accepted-file-types="image/jpeg, image/png, image/jpg" v-bind:files="myfiles"
            v-on:updatefiles="handleFilePondUpdateFile" />
        </div>
      </div>
    </div>
  </div>
  <div class="flex">
    <button class="flex-auto back-step" style="padding: 10px; font-size: 20px" @click="changePageIndex(1)">
      <span>
        <i class="pi pi-arrow-circle-left"></i>
        <span class="addAward ml-2">上一步</span></span>
    </button>

    <button class="flex-auto next-step" style="padding: 10px; font-size: 20px" @click="renderModel">
      <span>
        <span class="addAward mr-2">下一步</span>
        <i class="pi pi-chevron-circle-right"></i>
      </span>
    </button>
  </div>
  <widget-container-modal />
</template>

<script>
import { openModal, container } from "jenesius-vue-modal";
import WindowModal from "./SubmitWarning.vue";
import vueFilePond from "vue-filepond";

import "filepond/dist/filepond.min.css";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

import axios from "axios";
import { onMounted, ref } from "@vue/runtime-core";

export default {
  components: {
    WidgetContainerModal: container,
    FilePond,
  },
  props: {
    changePageIndex: Function,
  },
  setup(props) {
    const myfiles = ref([]);
    const stuInfo = ref({
      stuId: null,
      stuName: null,
      stuDept: null,
      stuMail: null,
      stuPhone: null,
      stuGrade: "",
      licenseType: "",
      licenseTeacher: null,
      licenseName: null,
      licenseFee: null,
      licenseStart: null,
      licenseLevel: "",
    });

    const stu_record = ref({})
    onMounted(() => {
      stu_record.value = JSON.parse(sessionStorage.getItem("stuInfo"))
      var statusList = JSON.parse(sessionStorage.getItem("AwardApplyStatus"))
      statusList.forEach((x) => {
        if (x.eng_name == "prolicense") {
          if (x.status == false && !process.env.VUE_APP_DEVELOP_MODE && stu_record.value.userId != "10844235") {
            alert("非申請期間！")
            location.href = "/awardapply/"
          } else {
            stuInfo.value.stuName = stu_record.value.userName
            stuInfo.value.stuId = stu_record.value.userId
            stuInfo.value.stuDept = stu_record.value.userDept
          }
        }
      })
    });

    const fileUpload = async (id) => {
      let formData = new FormData();
      for (var i = 0; i < myfiles.value.length; i++) {
        let file = myfiles.value[i];
        formData.append("file", file);
      }

      let uploadstatus = ref(false)

      await axios.post(
        process.env.VUE_APP_api_url + "/awardapply/pro/fileupload?stuId=" +
        document.getElementById("stuId").value +
        "&orderId=" +
        id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      ).then(() => {
        uploadstatus.value = true
      }).catch(() => {
        uploadstatus.value = false
      })

      return uploadstatus.value
    };


    const renderModel = async function () {
      const loader = document.getElementById("nextLoader");

      const ApplyData = {
        stuId: stuInfo.value.stuId,
        stuName: stuInfo.value.stuName,
        stuDept: stuInfo.value.stuDept,
        stuMail: stuInfo.value.stuMail,
        stuPhone: stuInfo.value.stuPhone,
        stuGrade: stuInfo.value.stuGrade,
        licenseType: stuInfo.value.licenseType,
        licenseTeacher: stuInfo.value.licenseTeacher,
        licenseName: stuInfo.value.licenseName,
        licenseFee: stuInfo.value.licenseFee,
        licenseStart: stuInfo.value.licenseStart,
        licenseLevel: stuInfo.value.licenseLevel,
      };

      var canSubmit = true;
      if (ApplyData.stuId == null || ApplyData.stuId.length == 0) {
        canSubmit = false;
      }
      if (ApplyData.stuName == null || ApplyData.stuName.length == 0) {
        canSubmit = false;
      }
      if (ApplyData.stuDept == null || ApplyData.stuDept.length == 0) {
        canSubmit = false;
      }
      if (ApplyData.stuMail == null || ApplyData.stuMail.length == 0) {
        canSubmit = false;
      }
      if (ApplyData.stuPhone == null || ApplyData.stuPhone.length == 0) {
        canSubmit = false;
      }
      if (ApplyData.stuGrade == null || ApplyData.stuGrade.length == 0) {
        canSubmit = false;
      }
      if (ApplyData.licenseType == null || ApplyData.licenseType.length == 0) {
        canSubmit = false;
      }
      if (
        ApplyData.licenseTeacher == null ||
        ApplyData.licenseTeacher.length == 0
      ) {
        canSubmit = false;
      }
      if (ApplyData.licenseName == null || ApplyData.licenseName.length == 0) {
        canSubmit = false;
      }
      if (ApplyData.licenseFee == null || ApplyData.licenseFee.length == 0) {
        canSubmit = false;
      }
      if (
        ApplyData.licenseStart == null ||
        ApplyData.licenseStart.length == 0
      ) {
        canSubmit = false;
      }
      if (
        ApplyData.licenseLevel == null ||
        ApplyData.licenseLevel.length == 0
      ) {
        canSubmit = false;
      }

      if (myfiles.value == undefined || myfiles.value.length == 0) {
        canSubmit = false;
      }

      if (canSubmit) {
        openModal(WindowModal, {
          ApplyData: ApplyData,
          fileUpload: fileUpload,
          changePageIndex: props.changePageIndex,
        });
      } else {
        loader.classList.add("is-active");
        setTimeout(() => {
          loader.classList.remove("is-active");
        }, "3000");
      }
    }

    const handleFilePondUpdateFile = (files) => {
      myfiles.value = files.map((files) => files.file);
    }

    return {
      stuInfo,
      myfiles,
      fileUpload,
      renderModel,
      handleFilePondUpdateFile
    };
  },
};
</script>
