<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="250px" class="border-r-2">
        <div class="mt-5 px-4 py-6 rounded-lg">
          <i class="pi pi-file mb-4" style="font-size: 3rem"></i>
          <h2 class="title-font font-medium text-2xl text-gray-900">設定</h2>
          <p class="leading-relaxed">Setting</p>
        </div>
        <ul>
          <li class="w-full">
            <a
              class="adminLink text-lg inline-block p-4 w-full hover:text-gray-70 rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              >系統錯誤訊息</a
            >
          </li>
          <li class="w-full">
            <a
              @click="goBack()"
              class="adminLink text-lg inline-block p-4 w-full bg-gray-100 hover:text-gray-70 rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              >返回上一頁</a
            >
          </li>
        </ul>
      </el-aside>
      <el-main>
        <div
          class="border rounded-lg text-white p-5 m-5 text-left"
          style="background-color: #2db3b3"
        >
          <div class="widget widget-info" style="padding-bottom: 0">
            <h3 class="widget-title">網站管理員 Website Manager</h3>
          </div>

          <table
            class="bg-gray-50 w-full text-black rounded-lg text-lg"
            style="table-layout: fixed"
          >
            <tr class="text-lg font-bold border-b-2" style="font-size: 22px">
              <th class="py-3 px-6">編號</th>
              <th class="py-3 px-6">使用者名稱</th>
              <th class="py-3 px-6">人事代碼</th>
              <th class="py-3 px-6">身份</th>
              <th class="py-3 px-6">操作</th>
            </tr>
            <tr v-for="(admin, index) in admins" :key="index" :row="admin.seq">
              <td class="py-3 px-6">{{ index + 1 }}</td>
              <td class="py-3 px-6">
                <input
                  :disabled="admin.update"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                  :id="`username-${admin.userId}`"
                  v-model="admin.userName"
                />
              </td>
              <td class="py-3 px-6">
                <input
                  :disabled="admin.update"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  :id="`userid-${admin.seq}`"
                  v-model="admin.userId"
                />
              </td>
              <td class="py-3 px-6">
                <select
                  v-model="admin.type"
                  :disabled="admin.update"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  name="usertype"
                  :id="`usertype-${admin.seq}`"
                >
                  <option value="網站管理員">網站管理員</option>
                </select>
              </td>
              <td class="py-3 px-6">
                <button
                  type="button"
                  :id="`update-${admin.seq}`"
                  @click="updateManager(admin)"
                  class="text-white p-highlight focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2"
                  style="width: 45%"
                >
                  編輯
                </button>
                <button
                  type="button"
                  @click="deleteManager(admin.seq)"
                  class="text-white bg-red-700 hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                  style="width: 45%"
                >
                  移除
                </button>
              </td>
            </tr>
            <tr id="addNewManager" style="visibility: collapse">
              <td class="py-3 px-6">{{ admins.length + 1 }}</td>
              <td class="py-3 px-6">
                <input
                  type="text"
                  v-model="newManager.userName"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="請輸入中文姓名"
                  required
                />
              </td>
              <td class="py-3 px-6">
                <input
                  type="text"
                  id="userId"
                  v-model="newManager.userId"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="請輸入人事代碼"
                  required
                />
              </td>
              <td class="py-3 px-6">
                <select
                  id="userType"
                  v-model="newManager.userType"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="網站管理員">網站管理員</option>
                </select>
              </td>
              <td class="py-3 px-6">
                <button
                  type="button"
                  style="width: 45%"
                  @click="addNewManager()"
                  class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                >
                  新增
                </button>
                <button
                  type="button"
                  style="width: 45%"
                  @click="cancelAddNewArea()"
                  class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  取消
                </button>
              </td>
            </tr>
            <tr>
              <td colspan="5" class="text-center py-2">
                <button class="py-0.5 px-2" @click="showAddNewArea()" aria-label="新增管理員">
                  <i class="pi pi-plus" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from "axios";
import { onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { ElNotification } from "element-plus";
import { ElMessageBox } from "element-plus";

export default {
  setup() {
    const admins = ref([]);
    onMounted(() => {
      axios.post(process.env.VUE_APP_api_url + "/admin/getadmin").then((e) => {
        e.data.forEach((admin) => {
          admin.update = true;
          admins.value.push(admin);
        });
      });
    });

    const showAddNewArea = () => {
      var show = document.getElementById("addNewManager").style.visibility;
      if (show == "collapse") {
        document.getElementById("addNewManager").style.visibility = "visible";
      } else {
        document.getElementById("addNewManager").style.visibility = "collapse";
      }
    };

    const cancelAddNewArea = () => {
      document.getElementById("addNewManager").style.visibility = "collapse";
    };

    const newManager = ref({
      seq: admins.length + 1,
      userName: "",
      userId: "",
      userType: "網站管理員",
    });

    const addNewManager = () => {
      axios
        .post(process.env.VUE_APP_api_url + "/admin/add", newManager.value)
        .then((response) => {
          if (response.data.status) {
            ElNotification({
              title: "成功",
              message: "新增成功。",
              type: "success",
            });
            location.reload();
          } else {
            ElNotification({
              title: "錯誤",
              message: "新增失敗",
              type: "error",
            });
            location.reload();
          }
        });
    };

    const deleteManager = (adminid) => {
      ElMessageBox.confirm("確定要刪除嗎？", "刪除", {
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        axios
          .post(process.env.VUE_APP_api_url + "/admin/delete", {
            adminId: adminid,
          })
          .then((response) => {
            if (response.data.status) {
              ElNotification({
                title: "成功",
                message: "刪除成功。",
                type: "success",
              });
              location.reload();
            } else {
              ElNotification({
                title: "錯誤",
                message: "刪除失敗",
                type: "error",
              });
              location.reload();
            }
          });
      });
    };

    const update = ref(true);
    const updateManager = (data) => {
      console.log(data);
      var update_class =
        "text-white p-highlight focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2";
      var success_class =
        "text-white bg-gray-500 hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800";
      if (
        document.getElementById(`update-${data.seq}`).innerHTML.trim() == "編輯"
      ) {
        document.getElementById(`update-${data.seq}`).innerHTML = "完成";
        document.getElementById(`update-${data.seq}`).classList = success_class
          .split(" ")
          .toString()
          .replaceAll(",", " ");
        data.update = false;
      } else {
        axios
          .post(process.env.VUE_APP_api_url + "/admin/update", data)
          .then((response) => {
            if (response.data.status) {
              document.getElementById(`update-${data.seq}`).innerHTML = "編輯";
              document.getElementById(`update-${data.seq}`).classList =
                update_class.split(" ").toString().replaceAll(",", " ");
              data.update = true;
              ElNotification({
                title: "成功",
                message: "編輯成功。",
                type: "success",
              });
              location.reload();
            } else {
              ElNotification({
                title: "錯誤",
                message: "編輯失敗",
                type: "error",
              });
              location.reload();
            }
          });
      }
    };

    const router = useRouter();
    const goBack = () => {
      router.push({
        path: "/sladmin/",
      });
    };

    return {
      admins,
      newManager,
      update,
      showAddNewArea,
      cancelAddNewArea,
      addNewManager,
      goBack,
      deleteManager,
      updateManager,
    };
  },
};
</script>