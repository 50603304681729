<template>
  <div id="extralarge-modal" tabindex="-1"
    class="overflow-y-auto overflow-x-auto fixed mt-2 mb-2 top-50 right-0 left-50 z-50 w-full md:inset-0 h-modal md:h-full">
    <div class="relative p-4 w-full h-full md:h-auto">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex justify-between items-center p-5 rounded-t border-b dark:border-gray-600">
          <h1 v-if="type == 'General'" class="title mt-5 mb-5">
            通識自主學習歷程
          </h1>
          <h1 v-else-if="type == 'English'" class="title mt-5 mb-5">
            外語自主學習歷程
          </h1>
          <h1 v-else-if="type == 'Pro'" class="title mt-5 mb-5">
            專業自主學習歷程
          </h1>
          <button type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="extralarge-modal">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
              @click="closeWindow()">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
        <div class="p-6 space-y-6">
          <table class="program-table" style="margin-bottom: 20px">
            <tr class="header-table">
              <th style="width: 10%">編號</th>
              <th style="width: 10%">申請學年</th>
              <th style="width: 50%">名稱</th>
              <th style="width: 20%">日期</th>
              <th style="width: 10%" v-if="type == 'General'">學分</th>
              <th style="width: 10%" v-else-if="type == 'English'">次數</th>
              <th style="width: 10%" v-else-if="type == 'Pro'">時數(小時)</th>
            </tr>

            <td v-if="credits.length == 0" colspan="4">無資料</td>

            <tr v-for="(item, index) in credits" :key="item.act_sn">
              <td style="height: 35px">{{ index + 1 }}</td>
              <td style="height: 35px">{{ item.year_term }}</td>
              <td style="height: 35px;width:100px;">{{ item.act_name }}</td>
              <td style="height: 35px">{{ getLocalDate(item.act_start) }}</td>
              <td style="height: 35px">{{ item.certi_points }}</td>
            </tr>
          </table>
        </div>
        <div class="footer flex items-center p-6 pb-9 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600"
          style="height: 25px">
          <button data-modal-toggle="extralarge-modal" type="button"
            class="back-btn text-white hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-right dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="closeWindow()">
            返回
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { closeModal } from "jenesius-vue-modal";

export default {
  props: {
    type: String,
    credits: Array,
  },
  setup() { },
  methods: {
    getLocalDate: function (date) {
      if (date == undefined) return "";
      return moment(date).local().format("YYYY年MM月DD日");
    },
    closeWindow: function () {
      closeModal();
    },
  },
};
</script>

<style>
#learning-info-modal {
  width: 90%;
  border-radius: 30px;
}

.back-btn {
  margin-top: 10px;
  margin-right: 70px;
  background: gray;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 50px;
  color: #fff;
}

.footer {
  background: #2db3b3;
  padding-bottom: 10px;
}
</style>
