<template>
    <div class="flex text-center">
        <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
        <div class="text-2xl font-black">
            自評表
        </div>
    </div>
    <div class="mt-6">
        <ul class="ml-6 text-left list-disc">
            <li v-for="notice in notices" :key="notice.order" v-html="notice.content"></li>
        </ul>
        <div v-for="(question, index) in questions" :key="index" class="my-4 p-3 bg-basic-100 rounded-xl shadow-md">
            <div class="m-auto flex">
                <img class="mr-2 h-7" :src="require(`../../../../assets/group/${question.icon}`)" alt="">

                <div class="bg-white rounded-xl flex grow" style="flex-grow:1;">
                    <div class="mr-auto p-1 px-2 flex text-basic">
                        {{ question.question }}
                    </div>
                    <div v-if="reply[index] ? reply[index].length <= 50 : 0"
                        class="p-1 px-2 bg-red-300 rounded-r-xl flex items-center font-black text-sm ">
                        {{ reply[index] ? reply[index].length : 0 }}/50字
                    </div>
                    <div v-if="reply[index] ? reply[index].length > 50 : 1"
                        class="p-1 px-2 rounded-r-xl flex items-center font-black text-sm ">
                        {{ reply[index] ? reply[index].length : 0 }}/50字
                    </div>
                </div>
            </div>
            <div>
                <textarea v-model="reply[index]" name="" id="" class="w-full rounded-lg mt-3"></textarea>
            </div>
        </div>

        <div class="flex gap-4">
            <button class="w-full rounded-xl mt-3 p-1 bg-mark-orange text-white">暫存</button>
            <button @click="submitEval()" class="w-full rounded-xl mt-3 p-1 bg-main-second text-white">送出</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
    setup() {
        const questions = ref([]);
        const notices = ref([])
        const reply = ref([]);

        const eval_data = ref({
            reply,
            groupId: JSON.parse(sessionStorage.getItem("groupId")),
        })

        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/showselfeval")
                .then((response) => {
                    questions.value = response.data;
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
                .then((response) => {
                    const allNotices = response.data;
                    notices.value = allNotices.filter((notice) => notice.type === "自評表");
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        const submitEval = () => {
            axios.post(process.env.VUE_APP_api_url + "/studygroup/updateselfeval", eval_data.value).then((response) => {
                if (response.data.insert == "finished") {
                    alert("新增成功")
                    location.reload()
                }
            })

        }

        return {
            questions,
            notices,
            reply,
            eval_data,
            submitEval,
        };
    },
};
</script>
