<template>
    <center class="bg-gray-200 p-3">
        <div class="flex">
            <label class="block mt-2 text-sm font-medium text-gray-900 dark:text-gray-400 text-lg w-64">
                系所
            </label>
            <select v-model="selectOption" @change="updateList()"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option v-for="(option, index) in DepartmentList" :value="option" :key="index">
                    {{ option }}
                </option>
            </select>
        </div>
    </center>
    <table class="reserveTable" style="margin: 0px auto 20px">
        <tr>
            <th width="15%" class="pl-8 pr-8" rowspan="2">時間\星期</th>
            <th class="pl-8 pr-8">星期一</th>
            <th class="pl-8 pr-8">星期二</th>
            <th class="pl-8 pr-8">星期三</th>
            <th class="pl-8 pr-8">星期四</th>
            <th class="pl-8 pr-8">星期五</th>
        </tr>

        <tr>
            <th v-for="(item, index) in thisWeek" :key="index">
                {{ item }}
            </th>
        </tr>

        <tr v-for="(data, indexRow) in DataTable.course" :key="indexRow">
            <td>{{ DataTable.Time[indexRow] }}</td>
            <td v-for="(ClassArr, indexCol) in data" :key="indexCol">
                <p v-for="(classInfo, indexClass) in ClassArr" :key="indexClass">
                    {{ classInfo.className }}-{{ classInfo.department }}({{
                        classInfo.classTeacher
                    }})
                    <br />
                    <a v-if="classInfo.isValid" class="text-blue-600 underline decoration-solid cursor-pointer" @click="
                        renderModel(
                            classInfo.seq,
                            classInfo.thisPlace,
                            thisWeekAndTime.Week[indexCol],
                            thisWeekAndTime.Time[indexRow]
                        )
                    ">
                        尚可預約
                    </a>
                    <a v-else class="text-red-600 underline decoration-solid cursor-not-allowed">
                        無法預約
                    </a>
                </p>
            </td>
        </tr>
    </table>
    <widget-container-modal />
</template>

<script>
import { openModal, container } from "jenesius-vue-modal";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import moment from "moment";
import axios from "axios";
import ReserveWindow from "./ReserveWindow.vue";
import _ from "lodash";

export default {
    components: {
        WidgetContainerModal: container,
    },
    setup() {
        const DataTable = ref({
            course: [
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
                [[], [], [], [], []],
            ],
            Time: [
                "08:10~09:00",
                "09:10~10:00",
                "10:10~11:00",
                "11:10~12:00",
                "12:10~13:00",
                "13:10~14:00",
                "14:10~15:00",
                "15:10~16:00",
                "16:10~17:00",
                "17:05~17:55",
                "18:00~18:50",
                "18:55~19:45",
                "19:50~20:40",
                "20:45~21:35",
            ],
        });

        const thisWeek = ref([]);
        const thisWeekAndTime = ref([]);

        const getCurrentWeek = () => {
            const now = moment();
            const todayWeek = moment(now).day();

            var currentWeek = [];
            if (todayWeek >= 5) {
                for (let i = 1; i < 6; i++) {
                    currentWeek.push(moment().add(1, "weeks").weekday(i).format("YYYY-MM-DD"));
                }
            } else {
                for (let i = 1; i < 6; i++) {
                    currentWeek.push(moment().weekday(i).format("YYYY-MM-DD"));
                }
            }

            return currentWeek;
        };

        thisWeek.value = getCurrentWeek();

        const getCurrentWeekAndTime = () => {
            const now = moment();
            const todayWeek = moment(now).day();

            var currentWeekAndTime = {
                Week: [],
                Time: DataTable.value.Time,
            };

            if (todayWeek >= 5) {
                for (let i = 1; i < 6; i++) {
                    currentWeekAndTime.Week.push(
                        moment().add(1, "weeks").weekday(i).format("YYYY-MM-DD")
                    );
                }
            } else {
                for (let i = 1; i < 6; i++) {
                    currentWeekAndTime.Week.push(moment().weekday(i).format("YYYY-MM-DD"));
                }
            }

            return currentWeekAndTime;
        };

        thisWeekAndTime.value = getCurrentWeekAndTime();

        const TimeArray = [
            "0810",
            "0910",
            "1010",
            "1110",
            "1210",
            "1310",
            "1410",
            "1510",
            "1610",
            "1705",
            "1800",
            "1855",
            "1950",
            "2045",
        ];
        const WeekArray = ["(一)", "(二)", "(三)", "(四)", "(五)"];

        const selectOption = ref("");
        const stuInfo = ref({});
        const AllClassInfo = ref([]);
        const DepartmentList = ref([]);

        onMounted(() => {
            stuInfo.value = JSON.parse(sessionStorage.getItem("stuInfo"));
            selectOption.value =
                _.dropRight(stuInfo.value.userDept.split(/一|二|三|四|系/)) + "系";

            axios
                .post(process.env.VUE_APP_api_url + "/guidance/getClassesInfo")
                .then((response) => {
                    const classInfo = splitConsultTime(response.data);
                    classInfo.forEach((e) => {
                        insertToTableRow(e);
                    });
                })
                .finally(() => {
                    sessionStorage.setItem("DataTable", JSON.stringify(DataTable.value));
                    updateList();
                });
        });

        const insertToTableRow = (data) => {
            try {
                if (
                    !DepartmentList.value.includes(
                        (data.department)
                    )
                ) {
                    DepartmentList.value.push(
                        data.department
                    );
                }
                data.isValid = true;
                DataTable.value.course[data.consultTime1.Time][data.consultTime1.Week].push(data);
                if (data.consultTime2 != null) {
                    DataTable.value.course[data.consultTime2.Time][data.consultTime2.Week].push(data);
                }
            } catch (error) {
                if (process.env.VUE_APP_DEVELOP_MODE) {
                    console.log(data, error)
                }
            }
        };

        const splitConsultTime = (response) => {
            response.forEach((classInfo) => {
                classInfo.consultTime1 = {
                    Week: WeekArray.indexOf(classInfo.consultTime1.substring(0, 3)),
                    Time: TimeArray.indexOf(
                        classInfo.consultTime1.replace(/\(.*?\)/g, "").substring(0, 4)
                    )
                };

                if (classInfo.consultTime2 != null) {
                    classInfo.consultTime2 = {
                        Week: WeekArray.indexOf(classInfo.consultTime2.substring(0, 3)),
                        Time: TimeArray.indexOf(
                            classInfo.consultTime2.replace(/\(.*?\)/g, "").substring(0, 4)
                        )
                    };
                }
            });
            return response;
        };

        const updateList = () => {
            if (selectOption.value != "") {
                let tempdata = JSON.parse(sessionStorage.getItem("DataTable")).course;
                tempdata.forEach((rowData, indexRow) => {
                    rowData.forEach((colData, indexCol) => {
                        colData.forEach((classData) => {
                            if (classData.consultTime2 != null) {
                                if (classData.consultTime2.Week == indexCol && classData.consultTime2.Time == indexRow) {
                                    classData.thisPlace = classData.place2;
                                } else {
                                    classData.thisPlace = classData.place;
                                }
                            } else {
                                classData.thisPlace = classData.place;
                            }
                        })
                        tempdata[indexRow][indexCol] = tempdata[indexRow][indexCol].filter(
                            (d) =>
                                _.dropRight(d.department.split(/一|二|三|四|系/))[0] ==
                                selectOption.value.replace("系", " ").trim()
                        );
                    });
                });

                const nowWeek = moment(moment()).day() - 1;
                if (nowWeek < 4) {
                    for (let k = 0; k < tempdata.length; k++) {
                        for (let i = 0; i < nowWeek + 1; i++) {
                            for (let j = 0; j < tempdata[k][i].length; j++) {
                                tempdata[k][i][j].isValid = false
                            }
                        }
                    }
                }
                DataTable.value.course = tempdata;
            }
        };

        return {
            thisWeek,
            AllClassInfo,
            thisWeekAndTime,
            selectOption,
            DataTable,
            DepartmentList,
            updateList,
        };
    },
    methods: {
        renderModel: function (seq, place, week, time) {
            let thisWeekDay = week + " " + time;
            openModal(ReserveWindow, { seq: seq, place: place, thisWeekDay: thisWeekDay });
        },
    },
};
</script>
