<template>
  <div class="block p-6 rounded-lg shadow-lg bg-white max-w-md">
    <div class="grid grid-cols-2 gap-2">
      <div>
        <label
          for="exampleInputEmail1"
          class="reserveTitle form-label ml-0.5 inline-block text-gray-700"
          >開課系級</label
        >
        <div class="form-group mb-3">
          <input
            type="text"
            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            :value="classInfo.department"
            disabled
          />
        </div>
      </div>
      <div>
        <label
          for="exampleInputEmail1"
          class="reserveTitle form-label ml-0.5 inline-block text-gray-700"
          >課程名稱</label
        >
        <div class="form-group mb-3">
          <input
            type="text"
            class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            :value="classInfo.className"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-2">
      <div>
        <label
          for="exampleInputEmail1"
          class="reserveTitle form-label ml-0.5 inline-block text-gray-700"
          >開放預約</label
        >
        <Toggle class="mt-1 mb-3" style="width: 100%" v-model="check" />
      </div>
    </div>
    <button
      type="button"
      id="reserveBtn"
      @click="updateReserveState(classInfo.seq)"
      class="w-full px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
    >
      存檔
    </button>
  </div>
</template>

<script>
import { closeModal } from "jenesius-vue-modal";
import { onMounted } from "@vue/runtime-core";
import { ref } from "@vue/reactivity";
import Toggle from "@vueform/toggle";
import axios from "axios";

export default {
  components: { Toggle },
  props: {
    classInfo: Object,
  },
  setup(props) {
    const check = ref(true);

    onMounted(() => {
      if (props.classInfo.status != 0) {
        check.value = false;
      }
    });

    const updateReserveState = (seq) => {
      let status = 0;
      if (!check.value) {
        status = 1;
      }

      axios
        .post(process.env.VUE_APP_api_url + "/guidance/updateState", {
          status: status,
          seq: seq,
        })
        .then((response) => {
          if (response.status == 200) {
            alert("更新完成！");
          }
        })
        .finally(() => {
          closeModal();
          location.reload();
        });
    };

    return { check, updateReserveState };
  },
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style>
.toggle {
  width: 100%;
  height: 20px;
}
</style>
