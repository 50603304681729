<template>
  <DataTable
    class="p-5"
    :value="classList"
    :paginator="true"
    :rows="10"
    :filters="filters"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 20, 50]"
    responsiveLayout="scroll"
    currentPageReportTemplate=""
  >
    <template #header>
      <div class="grid grid-nogutter">
        <div class="col-6" style="text-align: left">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search"></i>
            <InputText
              v-model="searchKey"
              @input="searchClass"
              class="w-full"
              placeholder="搜尋課程(不限欄位)..."
            />
          </span>
        </div>
      </div>
    </template>
    <Column field="department" header="開課單位" sortable></Column>
    <Column field="className" header="課程名稱"></Column>
    <Column field="classTeacher" header="授課教師"></Column>
    <Column field="tutorName" header="課業守護天使"></Column>
    <Column field="status" header="目前預約狀態">
      <template #body="{ data }">
        <span
          v-if="data.status == 0"
          class="bg-green-500 px-3 py-1.5 rounded-full text-white font-bold"
          >尚可預約</span
        >
        <span
          v-else-if="data.status == 1"
          class="bg-red-500 px-3 py-1.5 rounded-full text-white font-bold"
          >無法預約</span
        >
      </template>
    </Column>
    <Column
      headerStyle="width: 4rem; text-align: center"
      bodyStyle="text-align: center; overflow: visible"
    >
      <template #body="{ data }">
        <button
          @click="renderModel(data)"
          type="button"
          class="button text-white font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center"
        >
          <svg
            class="h-6 w-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
            <path
              fill-rule="evenodd"
              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </template>
    </Column>
    <template #paginatorstart></template>
    <template #paginatorend></template>
  </DataTable>
  <widget-container-modal />
</template>

<script>
import { container, openModal } from "jenesius-vue-modal";
import { ref, onMounted } from "@vue/runtime-core";
import Detail from "./Detail.vue";
import axios from "axios";
import _ from "lodash";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";

export default {
  components: {
    WidgetContainerModal: container,
    DataTable,
    Column,
    InputText,
  },
  setup() {
    const classList = ref([]);
    const searchKey = ref("");

    onMounted(() => {
      axios
        .post(process.env.VUE_APP_api_url + "/guidance/getClassesInfo")
        .then((response) => {
          response.data.forEach((e) => {
            classList.value.push(e);
          });
          sessionStorage.setItem("classInfo", JSON.stringify(classList.value));
        })
        .catch(() => {
          alert("課表讀取失敗，請至匯入課程頁面啟用課表。");
        });
    });

    const searchClass = () => {
      const tempdata = JSON.parse(sessionStorage.getItem("classInfo"));
      classList.value = _.filter(tempdata, (item) => {
        if (item.className.includes(searchKey.value)) {
          return item.className.includes(searchKey.value);
        } else if (item.tutorName.includes(searchKey.value)) {
          return item.tutorName.includes(searchKey.value);
        } else if (item.tutorId.includes(searchKey.value)) {
          return item.tutorId.includes(searchKey.value);
        } else if (item.classTeacher.includes(searchKey.value)) {
          return item.classTeacher.includes(searchKey.value);
        } else if (item.department.includes(searchKey.value)) {
          return item.department.includes(searchKey.value);
        }
      });
    };

    const renderModel = async (data) => {
      const modal = await openModal(Detail, { seq: data.seq });
      modal.onclose = () => {
        if (sessionStorage.getItem("reloadlist")) {
          classList.value = [];
          axios
            .post(process.env.VUE_APP_api_url + "/guidance/getClassesInfo")
            .then((response) => {
              sessionStorage.removeItem("reloadlist");
              response.data.forEach((e) => {
                classList.value.push(e);
              });
              sessionStorage.setItem(
                "classInfo",
                JSON.stringify(classList.value)
              );
            });
        }
      };
    };

    return { classList, searchKey, searchClass, renderModel };
  },
};
</script>
