<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <Header></Header>
  <div class="mt-2" id="mobile-menu">
    <button
      class="w-full btn bg-gray-300 p-1.5"
      @click="showMenu()"
      aria-label="打開菜單"
    >
      <i class="pi pi-align-justify" style="font-size: 1.5rem"></i>
    </button>
  </div>
  <div
    class="flex flex-wrap justify-center md:justify-between mt-3 md:my-3 gap-2 px-3 mb-4"
    id="link-header"
  >
    <router-link
      class="route-link flex-grow basis-full sm:basis-[calc(50%-0.5rem)] md:basis-[calc(33.333%-0.5rem)] lg:basis-[calc(25%-0.5rem)] xl:basis-[calc(14.285%-0.5rem)] text-center py-3 px-2 rounded-lg transition duration-300 ease-in-out"
      :class="{ active: pageIndex == 1 }"
      to="/"
      @click="changePageIndex(1)"
      >自主學習歷程</router-link
    >
    <router-link
      class="route-link flex-grow basis-full sm:basis-[calc(50%-0.5rem)] md:basis-[calc(33.333%-0.5rem)] lg:basis-[calc(25%-0.5rem)] xl:basis-[calc(14.285%-0.5rem)] text-center py-3 px-2 rounded-lg transition duration-300 ease-in-out"
      :class="{ active: pageIndex == 2 }"
      to="/program"
      @click="changePageIndex(2)"
      >修習學程</router-link
    >
    <router-link
      class="route-link flex-grow basis-full sm:basis-[calc(50%-0.5rem)] md:basis-[calc(33.333%-0.5rem)] lg:basis-[calc(25%-0.5rem)] xl:basis-[calc(14.285%-0.5rem)] text-center py-3 px-2 rounded-lg transition duration-300 ease-in-out"
      :class="{ active: pageIndex == 3 }"
      to="/counselor"
      @click="changePageIndex(3)"
      >線上輔導預約</router-link
    >
    <router-link
      class="route-link flex-grow basis-full sm:basis-[calc(50%-0.5rem)] md:basis-[calc(33.333%-0.5rem)] lg:basis-[calc(25%-0.5rem)] xl:basis-[calc(14.285%-0.5rem)] text-center py-3 px-2 rounded-lg transition duration-300 ease-in-out"
      :class="{ active: pageIndex == 4 }"
      to="/activity"
      @click="changePageIndex(4)"
      >活動歷程</router-link
    >
    <router-link
      class="route-link flex-grow basis-full sm:basis-[calc(50%-0.5rem)] md:basis-[calc(33.333%-0.5rem)] lg:basis-[calc(25%-0.5rem)] xl:basis-[calc(14.285%-0.5rem)] text-center py-3 px-2 rounded-lg transition duration-300 ease-in-out"
      :class="{ active: pageIndex == 5 }"
      to="/awardapply"
      @click="changePageIndex(5)"
      >獎勵申請</router-link
    >
    <router-link
      class="route-link flex-grow basis-full sm:basis-[calc(50%-0.5rem)] md:basis-[calc(33.333%-0.5rem)] lg:basis-[calc(25%-0.5rem)] xl:basis-[calc(14.285%-0.5rem)] text-center py-3 px-2 rounded-lg transition duration-300 ease-in-out"
      :class="{ active: pageIndex == 6 }"
      to="/wishlist"
      @click="changePageIndex(6)"
      >課程許願池</router-link
    >
    <router-link
      class="route-link flex-grow basis-full sm:basis-[calc(50%-0.5rem)] md:basis-[calc(33.333%-0.5rem)] lg:basis-[calc(25%-0.5rem)] xl:basis-[calc(14.285%-0.5rem)] text-center py-3 px-2 rounded-lg transition duration-300 ease-in-out"
      :class="{ active: pageIndex == 7 }"
      to="/feedback/course"
      @click="changePageIndex(7)"
      >課程評價</router-link
    >
  </div>
  <transition name="fade" mode="out-in">
    <router-view></router-view>
  </transition>
</template>

<script>
import { useCookies } from "vue3-cookies";
import { ref } from "vue";
import store from "@/store/index.js";
import Header from "./Header.vue";
export default {
  components: {
    Header,
  },
  setup() {
    var pageIndex =
      sessionStorage.getItem("pageIndex") != null
        ? ref(sessionStorage.getItem("pageIndex"))
        : ref(1);

    const { cookies } = useCookies();
    const changePageIndex = (n) => {
      var cookieToken = cookies.get("isLogin");
      isShow.value = false;
      document.querySelectorAll("#link-header .route-link").forEach((item) => {
        item.classList.remove("show");
      });
      if (cookieToken) {
        pageIndex.value = n;
        sessionStorage.setItem("pageIndex", n);
      } else if (!window.location.href.includes("localhost")) {
        store.commit("Logout");
      }
    };

    const isShow = ref(false);
    const showMenu = () => {
      if (isShow.value) {
        isShow.value = false;
        document
          .querySelectorAll("#link-header .route-link")
          .forEach((item) => {
            item.classList.remove("show");
          });
      } else {
        isShow.value = true;
        document
          .querySelectorAll("#link-header .route-link")
          .forEach((item) => {
            item.classList.add("show");
          });
      }
    };

    return {
      pageIndex,
      changePageIndex,
      showMenu,
    };
  },
};
</script>
