<template>
  <div class="border rounded-lg text-white p-5 m-5 text-left" style="background-color: #2db3b3">
    <div class="widget widget-info flex" style="padding-bottom: 0">
      <h3 class="widget-title">開放時間 Open Reserve Time</h3>
    </div>

    <div class="grid grid-cols-2 gap-3 mb-6 pt-0 mt-0 p-5">
      <div>
        <label for="name" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>開始日期</label>
        <div
          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:">
          <Calendar v-model="opendate.start_date" :showIcon="true" class="w-full" inputId="dateformat"
            dateFormat="yy-mm-dd" />
        </div>
      </div>

      <div>
        <label for="eng_name" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>結束日期</label>
        <div
          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:">
          <Calendar v-model="opendate.end_date" :showIcon="true" class="w-full" inputId="dateformat"
            dateFormat="yy-mm-dd" />
        </div>
      </div>
    </div>

    <div class="widget widget-info flex" style="padding-bottom: 0">
      <h3 class="widget-title">預約注意事項 Reserve Warning</h3>
    </div>
    <div class="p-5 pt-0 pb-0">
      <label class="block mb-2 text-lg font-medium text-white-900 dark:text-gray-300"><span style="color: red"> *
        </span>提供服務時間
      </label>
      <Editor v-model="warning.first" editorStyle="height: 80px"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
    </div>
    <div class="p-5 pt-0 pb-0">
      <label class="block mb-2 text-lg mt-3 font-medium text-white-900 dark:text-gray-300"><span style="color: red"> *
        </span>諮詢服務申請應依下列規定</label>
      <Editor v-model="warning.second" editorStyle="height:130px"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
    </div>

    <div class="p-5 pt-0 pb-0">
      <label class="block mb-2 text-lg mt-3 font-medium text-white-900 dark:text-gray-300"><span style="color: red"> *
        </span>檢視&取消申請</label>
      <Editor v-model="warning.third" editorStyle="height:100px"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
    </div>

    <div class="p-5 pt-0 pb-0">
      <label class="block mb-2 text-lg mt-3 font-medium text-white-900 dark:text-gray-300"><span style="color: red"> *
        </span>注意事項</label>
      <Editor v-model="warning.four" editorStyle="height:100px"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
    </div>
  </div>

  <div class="p-5 pt-0 pb-1">
    <button @click="updateDetail" type="button"
      class="p-3 pl-0 pr-0 w-full rounded-lg text-md hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
      style="background-color: gray; color: #fff">
      儲存設定
    </button>
  </div>
</template>

<script>
import Calendar from 'primevue/calendar';
import Editor from "primevue/editor";
import axios from "axios";
import moment from 'moment';
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";

export default {
  components: { Editor, Calendar },
  setup() {
    const warning = ref({
      first: "",
      second: "",
      third: "",
      four: "",
    });

    const opendate = ref({
      start_date: "",
      end_date: "",
    })

    onMounted(() => {
      axios
        .post(process.env.VUE_APP_api_url + "/guidance/warning", {
          typename: "guidance",
        })
        .then((response) => {
          const warningInfo = JSON.parse(response.data[0].warning.split(","));
          opendate.value.start_date = response.data[0].start_date;
          opendate.value.end_date = response.data[0].end_date;
          warning.value.first = warningInfo.first;
          warning.value.second = warningInfo.second;
          warning.value.third = warningInfo.third;
          warning.value.four = warningInfo.four;
        });

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, "300")
    });

    const updateDetail = () => {
      if (moment(warning.value.start_date).isAfter(moment(warning.value.end_date))) {
        alert("結束日期請勿早於開始日期！")
      } else {
        let yes = confirm("確定要儲存嗎？")
        if (yes) {
          opendate.value.start_date = moment(opendate.value.start_date).format("YYYY-MM-DD")
          opendate.value.end_date = moment(opendate.value.end_date).format("YYYY-MM-DD")
          axios
            .post(process.env.VUE_APP_api_url + "/guidance/updatewarning", {
              typename: "guidance",
              warning: warning.value,
              opendate: opendate.value
            })
            .then((response) => {
              if (response.status == 200) {
                alert("更新成功！");
                location.reload();
              } else {
                alert("更新失敗！");
              }
            });
        }
      }
    };

    return { warning, updateDetail, opendate };
  },
};
</script>

<style scope>
.p-button {
  background-color: #2EB3B3 !important;
  border: #2EB3B3 !important;
}
</style>