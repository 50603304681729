<template>
  <div class="program">
    <div style="margin: 0 auto; width: 100%" class="pb-5 bg-gray-200">
      <button class="next-step" style="
            margin-bottom: 0px;
            padding: 10px;
            font-size: 18px;
            position: absolute;
            left: 20px;
            margin-top: 7.5px;
          " @click="GoVerifyList()">
        <span><span class="addAward">返回</span></span>
      </button>

      <h1 class="title pt-3 mb-3" style="font-size: 24px"> 專業證照獎勵 / 申請歷史紀錄</h1>

      <button class="next-step" v-if="applyStatus || stuInfo.userId == '11044248'" style="
            margin-bottom: 0px;
            padding: 10px;
            font-size: 18px;
            position: absolute;
            right: 20px;
            margin-top: -50.7px;
          " @click="changePageIndex(1)">
        <span><i class="pi pi-plus"></i><span class="text-white ml-2">新增申請</span></span>
      </button>

      <el-tooltip v-else class="box-item" effect="dark" placement="top-end">
        <template #content>
          <span>{{ dateContent }}</span>
        </template>
        <button class="back-step" style="
              margin-bottom: 0px;
              padding: 10px;
              font-size: 18px;
              position: absolute;
              right: 20px;
              margin-top: -50.7px;
            ">
          <span><i class="pi pi-plus"></i><span class="addAward ml-2">非申請期間</span></span>
        </button>
      </el-tooltip>

      <div class="program mt-8" style="
            display: block;
            width: 100%;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
          ">
        <table class="program-table">
          <tr class="header-table">
            <th style="width: 5%">編號</th>
            <th style="width: 30%">證照名稱</th>
            <th style="width: 10%">證照等級</th>
            <th style="width: 10%">證照類型</th>
            <th style="width: 20%">申請日期</th>
            <th style="width: 15%">狀態</th>
            <th style="width: 10%">操作</th>
          </tr>

          <td v-if="dataList.length == 0" colspan="7">無資料</td>

          <tr class="ApplyDataRow" v-for="(item, index) in dataList" :key="item.seq">
            <td>{{ index + 1 }}</td>
            <td class="font-black">{{ item.licenseName }}</td>
            <td>{{ item.licenseLevel }}</td>
            <td>{{ item.licenseType }}</td>

            <td>
              {{ getLocalDate(item.ApplyTime) }}
            </td>
            <td v-if="item.State == 0">
              <span
                class="bg-blue-100 text-blue-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-blue-200 dark:text-blue-800">
                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clip-rule="evenodd"></path>
                </svg>

                審核中
              </span>
            </td>
            <td v-else-if="item.State == 1">
              <span
                class="bg-green-100 text-green-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-green-200 dark:text-green-800">
                <i class="pi pi-check"></i>
                通過
              </span>
            </td>
            <td v-else-if="item.State == 2">
              <span
                class="bg-red-100 text-red-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-red-200 dark:text-red-800">
                <i class="pi pi-times"></i>
                未通過
              </span>
            </td>
            <td v-else-if="item.State == 3">
              <span
                class="bg-gray-100 text-gray-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-gray-200 dark:text-gray-800"><i
                  class="pi pi-times-circle mr-1"></i>已取消</span>
            </td>
            <td>
              <button @click="GoDetailPage(item.seq)" type="button"
                class="button text-white font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center">
                <svg class="h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                  <path fill-rule="evenodd"
                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                    clip-rule="evenodd"></path>
                </svg>
                檢視
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import moment from "moment";
import axios from "axios";

export default {
  props: {
    changePageIndex: Function,
  },
  setup() {
    const router = useRouter();
    const dataList = ref([]);

    const GoDetailPage = (index) => {
      router.push({
        path: "/awardapply/prolicense/" + index,
      });
    };

    const GoVerifyList = () => {
      router.push({
        path: "/awardapply/",
      });
    };

    const stuInfo = ref({});
    const dateContent = ref("");
    const applyStatus = ref(true);
    onMounted(() => {
      stuInfo.value = JSON.parse(sessionStorage.getItem("stuInfo"));
      
      var statusList = JSON.parse(sessionStorage.getItem("AwardApplyStatus"));
      statusList.forEach((x) => {
        if (x.eng_name == "prolicense") {
          applyStatus.value = x.status;
          dateContent.value =
            moment(x.startDate).format("YYYY-MM-DD") +
            "~" +
            moment(x.endDate).format("YYYY-MM-DD");
        }
      });

      axios
        .post(process.env.VUE_APP_api_url + "/awardapply/pro/selectall", {
          userId: stuInfo.value.userId,
        })
        .then((data) => {
          dataList.value = data.data;
        });
    });

    return {
      dataList,
      GoDetailPage,
      GoVerifyList,
      applyStatus,
      dateContent,
      stuInfo,
    };
  },
  methods: {
    getLocalDate: function (date) {
      if (date == undefined) return "";
      return moment(date).local().format("YYYY/MM/DD HH:mm:ss");
    },
  },
};
</script>
