<template>
  <DataTable
    class="p-5"
    id="cooltable"
    editMode="cell"
    :value="classdata"
    :paginator="true"
    :rows="20"
    @cell-edit-complete="onCellEditComplete"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    responsiveLayout="scroll"
    contextMenu
  >
    <Column field="department" header="開課系級" sortable></Column>
    <Column field="className" header="課程名稱" style="width: 20%"></Column>
    <Column field="classTeacher" header="開課教授"></Column>
    <Column field="consultTime1" header="預約時間(一)"></Column>
    <Column field="consultTime2" header="預約時間(二)"></Column>
    <Column field="place" header="地點(一)"></Column>
    <Column field="place2" header="地點(二)"></Column>
    <Column field="tutorId" header="課守學號"></Column>
    <Column field="tutorName" header="課守姓名"></Column>
    <Column field="tutorPhone" header="課守手機"></Column>
    <Column field="tutorEmail" header="課守信箱"></Column>
    <template #paginatorstart></template>
    <template #paginatorend></template>
  </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "axios";
import { onMounted, ref } from "vue";

export default {
  components: {
    DataTable,
    Column,
  },
  props: {
    token: String,
  },
  setup(props) {
    const classdata = ref([]);

    onMounted(() => {
      axios
        .post(process.env.VUE_APP_api_url + "/guidance/getclassbytoken", {
          token: props.token,
        })
        .then((response) => {
          response.data.forEach((item) => {
            classdata.value.push(item);
          });
        });
    });

    return { classdata };
  },
};
</script>

<style scope>
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3e3e3e21;
}
</style>
