<template>
  <div class="text-left">
    <div style="margin: 0 auto; width: 100%" class="bg-gray-200 pb-2">
      <p class="p-3 pt-6" style="
          text-align: center;
          font-size: 23px;
          font-weight: bolder;
          color: #eb3434;
          max-width: 650px;
          margin: 0 auto;
        ">
        中原大學 學生考取專業證照獎勵
      </p>

      <div class="p-5" style="
          background: rgba(0, 0, 0, 0.08);
          border-radius: 15px;
          display: flex;
          max-width: 650px;
          margin: 0 auto;
        ">
        <div class="align-middle p-2" style="align-self: center">
          <img class="warning-img" src="../../../../assets/number-one.png" />
        </div>
        <div class="flex-1 p-5">
          <h1 class="font-black uppercase truncate title">申請資格</h1>
          <p v-html="data.stepOne"></p>
        </div>
      </div>

      <div class="p-5" style="
          background: rgba(0, 0, 0, 0.08);
          border-radius: 15px;
          display: flex;
          width: 100%;
          max-width: 650px;
          margin: 10px auto;
        ">
        <div class="align-middle p-2" style="align-self: center">
          <img class="warning-img" src="../../../../assets/number-two.png" />
        </div>
        <div class="flex-1 p-5">
          <h1 class="font-black text-lg uppercase truncate title">申請流程</h1>
          <p v-html="data.stepTwo"></p>
        </div>
      </div>

      <div class="p-5" style="
          background: rgba(0, 0, 0, 0.08);
          border-radius: 15px;
          display: flex;
          width: 100%;
          max-width: 650px;
          margin: 10px auto;
        ">
        <div class="align-middle p-2" style="align-self: center">
          <img src="../../../../assets/number-three.png" class="warning-img" />
        </div>
        <div class="flex-1 p-5">
          <h1 class="font-black text-lg uppercase truncate title">獎勵金額</h1>
          <p v-html="data.stepThree"></p>
        </div>
      </div>

      <div class="p-5" style="
          background: rgba(0, 0, 0, 0.08);
          border-radius: 15px;
          display: flex;
          width: 100%;
          max-width: 650px;
          margin: 10px auto;
        ">
        <div class="align-middle p-2" style="align-self: center">
          <img class="warning-img" src="../../../../assets/number-four.png" />
        </div>
        <div class="flex-1 p-5">
          <h1 class="font-black text-lg uppercase truncate title">注意事項</h1>
          <p v-html="data.stepFour"></p>
        </div>
      </div>
    </div>

    <div class="flex">
      <button class="flex-auto back-step" style="padding: 10px; font-size: 20px" @click="changePageIndex(0)">
        <span>
          <i class="pi pi-arrow-circle-left"></i>
          <span class="addAward ml-2">上一步</span></span>
      </button>

      <button class="flex-auto next-step" style="padding: 10px; font-size: 20px" @click="changePageIndex(2)">
        <span>
          <span class="addAward mr-2">下一步</span>
          <i class="pi pi-chevron-circle-right"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core';

export default {
  components: {},
  props: {
    changePageIndex: Function,
  },
  setup() {
    const data = ref({
      stepOne: "",
      stepTwo: "",
      stepThree: "",
      stepFour: "",
    })

    onMounted(() => {
      Object.assign(data.value, JSON.parse(sessionStorage.getItem("prolicense_notice")))
    })

    return { data }
  },
};
</script>
