<template>
    <div class="flex">
        <div class="mr-2">
            <img src="../../../assets/group/announcement.png">
        </div>
        <marquee class="text-lg justify-center text-gray-600 flex items-center" scrollamount="5">
            <div v-for="(mar, index) in mars" :key="index" v-html="mar.content"></div>
        </marquee>
        <div style="clear:both"></div>
    </div>
    <div class="text-xl text-main-primary text-left">
        <div class="ml-0 m-4">
            進行時程
        </div>
        <ol class="relative border-l border-gray-200">
            <li v-for="(time, index) in notices" :key="index" class="mb-3 ml-4">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white">
                </div>
                <h3 class="text-lg font-semibold text-gray-900">{{ time.content }}</h3>
                <time class="mb-1 text-sm font-normal leading-none text-gray-400">
                    {{ formatDate(time.start_date, 'YYYY-MM-DD') }}
                    {{ formatDate(time.end_date, 'YYYY-MM-DD') == "Invalid date" ? "" : "~ " + formatDate(time.end_date,
                        'YYYY-MM-DD') }}
                </time>
            </li>
        </ol>

        <!-- <ol class="relative border-l border-gray-200">
            <li v-for="(time, index) in notices" :key="index" class="mb-3 ml-4">
                <div class="absolute w-3 h-3 bg-main-primary rounded-full mt-1.5 -left-1.5 border border-white">
                </div>
                <h3 class="text-lg font-semibold text-main-primary">{{ time.content }}</h3>
                <time class="mb-1 text-sm font-normal leading-none text-gray-400">
                    {{ formatDate(time.start_date, 'YYYY-MM-DD') }}
                    {{ formatDate(time.end_date, 'YYYY-MM-DD') == "Invalid date" ? "" : "~ " + formatDate(time.end_date,
                        'YYYY-MM-DD') }}
                </time>
            </li>
        </ol> -->

    </div>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import axios from "axios";
import moment from "moment";

export default {
    setup() {
        const mars = ref([]);
        const notices = ref([]);
        const app = getCurrentInstance().appContext.app;

        const formatDate = (value, format) => {
            return moment(value).format(format || 'YYYY-MM-DD, HH:mm:ss');
        };

        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
                .then((response) => {
                    const allNotices = response.data;
                    mars.value = allNotices.filter((notice) => notice.type === "跑馬燈");
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
                .then((response) => {
                    const allNotices = response.data;
                    notices.value = allNotices.filter((notice) => notice.type === "時間軸");
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        app.config.globalProperties.formatDate = formatDate;

        return {
            mars,
            notices,
        };
    },
};
</script>