import { createStore } from "vuex";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export default createStore({
  state: {
    IsLogin: false,
    IsAdmin: false,
    GuidanceAgree: false,
    IsVenue: false,
  },
  mutations: {
    Login(state) {
      state.IsLogin = true;
    },
    Logout(state) {
      state.IsLogin = false;
      sessionStorage.clear();
      localStorage.removeItem("jwt-token")
      cookies.keys().forEach((cookie) => cookies.remove(cookie));
    },
    AdminLogin(state) {
      state.IsAdmin = true;
    },
    AdminLogout(state) {
      state.IsAdmin = false;
      sessionStorage.clear();
      localStorage.removeItem("jwt-token")
      cookies.keys().forEach((cookie) => cookies.remove(cookie));
    },
    GuidanceAgree(state) {
      state.GuidanceAgree = true;
    },
    IsVenue(state) {
      state.IsVenue = true;
    },
    NotVenue(state) {
      state.IsVenue = false;
    },
  },
  actions: {},
  modules: {},
});
