<template>
  <header class="bg-white">
    <nav
      class="mx-auto grid grid-cols-1 md:max-w-7xl items-center justify-between p-6 lg:px-8 pb-3 md:mx-auto md:flex"
    >
      <div class="flex-none w-64">
        <a href="/wishlist" class="-m-1.5 p-1.5 flex">
          <img class="h-12 w-auto" src="../../../assets/book.gif" alt="" />
          <p class="text-xl" style="margin-left: 5px; margin-top: 14px">
            課程評價
          </p>
        </a>
      </div>
      <div class="flex-1">
        <span class="p-input-icon-left my-2 w-full md:mt-0">
          <i class="pi pi-search"></i>
          <InputText
            placeholder="Search"
            style="width: 100%; border-radius: 9999px !important"
          />
        </span>
      </div>
      <div class="flex-none w-full md:w-52 md:pl-3 mx-auto">
        <div
          id="wish-button"
          class="rounded-full"
          style="background-color: #54bbbb"
        >
          <button
            class="mx-auto p-2 text-xl text-white"
            @click="feedback_click()"
          >
            我想推薦課程
          </button>
        </div>
      </div>
    </nav>
  </header>

  <div class="mx-auto max-w-7xl items-center justify-between p-6 pt-1 lg:px-8">
    <div class="waterfall text-left" ref="waterfall">
      <div
        class="item block p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
        v-for="(feedback, index) in course_feedback"
        :key="index"
      >
        <h5
          class="flex mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white"
        >
          <p class="flex-1 text-medium">
            {{ feedback.recommended_course_name }}
          </p>
          <div class="flex justify-end">
            <img
              v-if="wishs_favorite.includes(NumToString(feedback.id))"
              id="like"
              @click="favorite_click(NumToString(feedback.id))"
              src="../../../assets/heart.png"
              alt="Favorite"
              class="h-6 cursor-pointer"
            />
            <img
              v-else
              id="unlike"
              @click="favorite_click(NumToString(feedback.id))"
              src="../../../assets/empty-heart.png"
              alt="Unfavorite"
              class="h-6 cursor-pointer"
            />
            <p class="ml-1">{{ feedback.count }}</p>
          </div>
        </h5>
        <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />
        <h5
          class="mb-1 mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          開課系所：
        </h5>
        <p class="break-words">{{ feedback.teaching_unit }}</p>

        <h5
          class="mb-1 mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          開課老師：
        </h5>
        <p class="break-words">{{ feedback.teacher }}</p>
        <h5
          class="mb-1 mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          課程推薦心得：
        </h5>
        <p class="whitespace-pre-wrap break-words">
          {{ truncateFeedback(feedback.feedback, feedback.showFullFeedback) }}
          <span
            v-if="feedback.feedback.length > 24"
            class="text-blue-500 cursor-pointer"
            @click="toggleFeedback(index)"
          >
            {{ feedback.showFullFeedback ? "收起" : "more..." }}
          </span>
        </p>
      </div>
    </div>
  </div>
  <container />
</template>

<script setup>
import InputText from "primevue/inputtext";
import axios from "axios";
import { onMounted, ref, nextTick } from "vue";
import { container, openModal } from "jenesius-vue-modal";
import Masonry from "masonry-layout";
import CourseWindow from "./CourseWindow.vue";

const wishs_favorite = ref([]);
const course_feedback = ref([]);
const waterfall = ref(null);
let msnry = null;

const feedback_click = () => {
  openModal(CourseWindow, {
    onFormSubmitted: handleFormSubmitted,
  });
};

const handleFormSubmitted = () => {
  // Generate a temporary id for the new feedback
  // const tempId = parseInt(newFeedback.id);

  // // Add the new feedback to the beginning of the array
  // course_feedback.value.unshift({
  //   ...newFeedback,
  //   id: tempId, // Use the temporary id
  //   showFullFeedback: false,
  //   count: 0, // Assuming new submissions start with 0 likes
  //   department: newFeedback.department || "未知", // Ensure department is set
  // });

  // Update the masonry layout
  nextTick(() => {
    if (msnry) {
      msnry.reloadItems();
      msnry.layout();
    } else {
      console.warn("Masonry instance not initialized");
    }
  });
};

const favorite_click = (index) => {
  if (!wishs_favorite.value.includes(index)) {
    axios
      .post(process.env.VUE_APP_api_url + "/feedback/course/like", {
        wish_id: index,
      })
      .then((response) => {
        const wishcard_index = course_feedback.value.findIndex((item) => {
          return index === NumToString(item.id);
        });

        course_feedback.value[wishcard_index].count = response.data.count;

        wishs_favorite.value.push(index);
        localStorage.setItem("wishs_favorite", wishs_favorite.value);
      });
  } else {
    axios
      .post(process.env.VUE_APP_api_url + "/feedback/course/unlike", {
        wish_id: index,
      })
      .then((response) => {
        const wishcard_index = course_feedback.value.findIndex((item) => {
          return index === NumToString(item.id);
        });

        course_feedback.value[wishcard_index].count = response.data.count;

        wishs_favorite.value.splice(
          wishs_favorite.value.findIndex((item) => NumToString(index) === item),
          1
        );
        localStorage.setItem("wishs_favorite", wishs_favorite.value);
      });
  }
};

onMounted(() => {
  if (localStorage.getItem("wishs_favorite")) {
    wishs_favorite.value = localStorage.getItem("wishs_favorite").split(",");
  }

  axios
    .post(process.env.VUE_APP_api_url + "/feedback/course/feedback")
    .then((response) => {
      course_feedback.value = response.data
        .filter((item) => item.review_status === "通過")
        .map((item) => ({
          ...item,
          showFullFeedback: false,
        }));
      sessionStorage.setItem(
        "course_feedback",
        JSON.stringify(course_feedback.value)
      );

      nextTick(() => {
        initMasonry();
      });
    });
});

const initMasonry = () => {
  msnry = new Masonry(waterfall.value, {
    itemSelector: ".item",
    columnWidth: ".item",
    percentPosition: true,
  });
};

const truncateFeedback = (feedback, showFull) => {
  if (feedback.length <= 24 || showFull) {
    return feedback;
  }
  return feedback.slice(0, 24) + "...";
};

const toggleFeedback = (index) => {
  course_feedback.value[index].showFullFeedback =
    !course_feedback.value[index].showFullFeedback;
  nextTick(() => {
    msnry.layout();
  });
};

const NumToString = (num) => {
  return num.toString();
};
</script>

<style scoped>
.flex-gap > div {
  margin: 3px;
}

.waterfall {
  width: 100%;
  margin: 0 auto;
}
.waterfall .item {
  margin-left: 4px;
  margin-right: 4px;
}
.item {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.item:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media (min-width: 640px) {
  .item {
    width: calc(50% - 10px);
  }
}

@media (min-width: 768px) {
  .item {
    width: calc(33.333% - 13.333px);
  }
}

@media (min-width: 1024px) {
  .item {
    width: calc(25% - 15px);
  }
}

.item p {
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 100%;
}
</style>
