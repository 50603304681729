<template>
  <div class="border rounded-lg text-white p-5 m-5 text-left" style="background-color: #2db3b3">
    <div class="widget widget-info flex" style="padding-bottom: 0">
      <h3 class="widget-title">申請資訊 Apply Information</h3>
    </div>
    <div class="p-5 pt-0 pb-0">
      <label for="sem" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span style="color: red">
          * </span>開放申請學期</label>
      <input contenteditable="true" type="text" id="sem" name="sem" v-model="data.sem"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:"
        required />
    </div>
    <div class="grid gap-6 p-5 lg:grid-cols-2">
      <div>
        <label for="name" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>名稱(類型)</label>
        <input type="text" id="name" name="name" v-model="data.name" disabled
          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:"
          required />
      </div>

      <div>
        <label for="eng_name" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>英文名稱(類型)</label>
        <input type="text" id="eng_name" name="eng_name" v-model="data.eng_name" disabled
          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:"
          required />
      </div>

      <div>
        <label for="startDate" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>開始日期</label>
        <input datepicker type="date" id="startDate" name="startDate" v-model="data.startDate"
          class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-md rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:"
          placeholder="開始日期" required />
      </div>
      <div>
        <label for="endDate" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>結束日期</label>
        <input datepicker type="date" id="endDate" name="endDate" v-model="data.endDate"
          class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-md rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:"
          placeholder="結束日期" required />
      </div>
    </div>

    <div class="p-5 pt-0">
      <label for="description" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-400"><span
          style="color: red"> * </span>描述</label>
      <textarea id="description" rows="4" v-model="data.description"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:"></textarea>
    </div>
  </div>

  <div class="border rounded-lg text-white p-5 m-5 text-left" style="background-color: #2db3b3">
    <div class="widget widget-info flex" style="padding-bottom: 0">
      <h3 class="widget-title">申請注意事項 Apply Warning</h3>
    </div>
    <div class="p-5 pt-0 pb-0">
      <label for="sem" class="block mb-2 text-lg mt-3 font-medium text-white-900 dark:text-gray-300"><span
          style="color: red"> * </span>申請資格</label>
      <Editor v-model="data.stepOne" contenteditable="false" editorStyle="height: 150px"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:" />
    </div>
    <div class="p-5 pt-0 pb-0">
      <label for="sem" class="block mb-2 text-lg mt-3 font-medium text-white-900 dark:text-gray-300"><span
          style="color: red"> * </span>申請流程</label>
      <Editor v-model="data.stepTwo" contenteditable="false" editorStyle="height: 150px"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:" />
    </div>

    <div class="p-5 pt-0 pb-0">
      <label for="sem" class="block mb-2 text-lg mt-3 font-medium text-white-900 dark:text-gray-300"><span
          style="color: red"> * </span>獎勵金額</label>
      <Editor v-model="data.stepThree" contenteditable="false" editorStyle="height: 80px"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:" />
    </div>

    <div class="p-5 pt-0 pb-0">
      <label for="sem" class="block mb-2 text-lg mt-3 font-medium text-white-900 dark:text-gray-300"><span
          style="color: red"> * </span>注意事項</label>
      <Editor v-model="data.stepFour" contenteditable="false" editorStyle="height: 150px"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:" />
    </div>
    <div class="widget widget-info mt-5 flex" style="padding-bottom: 0">
      <h3 class="widget-title">送出提醒 Submit Alert</h3>
    </div>
    <div class="p-5 pt-0 pb-0">
      <label for="sem" class="block mb-2 text-lg font-medium text-white-900 dark:text-gray-300"><span style="color: red">
          * </span>送出審核提醒</label>
      <Editor v-model="data.submitWarning" contenteditable="false" editorStyle="height: 100px" :disabled="!stepcheck"
        class="block p-2.5 w-full text-md text-gray-900 bg-gray-50 rounded-lg border border-gray-300   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark: dark:" />
    </div>
  </div>
  <div class="p-5 pt-0">
    <button @click="updateDetail" type="button"
      class="p-3 pl-0 pr-0 w-full rounded-lg text-md hover:bg-gray-900 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:border-gray-700"
      style="background-color: gray; color: #fff">
      儲存設定
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import moment from "moment";
import axios from "axios";
import Editor from 'primevue/editor';

export default {
  components: { Editor },
  setup() {
    const data = ref({
      sem: null,
      name: null,
      eng_name: null,
      startDate: null,
      endDate: null,
      description: "",
      stepOne: "",
      stepTwo: "",
      stepThree: "",
      stepFour: "",
      submitWarning: ""
    });

    const getLocalDate = (date) => {
      if (date == undefined) return "";
      return moment(date).local().format("YYYY-MM-DD");
    };

    onMounted(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, "100")

      axios
        .get(
          process.env.VUE_APP_api_url + "/awardapply/applydetail?eng_name=ProLicense"
        )
        .then((d) => {
          data.value.sem = d.data[0].sem;
          data.value.name = d.data[0].name;
          data.value.eng_name = d.data[0].eng_name;
          data.value.startDate = getLocalDate(d.data[0].startDate);
          data.value.endDate = getLocalDate(d.data[0].endDate);
          data.value.description = d.data[0].description;
          data.value.stepOne = d.data[0].stepOne;
          data.value.stepTwo = d.data[0].stepTwo;
          data.value.stepThree = d.data[0].stepThree;
          data.value.stepFour = d.data[0].stepFour;
          data.value.submitWarning = d.data[0].submitWarning;
        });
    });

    const updateDetail = () => {
      var yes = confirm("你確定要儲存嗎？");
      if (yes) {
        axios
          .post(
            process.env.VUE_APP_api_url + "/awardApply/updateDetail",
            data.value
          )
          .then(() => {
            alert("儲存成功");
            location.reload();
          });
      }
    };

    return { data, updateDetail };
  },
};
</script>

<style>
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #000000 !important;
}
</style>
