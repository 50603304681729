<template>
    <div class="block p-6 rounded-lg shadow-lg bg-white max-w-md">
        <div class="grid grid-cols-2 gap-2">
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">諮詢日期</label>
                <div class="form-group mb-3">

                    <input type="text" id="tutorTimeText"
                        :value="reserveDetail.ReserveDate + ' ' + reserveDetail.ReserveTime"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">諮詢科目</label>
                <div class="form-group mb-3">
                    <input type="text" :value="reserveDetail.subject"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-2 gap-2">
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">諮詢地點</label>
                <div class="form-group mb-3">

                    <input type="text" id="tutorTimeText" :value="reserveDetail.place"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">課業守護天使</label>
                <div class="form-group mb-3">
                    <input type="text" :value="reserveDetail.tutorName"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-2 gap-2">
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">課守聯絡電話</label>
                <div class="relative">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" focusable="false" class="icon" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512" width="1em" height="1em">
                            <path
                                d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
                        </svg>
                    </div>
                    <input type="number" maxlength="10" v-model="tutorInfo.tutorPhone" disabled
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="0912-345-678">
                </div>
            </div>
            <div>
                <label for="stuEmail" class="reserveTitle form-label ml-0.5 inline-block text-gray-700">課守電子郵件</label>
                <div class="relative">
                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-700 dark:text-gray-400" fill="currentColor"
                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                        </svg>
                    </div>
                    <input type="text" v-model="tutorInfo.tutorEmail" disabled
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="mail@sample.com">
                </div>
            </div>
        </div>

        <hr class="mt-3 mb-3">

        <div class="grid grid-cols-2 gap-2">
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">學生學號</label>
                <div class="form-group mb-3">

                    <input type="text" id="tutorTimeText" :value="stuInfo.stuId"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">學生姓名</label>
                <div class="form-group mb-3">
                    <input type="text" :value="stuInfo.stuName"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
        </div>

        <div class="grid grid-cols-2 gap-2">
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">學生信箱</label>
                <div class="form-group mb-3">

                    <input type="text" id="tutorTimeText" :value="stuInfo.stuEmail"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
            <div>
                <label for="exampleInputEmail1"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">學生聯絡電話</label>
                <div class="form-group mb-3">
                    <input type="text" :value="stuInfo.stuPhone"
                        class="form-control block text-md w-full px-3 py-2 font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        disabled />
                </div>
            </div>
        </div>

        <hr class="mt-3 mb-3">

        <div class="grid grid-cols-1 mt-3">
            <div>
                <label for="Description" class="reserveTitle form-label ml-0.5 inline-block text-gray-700">學生問題</label>
                <div class="form-group mb-3">
                    <textarea id="message" rows="3" :value="reserveDetail.description" disabled
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-1 mt-1">
            <div>
                <label for="Description"
                    class="reserveTitle form-label ml-0.5 inline-block text-gray-700">課業守護天使回覆</label>
                <div class="form-group mb-3">
                    <textarea id="message" rows="3" :value="reserveDetail.tutorReply" disabled
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                </div>
            </div>
        </div>

        <button type="button" id="reserveBtn" @click="closeModal()"
            class="w-full px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
            返回
        </button>
    </div>

</template>

<script>
import axios from "axios"
import { onMounted, ref } from '@vue/runtime-core';
import { closeModal } from "jenesius-vue-modal";

export default {
    props: {
        reserveDetail: Object
    },
    setup(props) {
        const tutorInfo = ref({})
        const stuInfo = ref({})
        onMounted(() => {
            axios.post(process.env.VUE_APP_api_url + "/guidance/tutorInfo", {
                tutorId: props.reserveDetail.tutorId
            }).then((response) => {
                Object.assign(tutorInfo.value, response.data[0]);
            })

            axios.post(process.env.VUE_APP_api_url + "/guidance/getReserveStuInfo", {
                seq: props.reserveDetail.seq
            }).then((response) => {
                Object.assign(stuInfo.value, response.data[0]);
            })
        })
        return { tutorInfo, stuInfo, closeModal }
    },
}
</script>