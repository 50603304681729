<template>
  <div id="loader" class="loader loader-border is-active">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../assets/Spin-1s-200px.gif" />
        </div>
        <p class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>
  <div class="StudentProgram">
    <div class="program">
      <h1 class="title">參與活動歷程</h1>
      <table class="program-table">
        <tr class="header-table">
          <th style="width: 10%">編號</th>
          <th style="width: 50%">名稱</th>
          <th style="width: 10%">時數(分鐘)</th>
          <th style="width: 30%">日期</th>
        </tr>

        <td v-if="userActivity.length == 0" colspan="4">無資料</td>

        <tr v-for="(item, index) in userActivity" :key="item.act_sn">
          <td>{{ index + 1 }}</td>
          <td>{{ item.title }}</td>
          <td v-if="!item.hours">N/A</td>
          <td v-else>{{ item.hours }}</td>
          <td>{{ getLocalDate(item.start_time) }}</td>
        </tr>
      </table>
      <div class="text-right p-10 pt-4">
        <a style="
            border-bottom: 2px solid #2db3b3;
            font-weight: bolder;
            padding-bottom: 5px;
            font-size: 18px;
          " target="_blank"
          href="https://itouch.cycu.edu.tw/active_project/cycu2100h_06/acpm3/#!/activityList/welcome">查看更多相關活動 <i
            class="pi pi-angle-right mr-1"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { onMounted, ref } from "@vue/runtime-core";
import moment from "moment";

export default {
  setup() {
    const userActivity = ref([]);

    onMounted(() => {
      axios
        .post(process.env.VUE_APP_api_url + "/getdata/activity")
        .then((d) => {
          d.data.actList.forEach((e) => {
            const activity = {
              act_sn: e.act_sn,
              end_time: e.end_time,
              hours: e.hours,
              reg_time: e.reg_time,
              start_time: e.start_time,
              title: e.title,
            };
            userActivity.value.push(activity);
          });
        })
        .finally(() => {
          const loader = document.getElementById("loader");
          loader.classList.remove("is-active");
        });
    });

    return { userActivity };
  },
  methods: {
    getLocalDate: function (date) {
      if (date == undefined) return "";
      return moment(date).local().format("YYYY年MM月DD日");
    },
  },
};
</script>
