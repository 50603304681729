<template>
    <div id="export" class="loader loader-border">
        <div class="loader-area">
            <div class="loader-inner">
                <div class="loader-image">
                    <img src="../../../../assets/Spin-1s-200px.gif" />
                </div>
                <p class="loader-text">匯出Excel中...</p>
            </div>
        </div>
    </div>

    <DataTable class="p-5" :value="verifyList" :paginator="true" :rows="10"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]" responsiveLayout="scroll" currentPageReportTemplate="">
        
        <template #header>
            <div class="grid grid-nogutter">
                待審核清單
            </div>
        </template>

        <Column field="seq" style="width: 10%" sortable header="申請序號"></Column>
        <Column field="semCount" style="width: 10%" sortable header="收件編號"></Column>
        <Column field="sem" style="width: 10%" sortable header="學期"></Column>
        <Column field="stuName" style="width: 12%" header="申請人"></Column>
        <Column field="stuDept" style="width: 11%" header="系級"></Column>
        <Column field="licenseName" style="width: 20%" header="證照名稱"></Column>
        <Column field="ApplyTime" style="width: 15%" sortable header="申請日期">
            <template #body="{ data }">
                {{ getLocalDate(data.ApplyTime) }}
            </template>
        </Column>
        <Column field="State" header="狀態" sortable :filterMenuStyle="{ width: '14rem' }" style="width: 25%">
            <template #body="{ data }">
                <span v-if="data.State == 0"
                    class="bg-blue-100 text-blue-800 text-md pr-3 font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-blue-200 dark:text-blue-800">
                    <svg class="w-5 h-5 mr-0.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                            clip-rule="evenodd"></path>
                    </svg>

                    待審核
                </span>
                <span v-if="data.State == 1"
                    class="bg-green-100 pr-3 text-green-800 text-md font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-green-200 dark:text-green-800">
                    <i class="pi pi-check w-5 h-5 mr-0.5 mt-1"></i>
                    通過
                </span>
                <span v-if="data.State == 2"
                    class="bg-red-100 pr-3 text-red-800 text-md font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-red-200 dark:text-red-800">
                    <i class="pi pi-times w-5 h-5 mr-0.5 mt-1"></i>
                    未通過
                </span>
                <span v-if="data.State == 3"
                    class="bg-gray-100 pr-3 text-gray-800 text-md font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-gray-200 dark:text-gray-800"><i
                        class="pi pi-times-circle w-5 h-5 mr-0.5 mt-1"></i>已取消</span>
            </template>
        </Column>
        <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{ data }">
                <button @click="GoDetailPage(data.seq)" type="button"
                    class="button text-white font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center">
                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                        <path fill-rule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
            </template>
        </Column>
        <template #paginatorstart>
            <button v-if="verifyList.length > 0" style="background-color: #2db3b3" @click="exportExcel(verifyList)"
                class="text-white text-bolder p-5 pt-1 pb-1 rounded">
                匯出Excel
            </button>
        </template>
        <template #paginatorend> </template>
        <template #empty> 無待審核文件 </template>
    </DataTable>
    <widget-container-modal id="prolicense_window" />
</template>
  
<script>
import { openModal, container } from "jenesius-vue-modal";
import { ref, onMounted } from "@vue/runtime-core";
import axios from "axios";
import moment from "moment";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProDetail from "./Detail.vue";
import _ from "lodash"

export default {
    components: {
        WidgetContainerModal: container,
        DataTable,
        Column,
    },
    setup() {
        const GoDetailPage = (index) => {
            openModal(ProDetail, { id: index });
        };

        const verifyList = ref([]);
        const sortKey = ref("");
        const sortKey_status = ref("")

        const onSortChange = () => {
            const tempdata = JSON.parse(sessionStorage.getItem("dataSource"));
            tempdata.forEach((e) => {
                e.ApplyTime = getLocalDate(e.ApplyTime);
            });
            verifyList.value = tempdata.filter((e) => e.sem == sortKey.value);
            switch (sortKey_status.value) {
                case "通過":
                    verifyList.value = tempdata.filter((e) => e.State == 1);
                    break;
                case "未通過":
                    verifyList.value = tempdata.filter((e) => e.State == 2);
                    break;
                case "已取消":
                    verifyList.value = tempdata.filter((e) => e.State == 3);
                    break;
            }
        };

        const getLocalDate = (date) => {
            if (date == undefined) return "";
            return moment(date).local().format("YYYY/MM/DD HH:mm:ss");
        };

        onMounted(() => {
            axios
                .post(process.env.VUE_APP_api_url + "/awardapply/pro/verifyall")
                .then((data) => {
                    verifyList.value = _.filter(data.data, item => item.State == 0);
                    sessionStorage.setItem("dataSource", JSON.stringify(data.data));
                });
        });

        const getSortOption = () => {
            var options = [];
            if (sessionStorage.getItem("dataSource")) {
                const temp = JSON.parse(sessionStorage.getItem("dataSource"));
                for (let i = 0; i < temp.length; i++) {
                    if (!options.includes(temp[i].sem)) {
                        options.push(temp[i].sem);
                    }
                }
            }
            return options;
        };

        const getStatusSortOption = () => {
            var options = ["未通過", "已取消", "通過"];
            return options;
        };



        const exportExcel = () => {
            const exportData = verifyList.value.slice().reverse();
            axios
                .post(
                    process.env.VUE_APP_api_url + "/awardapply/pro/export",
                    exportData
                )
                .then((response) => {
                    const loader = document.getElementById("export");
                    loader.classList.add("is-active");
                    setTimeout(() => {
                        const downloadAnchor = document.createElement("a");
                        downloadAnchor.setAttribute(
                            "href",
                            process.env.VUE_APP_api_url + "/uploads/" +
                            response.data.path
                        );
                        downloadAnchor.setAttribute("download", response.data.fileName);
                        document.body.appendChild(downloadAnchor);
                        downloadAnchor.click();
                        document.body.removeChild(downloadAnchor);
                        loader.classList.remove("is-active");
                    }, 1500);
                });
        };

        return {
            verifyList,
            sortKey,
            sortKey_status,
            exportExcel,
            onSortChange,
            getLocalDate,
            getSortOption,
            getStatusSortOption,
            GoDetailPage,
        };
    },
    methods: {},
};
</script>
  