<template>
  <div class="bg-white p-5 relative" id="cooltable" style="width: 85% !important; border-top: 10px solid #2db3b3">
    <div class="py-2 md:py-0 md:absolute cursor-pointer" style="right: 30px" @click="closeModal()">
      <i class="pi pi-times" style="font-size: 1.5rem"></i>
    </div>

    <div class="text-left">
      <h4 class="font-bold">
        <span style="color: red">*</span> 您對哪個主題的課程或演講有興趣?
        (可複選)
      </h4>
      <h4 class="font-bold">
        Which topic of course or lecture are you interested in?
      </h4>

      <div class="grid grid-cols-2 md:grid-cols-4 mt-2 gap-3">
        <div class="flex align-items-center" v-for="(type, index) in type_list" :key="index">
          <Checkbox v-model="select_list" :inputId="type.id" name="type" :value="type.topic + '&' + type.topic_eng" />
          <label class="ml-1">{{ type.topic }}<br />{{ type.topic_eng }}</label>
        </div>
      </div>

      <hr class="mt-3 mb-3" />

      <h4 class="font-bold">
        <span style="color: red">*</span> 您希望自己能提升哪些能力？(可複選)
      </h4>
      <h4 class="font-bold">What skills would you want to improve？</h4>
      <div class="grid grid-cols-1 md:grid-cols-4 mt-2 gap-3">
        <div class="flex align-items-center" v-for="(type, index) in skill_list" :key="index">
          <Checkbox v-model="skill_select_list" :inputId="type.id" name="type"
            :value="type.topic + '&' + type.topic_eng" />
          <label class="ml-1">{{ type.topic }}<br />{{ type.topic_eng }}</label>
        </div>
      </div>

      <hr class="mt-3 mb-3" />

      <h4 class="font-bold">
        <span style="color: red">*</span>
        您有想要學習或感興趣的學習坊主題嗎？您許願我們幫你舉辦！
      </h4>
      <h4 class="font-bold mb-3">
        Do you have any workshop topics you want to learn or have interested in
        ? Tell us, let me help you to organize!
      </h4>

      <Textarea v-model="wish_data.q_workshop_topic" autoResize rows="5" cols="30" class="w-full"></Textarea>

      <hr class="mt-3 mb-3" />

      <h4 class="font-bold">
        <span style="color: red">*</span>
        您想學習或感興趣的學習坊主題，有推薦的講師介紹給我們吧!
      </h4>
      <h4 class="font-bold mb-3">
        If you have the workshop recommended lecturers that you want to learn
        and are interested in, please introduce to us!
      </h4>

      <Textarea v-model="wish_data.q_lecturers" autoResize rows="5" cols="30" class="w-full"></Textarea>

      <hr class="mt-3 mb-3" />

      <h4 class="font-bold">
        <span style="color: red">*</span>
        歡迎留下您 的 E-mail 與 暱稱,您推薦的學習坊主題若舉行，我們會通知您喔!
      </h4>
      <h4 class="font-bold mb-3">
        Please write your email and nickname so that we can notify you when the
        learning workshop you recommended is held.
      </h4>
      <div class="grid grid-cols-2 gap-4">
        <InputText type="text" v-model="wish_data.q_email"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="請輸入您的電子信箱 Email" />
        <InputText type="text" v-model="wish_data.nickname"
          class="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="請輸入您的暱稱 Nickname" />
      </div>

      <button class="w-full p-2 text-white mt-5" style="background-color: #2db3b3" @click="sendWish()">
        許下我的願望
      </button>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import Checkbox from "primevue/checkbox";
import Textarea from "primevue/textarea";
import InputText from "primevue/inputtext";
import { onMounted, ref } from "vue";
import { closeModal } from "jenesius-vue-modal";
import { ElNotification } from "element-plus";

const type_list = ref([]);
const select_list = ref([]);
const wish_data = ref({
  type: "",
  nickname: "",
  stuId: "N/A",
  stuDept: "N/A",
  q_workshop_topic: "",
  q_lecturers: "",
  q_email: "",
});

const checkObject = (obj) => {
  for (let key in obj) {
    if (obj[key] === "") {
      return false; // 返回 false 如果键的值是空字符串
    }
  }
  return true; // 返回 true 如果所有的键都有值
};

const skill_list = ref([]);
const skill_select_list = ref([]);
const stuInfo = ref({});
onMounted(() => {
  stuInfo.value = JSON.parse(sessionStorage.getItem("stuInfo"));
  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/getwishtopic")
    .then((response) => {
      response.data.forEach((item) => {
        type_list.value.push(item);
      });
    });

  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/getwishskill")
    .then((response) => {
      response.data.forEach((item) => {
        skill_list.value.push(item);
      });
    });
});

const sendWish = () => {
  wish_data.value.stuId = stuInfo.value.userId;
  wish_data.value.stuDept = stuInfo.value.userDept;
  wish_data.value.type = select_list.value.join(",");
  wish_data.value.skill = skill_select_list.value.join(",");

  console.log(wish_data.value.skill);
  if (checkObject(wish_data.value)) {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/addwish", wish_data.value)
      .then((response) => {
        if (response.data.write) {
          ElNotification({
            title: "成功",
            message: "您的願望已送出成功！",
            type: "success",
          });
          closeModal();
        }
      });
  } else {
    ElNotification({
      title: "錯誤",
      message: "無法送出，所有欄位不可為空。",
      type: "error",
    });
  }
};
</script>