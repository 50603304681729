<template>
  <div class="mx-auto max-w-7xl items-center justify-between p-6 pt-1 lg:px-8">
    <span class="p-input-icon-left mt-8 w-full">
      <i class="pi pi-search"></i>
      <InputText
        v-model="searchkey"
        @input="search"
        placeholder="Search"
        class="w-full rounded-lg"
      />
    </span>
    <div class="grid grid-cols-1 gap-6 text-left md:grid-cols-4 mt-4">
      <div
        v-for="(wish, index) in del_wish"
        :key="index"
        style="height: fit-content"
        class="relative block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
      >
        <Badge
          class="mt-2 w-full"
          style="background-color: rgb(188, 188, 188); border-radius: 20px"
          >{{
            moment(wish.create_time).local().format("YYYY/MM/DD HH:mm:ss")
          }}</Badge
        >

        <div class="grid grid-cols-4 gap-2 my-2 rounded-lg">
          <Tag class="col-span-2" style="border-radius: 20px" severity="info">{{
            wish.stuDept
          }}</Tag>
          <Tag class="col-span-2" style="border-radius: 20px" severity="info">{{
            wish.stuId
          }}</Tag>
          <Tag
            style="border-radius: 20px; text-align: left !important"
            class="col-span-4 mx-0 cut-text"
            >姓名: {{ wish.nickname }}</Tag
          >
        </div>

        <h5
          class="mb-1 underline mt-3 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          培育能力：
        </h5>

        <p class="break-words">
          {{ wish.skill }}
        </p>

        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          學習坊類型：
        </h5>

        <p class="break-words">
          {{ wish.type }}
        </p>

        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          感興趣的學習坊主題：
        </h5>
        <p class="break-words">
          {{ wish.q_workshop_topic }}
        </p>
        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          推薦的講師：
        </h5>
        <p class="break-words">
          {{ wish.q_lecturers }}
        </p>

        <h5
          class="mb-1 underline mt-1 text-md font-bold tracking-tight text-gray-900 dark:text-white"
        >
          推薦人信箱：
        </h5>
        <p class="break-words">
          {{ wish.q_email }}
        </p>

        <div class="text-right text-sm mt-3 col-span-2 my-3">
          來源:
          <span class="underline">{{ wish.ip }}</span>
        </div>

        <button
          @click="recovery(wish.seq)"
          style="background-color: #2db3b3"
          class="w-full text-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        >
          復原
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import _ from "lodash";
import Tag from "primevue/tag";
import Badge from "primevue/badge";
import moment from "moment";
import InputText from "primevue/inputtext";
import axios from "axios";
import { onMounted, ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";

const del_wish = ref([]);
const get_wish_card = () => {
  del_wish.value = [];
  axios
    .post(process.env.VUE_APP_api_url + "/wishlist/getwishdel", {})
    .then((response) => {
      del_wish.value = response.data;
    });
};
onMounted(() => {
  get_wish_card();
});

const searchkey = ref("");
const search = () => {
  const temp_data = JSON.parse(sessionStorage.getItem("del-wishcard"));
  if (searchkey.value) {
    const filteredData = _.filter(temp_data, (item) => {
      let hasMatch = false;
      for (const [key, value] of Object.entries(item)) {
        if (value !== null && key !== null && typeof value === "string") {
          if (value.toLowerCase().includes(searchkey.value.toLowerCase())) {
            hasMatch = true;
            break;
          }
        }
      }
      return hasMatch;
    });
    del_wish.value = filteredData;
  } else {
    del_wish.value = temp_data;
  }
};

const recovery = (wish_id) => {
  ElMessageBox.confirm("確定要復原這個願望嗎？", "復原", {
    confirmButtonText: "復原",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    axios
      .post(process.env.VUE_APP_api_url + "/wishlist/verify", {
        wish_id: wish_id,
        status: 0,
      })
      .then((response) => {
        if (response.data) {
          ElMessage({
            type: "success",
            message: "復原成功！",
          });
          get_wish_card();
        }
      });
  });
};
</script>
