export const developer = {
  userDept: "資管二乙",
  userDouble: [],
  userId: "11044248",
  userMinor: [
    {
      sub_aply_yer: "1121",
      sub_dept: "4700B",
      sub_dept_name: "資訊系",
      sub_id: "11044248",
      sub_sts: "修習",
      sub_sub: "輔",
    },
  ],
  userName: "葉絮喬",
};
