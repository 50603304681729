import AdminHome from "../components/admin/AdminHome.vue";
import LoginRecord from "../components/admin/LoginRecord.vue";
import AdminSetting from "../components/admin/AdminSetting.vue";
import DashBoard from "../components/admin/DashBoard.vue";
import AwardList from "../components/admin/awardapply/AwardList.vue";
import Guidance from "../components/admin/guidance/Index.vue";
import GuidanceClassDetail from "../components/admin/guidance/Detail.vue";
import ProLicenseVerify from "../components/admin/awardapply/prolicense/Index.vue";
import ProVerifyDetail from "../components/admin/awardapply/prolicense/Detail.vue";
import Wishlist from "../components/admin/wishlist/Index.vue";
import CourseFeedback from "../components/admin/feedback/Index.vue";

export const admin_route = {
  path: "/sladmin",
  name: "AdminHome",
  component: AdminHome,
  children: [
    {
      path: "/sladmin",
      name: "DashBoard",
      component: DashBoard,
    },
    {
      path: "/sladmin/awardlist",
      name: "AwardList",
      component: AwardList,
    },
    {
      path: "/sladmin/loginrecord",
      name: "LoginRecord",
      component: LoginRecord,
    },
    {
      path: "/sladmin/guidance",
      name: "Guidance",
      component: Guidance,
    },
    {
      path: "/sladmin/guidance/:id",
      name: "GuidanceDetail",
      component: GuidanceClassDetail,
    },
    {
      path: "/sladmin/feedback/course",
      name: "CourseFeedback",
      component: CourseFeedback,
    },
    {
      path: "/sladmin/setting",
      name: "Setting",
      component: AdminSetting,
    },
    {
      path: "/sladmin/awardlist/prolicense/",
      name: "ProLicenseVerify",
      component: ProLicenseVerify,
    },
    {
      path: "/sladmin/awardlist/prolicense/:id",
      name: "ProVerifyDetail",
      component: ProVerifyDetail,
    },
    {
      path: "/sladmin/wishlist",
      name: "Wishlist",
      component: Wishlist,
    },
  ],
};
