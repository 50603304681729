<template>
  <div id="loader" class="loader loader-border is-active">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../../assets/Spin-1s-200px.gif" />
        </div>
        <p class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>
  <ApplyListPage :changePageIndex="changePageIndex" v-if="pageIndex == 0"></ApplyListPage>
  <InfoPage :changePageIndex="changePageIndex" v-else-if="pageIndex == 1"></InfoPage>
  <ApplyPage :changePageIndex="changePageIndex" v-else-if="pageIndex == 2"></ApplyPage>
</template>

<script>
import InfoPage from "./prolicense/ApplyNotice.vue";
import ApplyPage from "./prolicense/Apply.vue";
import ApplyListPage from "./prolicense/ApplyList.vue";
import { onMounted, ref } from "vue";

export default {
  components: { InfoPage, ApplyPage, ApplyListPage },
  setup() {
    var pageIndex = ref(0);
    const changePageIndex = (v) => {
      pageIndex.value = v;
      sessionStorage.setItem("AwardLevel", pageIndex.value);
    };

    onMounted(() => {
      setTimeout(() => {
        const loader = document.getElementById("loader");
        loader.classList.remove("is-active");
      }, "1000");
    });

    return { pageIndex, changePageIndex };
  },
};
</script>

<style>
#awardInfo {
  background: rgb(221, 221, 221);
  padding: 20px;
}
</style>
