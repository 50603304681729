<template>
  <div class="flex bg-white">
    <div class="border rounded-lg text-white p-5 m-5 mt-5 text-left" style="width:70%;background-color: #2db3b3">
      <div class="widget widget-info flex" style="padding-bottom: 0">
        <h3 class="widget-title">課程資訊 Class Information</h3>
      </div>
      <button type="button" id="btn_edit"
        class="text-black bg-gray-200 hover:bg-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        @click="switchlock()">編輯課程</button>
      <button type="button" id="btn_save"
        class="hide text-white bg-red-600 hover:bg-red-500 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        @click="switchlock()">儲存課程</button>
      <div class="grid gap-6 p-5 lg:grid-cols-3">
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>開課系級</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="clsDetail.department" :disabled="lockinput" required />
        </div>
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>課程名稱</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="clsDetail.className" :disabled="lockinput" required />
        </div>
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>開課教授</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="clsDetail.classTeacher" :disabled="lockinput" required />
        </div>
      </div>
      <div class="grid gap-6 p-5 lg:grid-cols-2">
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>提供預約時間(一)</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="weekTimePlace.first" :disabled="lockinput" required />
        </div>
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>提供預約時間(二)</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="weekTimePlace.second" :disabled="lockinput" required />
        </div>
      </div>
      <div class="widget widget-info flex mt-3" style="padding-bottom: 0">
        <h3 class="widget-title">課業守護天使資訊 Guidance Information</h3>
      </div>
      <div class="grid gap-6 p-5 pt-0 lg:grid-cols-2">
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>課守學號</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="clsDetail.tutorId" :disabled="lockinput" required />
        </div>
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>課守姓名</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="clsDetail.tutorName" :disabled="lockinput" required />
        </div>
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>課守電話</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="clsDetail.tutorPhone" :disabled="lockinput" required />
        </div>
        <div>
          <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
              style="color: red"> * </span>課守信箱</label>
          <input type="text" id="stuName" name="stuName"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            v-model="clsDetail.tutorEmail" :disabled="lockinput" required />
        </div>
      </div>
    </div>
    <div class="mt-4 border rounded-lg text-black p-5 m-5 text-left"
      style="width:100%;background-color: rgb(207, 207, 207)">
      <div class="widget widget-info" style="padding-bottom: 0">
        <h3 class="widget-title-black" style="color: #000 !important">
          預約清單 Reserve List
        </h3>
      </div>

      <div class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert"
        v-if="clsDetail.status == 1 && lockinput">
        <span class="text-xl">提醒：該課程目前預約狀態為：<b class="font-bold">關閉預約</b></span>
      </div>
      <div class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
        role="alert" v-if="clsDetail.status == 0 && lockinput">
        <span class="text-xl">提醒：該課程目前預約狀態為：<b class="font-bold">開放預約</b></span>
      </div>

      <el-tooltip content="點擊編輯預約狀態" placement="top" v-if="clsDetail.status == 1 && !lockinput">
        <div class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert"
          @click="updatestate(0)">
          <span class="text-xl">提醒：該課程目前預約狀態為：<b class="font-bold">關閉預約</b></span>
        </div>
      </el-tooltip>
      <el-tooltip content="點擊編輯預約狀態" placement="top" v-else-if="clsDetail.status == 0 && !lockinput">
        <div class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
          role="alert" @click="updatestate(1)">
          <span class="text-xl">提醒：該課程目前預約狀態為：<b class="font-bold">開放預約</b></span>
        </div>
      </el-tooltip>


      <DataTable :value="reserveList" :paginator="true" :rows="5"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown w-full"
        responsiveLayout="scroll" currentPageReportTemplate="顯示 {first} 至  {last} 筆 | 共 {totalRecords} 筆">
        <Column field="count" header="#" headerStyle="width:15%"></Column>
        <Column field="stuName" header="學生姓名" headerStyle="width:20%"></Column>
        <Column field="ReserveDate" header="預約日期" headerStyle="width:20%"></Column>
        <Column field="ReserveTime" header="預約時段" headerStyle="width:20%"></Column>
        <Column field="place" header="預約地點" headerStyle="width:15%"></Column>
        <Column field="status" header="狀態" style="width: 15%">
          <template #body="{ data }">
            <span v-if="data.status == 0"
              class="bg-blue-100 text-blue-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-blue-200 dark:text-blue-800">
              <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                  clip-rule="evenodd"></path>
              </svg>
            </span>

            <span v-else-if="data.status == 1"
              class="bg-green-100 text-green-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-green-200 dark:text-green-800">
              <i class="pi pi-check"></i>
            </span>

            <span v-else-if="data.status == 2"
              class="bg-red-100 text-red-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-red-200 dark:text-red-800">
              <i class="pi pi-times"></i>
            </span>

            <span v-else-if="data.status == 3"
              class="bg-yellow-400 text-white text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-red-200 dark:text-red-800">
              <i class="pi pi-times-circle"></i>
            </span>

            <span v-else-if="data.status == -1"
              class="bg-red-100 text-red-800 text-sm font-semibold inline-flex items-center p-1.5 rounded-full dark:bg-red-200 dark:text-red-800">
              <i class="pi pi-times"></i>
            </span>
          </template>
        </Column>
        <Column header="" style="width: 5%">
          <template #body="slotProps">
            <div v-if="slotProps.data.status == 0">
              <button type="button" @click="openReplyWindow(slotProps.data, slotProps.index)"
                class="text-white reserveReplybutton font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2">
                <i class="pi pi-reply py-1"></i>
              </button>
            </div>
            <div v-else>
              <button @click="openReplyWindow(slotProps.data, slotProps.index)" type="button"
                class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                <i class="pi pi-eye py-1"></i>
              </button>
            </div>
          </template>
        </Column>
        <template #empty> 無預約紀錄 </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { pushModal } from "jenesius-vue-modal";
import { onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import replyWindow from "../../pages/guidance/venue/Reply.vue";

export default {
  components: {
    DataTable,
    Column
  },
  props: {
    seq: String
  },
  setup(props) {
    const clsDetail = ref({});
    const reserveList = ref([]);
    const lockinput = ref(true);

    const openReplyWindow = async (data, index) => {
      const modal = await pushModal(replyWindow, {
        index: index + 1,
        data,
      });

      modal.onclose = () => {
        if (sessionStorage.getItem("reply")) {
          reserveList.value = []
          axios
            .post(process.env.VUE_APP_api_url + "/guidance/reserveList", {
              subject: clsDetail.value.className,
            })
            .then((res) => {
              sessionStorage.removeItem("reply")
              res.data.forEach((e) => {
                reserveList.value.push(e);
              })
            });
        }
      }
    };

    const check = ref(true);
    const weekTimePlace = ref({ first: "", second: "" })
    onMounted(() => {
      axios
        .post(process.env.VUE_APP_api_url + "/guidance/clsDetail", {
          id: props.seq,
        })
        .then((response) => {
          clsDetail.value = response.data[0];
          weekTimePlace.value.first = clsDetail.value.consultTime1 + "(" + clsDetail.value.place + ")"

          if (clsDetail.value.consultTime2 != null) {
            weekTimePlace.value.second = clsDetail.value.consultTime2 + "(" + clsDetail.value.place2 + ")"
          }
          if (clsDetail.value.status != 0) {
            check.value = false;
          }

          axios
            .post(process.env.VUE_APP_api_url + "/guidance/reserveList", {
              subject: clsDetail.value.className,
            })
            .then((res) => {
              let reservecount = 1;
              res.data.forEach((e) => {
                e.count = reservecount;
                reserveList.value.push(e);
                reservecount += 1;
              })
            });
        })
    });

    const goBack = () => {
      history.back(-1)
    };

    const switchlock = () => {
      if (lockinput.value) {
        document.getElementById("btn_edit").classList.add("hide");
        document.getElementById("btn_save").classList.remove("hide");
      } else {
        const regex = /^\((.+?)\)(\d{4}-\d{4})\((.+)\)$/;
        // 使用正則表達式進行匹配
        let consultInfo1 = weekTimePlace.value.first.match(regex);
        let consultInfo2 = weekTimePlace.value.second.match(regex);

        clsDetail.value.consultTime1 = "(" + consultInfo1[1] + ")" + consultInfo1[2]
        clsDetail.value.consultTime2 = "(" + consultInfo2[1] + ")" + consultInfo2[2]
        clsDetail.value.place = consultInfo1[3]
        clsDetail.value.place2 = consultInfo2[3]

        axios.post(process.env.VUE_APP_api_url + "/guidance/updateClass", clsDetail.value).then((response) => {
          if (response.status == 200) {
            ElMessage({
              message: '儲存成功', type: 'success',
            })
            sessionStorage.setItem("reloadlist", true)
          }
        })

        document.getElementById("btn_edit").classList.remove("hide");
        document.getElementById("btn_save").classList.add("hide");
      }
      lockinput.value = !lockinput.value;
    }

    const updatestate = (status) => {
      clsDetail.value.status = status;
      axios
        .post(process.env.VUE_APP_api_url + "/guidance/updateState", {
          status: status,
          seq: clsDetail.value.seq,
        })
        .then((response) => {
          if (response.status == 200) {
            ElMessage({
              message: '更改成功', type: 'success',
            })
            sessionStorage.setItem("reloadlist", true)
          }
        })
    }

    return { goBack, reserveList, clsDetail, openReplyWindow, check, weekTimePlace, lockinput, switchlock, updatestate };
  },
};
</script>
