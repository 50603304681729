import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import HighchartsVue from "highcharts-vue";
import stylesheet from "./assets/style.css";
import styleGroup from "./assets/style-group.css";
import Loading from "vue-loading-overlay";
import { jenesius } from "jenesius-vue-modal";
import "flowbite";
import PrimeVue from "primevue/config";
import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "./assets/css-loader.css";
import JsonExcel from "vue-json-excel";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import axios from "axios";

import { developer } from "./utils/developer";
if (process.env.VUE_APP_DEVELOP_MODE) {
  sessionStorage.setItem("adminId", JSON.stringify(developer.userId));
  sessionStorage.setItem("stuInfo", JSON.stringify(developer));
  if (!localStorage.getItem("jwt-token")) {
    axios
      .get(process.env.VUE_APP_api_url + "/login/registerdev")
      .then((response) => {
        localStorage.setItem("jwt-token", response.data.token);
      });
  }
}

axios.defaults.baseURL = process.env.VUE_APP_api_url;
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("jwt-token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const app = createApp(App);
app.config.globalProperties.$axios = axios;

app.use(ElementPlus);
app.use(JsonExcel);
app.use(PrimeVue);
app.use(jenesius);
app.use(store);
app.use(Loading);
app.use(router);
app.use(HighchartsVue);
app.use(stylesheet);
app.use(styleGroup);

app.mount("#app");
