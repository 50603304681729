<template>
    <div class="w-full p-3 bg-main-green grid grid-cols-2" style="min-height: 100%; grid-template-columns: 0.5fr 2.5fr;">
        <div class="p-6 bg-basic-100 rounded-l-xl"
            style="box-shadow: rgba(0, 0, 0, 0.10) 1px 0px 6px 1px; z-index: 1; min-height: 100%; min-width:100px;">
            <div class="p-2 px-4 text-2xl text-main-green bg-white font-bold rounded-2xl shadow-md">
                <div>同儕精進讀書會</div>
                <div>Study Group</div>
            </div>
            <hr class="my-6 border-basic-300">
            <div @click="changePageIndex(0)"
                class="mb-6 bg-white text-basic-600 hover:text-basic-400 cursor-pointer text-xl rounded-2xl font-bold p-4 flex flex-col justify-center shadow-md"
                :class="{ PageActive: pageIndex == 0 }">

                <i class="pi pi-pencil mb-2" style="font-size: 40px;"></i>
                <div>申請簽核</div>
            </div>
            <div @click="changePageIndex(1)"
                class="mb-6 bg-white text-basic-600 hover:text-basic-400 cursor-pointer text-xl rounded-2xl font-bold p-4 flex flex-col justify-center shadow-md"
                :class="{ PageActive: pageIndex == 1 }">

                <i class="pi pi-pencil mb-2" style="font-size: 40px;"></i>
                <div>申請查詢</div>
            </div>
            <div @click="changePageIndex(2)"
                class="mb-6 bg-white text-basic-600 hover:text-basic-400 cursor-pointer text-xl rounded-2xl font-bold p-4 flex flex-col justify-center shadow-md"
                :class="{ PageActive: pageIndex == 2 }">

                <i class="pi pi-pencil mb-2" style="font-size: 40px;"></i>
                <div>指導成果查詢</div>
            </div>
            <div
                class="mb-6 p-4 px-6 bg-white text-basic-600 text-lg rounded-2xl font-bold flex flex-col justify-center gap-3 shadow-md">
                <img class="m-auto" src="../../../assets/group/teacher/user.png" width="52" alt="">
                <div>XXX老師 您好！</div>
                <button class="rounded-lg bg-main-green hover:bg-main-second text-white font-bold p-1">登出</button>
            </div>
        </div>
        <Review v-if="pageIndex == 0"></Review>

    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
// import axios from "axios";
import Review from './Review.vue';

export default {
    components: {
        Review,
    },

    setup() {
        const pageIndex = ref(0);
        const changePageIndex = (pIndex) => {
            pageIndex.value = pIndex
        }
        onMounted(() => {

        });

        return {
            pageIndex,
            changePageIndex,
        };

    },
}


</script>

<style scoped>
.PageActive {
    background-color: #767676;
    color: white;
    cursor: auto;
    pointer-events: none;
}
</style>
