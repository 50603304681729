<template>
    <div class="p-6 mr-6 mb-6 bg-white rounded-2xl shadow-lg">
        <div class="p-2 mb-6 bg-basic-100 rounded-2xl">
            <div class="flex">
                <input type="button" value="執行表繳交" @click="changePageIndex(0)" :class="{ groupActive: pageIndex == 0 }"
                    class="duration-300	text-lg p-2 mx-2 w-full rounded-sm border-0 py-1 bg-white text-main-primary font-black hover:bg-white text-main-second cursor-pointer"
                    style="border-radius:20px;">
                <input type="button" value="自評表" @click="changePageIndex(1)" :class="{ groupActive: pageIndex == 1 }"
                    class="duration-300	text-lg p-2 mx-2 w-full rounded-sm border-0 py-1 bg-white text-main-primary font-black hover:bg-white text-main-second cursor-pointer"
                    style="border-radius:20px;">
                <input type="button" value="心得分享" @click="changePageIndex(2)" :class="{ groupActive: pageIndex == 2 }"
                    class="duration-300	text-lg p-2 mx-2 w-full rounded-sm border-0 py-1 bg-white text-main-primary font-black hover:bg-white text-main-second cursor-pointer"
                    style="border-radius:20px;">
                <input type="button" value="滿意度問卷" @click="changePageIndex(3)" :class="{ groupActive: pageIndex == 3 }"
                    class="duration-300	text-lg p-2 mx-2 w-full rounded-sm border-0 py-1 bg-white text-main-primary font-black hover:bg-white text-main-second cursor-pointer"
                    style="border-radius:20px;">
                <input type="button" value="獎金分配" @click="changePageIndex(4)" :class="{ groupActive: pageIndex == 4 }"
                    class="duration-300	text-lg p-2 mx-2 w-full rounded-sm border-0 py-1 bg-white text-main-primary font-black hover:bg-white text-main-second cursor-pointer"
                    style="border-radius:20px;">
            </div>
        </div>
        <ProgressWork v-if="pageIndex == 0"></ProgressWork>
        <SelfEval v-else-if="pageIndex == 1"></SelfEval>
        <Reflection v-else-if="pageIndex == 2"></Reflection>
        <Satisfaction v-else-if="pageIndex == 3"></Satisfaction>
        <Money v-else-if="pageIndex == 4"></Money>
    </div>
</template>
<script>
import { ref } from 'vue';
import ProgressWork from "./upload/ProgressWork.vue"
import SelfEval from "./upload/SelfEval.vue"
import Reflection from "./upload/Reflection.vue"
import Satisfaction from './upload/Satisfaction.vue';
import Money from './upload/Money.vue';

export default {
    components: {
        ProgressWork,
        SelfEval,
        Reflection,
        Satisfaction,
        Money,
    },
    setup() {
        const pageIndex = ref(0);
        const changePageIndex = (pIndex) => {
            pageIndex.value = pIndex
        }
        return { pageIndex, changePageIndex }
    }
}
</script>