<template>
    <div class="p-2 px-4 text-lg text-basic-500 bg-white font-bold rounded-2xl shadow-lg">
        <div class="flex align-center">
            <div v-for="g in group" :key="g.id" class="m-auto ml-0">隊名：{{ g.group_name }}</div>
            <a href="/group/login">
                <input @click="logout()" type="button" value="登出"
                    class="text-lg m-auto w-24 rounded-md border-0 py-1 bg-main-primary text-white font-black hover:bg-main-second cursor-pointer"
                    style="border-radius:20px;">
            </a>
        </div>
        <div v-for="stu in student" :key="stu.id" class="flex align-center">
            <div class="m-auto ml-0">系級：{{ stu.dept }}</div>
            <div class="m-auto ml-0">學號：{{ stu.id }}</div>
            <div class="ml-0">姓名：{{ stu.name }}</div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";
import axios from "axios";
export default {
    setup() {
        const student = ref([])
        const group = ref([])

        onMounted(() => {
            const id = JSON.parse(sessionStorage.getItem("stuId"))
            console.log(id);
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getmember")
                .then((response) => {
                    const allmem = response.data;
                    student.value = allmem.filter((stu) => stu.id == id);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getgroup")
                .then((response) => {
                    const allgroup = response.data;
                    group.value = allgroup.filter((group) => group.id == student.value[0].group_id);
                    sessionStorage.setItem("groupId", JSON.stringify(group.value[0].id));
                    console.log(group);
                    console.log(sessionStorage.getItem("groupId"));
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        const logout = () => {
            sessionStorage.removeItem("stuId");
            sessionStorage.removeItem("groupId");
        }

        return {
            student,
            group,
            logout,
        }
    }
}
</script>