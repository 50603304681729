<template>
    <DataTable class="p-5" :value="reserveData" :paginator="true" :rows="10" :filters="filters"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        responsiveLayout="scroll" currentPageReportTemplate="">
        <Column field="count" header="序號" sortable></Column>
        <Column field="subject" header="課程名稱"></Column>
        <Column field="ReserveDate" header="預約日期"></Column>
        <Column field="ReserveTime" header="預約時間"></Column>
        <Column header="狀態">
            <template #body="{ data }">
                <span v-if="data.status == 0" class="bg-blue-500 px-3 py-1.5 rounded-full text-white font-bold">等待回覆</span>
                <span v-else-if="data.status == 1"
                    class="bg-green-500 px-3 py-1.5 rounded-full text-white font-bold">預約成功</span>
                <span v-else-if="data.status == 2"
                    class="bg-gray-500 px-3 py-1.5 rounded-full text-white font-bold">無法預約</span>
                <span v-else-if="data.status == 3"
                    class="bg-yellow-400 px-3 py-1.5 rounded-full text-white font-bold">預約失敗</span>
            </template>
        </Column>
        <Column header="#" headerStyle="width: 10rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
            <template #body="{ data }">
                <button type="button" @click="cancelReserve(data.seq)" v-if="data.status == 0"
                    class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                    取消預約
                </button>
                <button type="button" @click="renderModel(data)" v-else
                    class="text-white reserveReplybutton font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 ">
                    課守回覆
                </button>
            </template>
        </Column>
        <template #paginatorstart></template>
        <template #paginatorend></template>
    </DataTable>
    <widget-container-modal />
</template>

<script>
import axios from "axios"
import reserveReply from "./ReserveReply.vue"
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { onMounted, ref } from '@vue/runtime-core'
import { openModal, container } from "jenesius-vue-modal";

export default {
    components: {
        WidgetContainerModal: container,
        DataTable,
        Column,
    },
    setup() {
        const stuInfo = ref({})
        const reserveData = ref([])

        onMounted(() => {
            stuInfo.value = JSON.parse(sessionStorage.getItem("stuInfo"))
            axios.post(process.env.VUE_APP_api_url + "/guidance/stuReserve", {
                stuId: stuInfo.value.userId
            }).then((response) => {
                let count = 1;
                response.data.forEach((e) => {
                    e.count = count
                    reserveData.value.push(e)
                    count += 1
                })
            })
        })

        const cancelReserve = (seq) => {
            var yes = confirm("確定要取消嗎？")

            if (yes) {
                axios.post(process.env.VUE_APP_api_url + "/guidance/cancel", {
                    seq: seq
                }).then((response) => {
                    if (response.status == 200) {
                        alert("取消成功")
                        location.reload()
                    }
                })
            }

        }

        const renderModel = (data) => {
            openModal(reserveReply, { reserveDetail: data });
        }

        return { reserveData, cancelReserve, renderModel }
    }
}
</script>