<template>
  <div id="loader" class="loader loader-border is-active">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../assets/Spin-1s-200px.gif" />
        </div>
        <p class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>
  <ReserveNotice v-if="!IsVenue" />
  <ReserveCheck v-else-if="IsVenue" />
</template>

<script>
import { ref, onMounted, computed } from "@vue/runtime-core";
import ReserveNotice from "./guidance/ReserveNotice.vue";
import ReserveCheck from "./guidance/venue/Check.vue";
import axios from "axios";
import store from "../../store";

export default {
  components: {
    ReserveNotice,
    ReserveCheck,
  },
  setup() {
    const IsVenue = computed(() => {
      return store.state.IsVenue;
    });

    const stuInfo = ref({});
    onMounted(() => {
      stuInfo.value = JSON.parse(sessionStorage.getItem("stuInfo"));
      axios
        .post(process.env.VUE_APP_api_url + "/guidance/checkVenue", {
          userId: stuInfo.value.userId,
        })
        .then((response) => {
          if (response.data) {
            store.commit("IsVenue");
          } else {
            store.commit("NotVenue");
          }
        });
    });

    setTimeout(() => {
      const loader = document.getElementById("loader");
      loader.classList.remove("is-active");
    }, "1000");

    return { IsVenue };
  },
};
</script>
