<template>
    <div class="w-full h-full my-0 p-1" style="z-index: -20;">
        <div class="m-1 p-1 bg-basic-200 rounded-2xl" style="z-index: -10; max-width: 1060px; margin: auto;">

            <div class="grid grid-cols-2 gap-6 auto-cols-min grid-rows-none grid-cols-none"
                style="grid-template-columns: 1.6fr 4fr;">
                <div class="mt-6 ml-6">
                    <div class="p-2 px-4 text-2xl text-main-primary bg-white font-bold rounded-2xl shadow-lg">
                        <div>同儕精進讀書會<br>Study Group</div>
                    </div>
                </div>
                <div class="mt-6 mr-6">
                    <MyInfo></MyInfo>
                </div>
                <div class="flex row-span-2">
                    <div class="ml-6 mb-6">
                        <div class="p-2 px-4 bg-white font-bold rounded-2xl shadow-lg">
                            <TimeInfo></TimeInfo>
                        </div>

                        <div class="mt-6 p-2 px-4 bg-white font-bold rounded-2xl shadow-lg">

                            <div class="m-3 grid grid-cols-2 gap-6">
                                <div>
                                    <div>報名申請<br>初審結果</div>
                                    <div class="flex justify-center">
                                        <img src="../../../assets/group/big-pass.png" alt="">
                                    </div>
                                </div>
                                <!-- <div class="bg-basic-200" style="width:2px;"></div> -->
                                <div>
                                    <div>指導老師<br>同意狀況</div>
                                    <div class="flex justify-center">
                                        <img src="../../../assets/group/big-pass.png" alt="">
                                    </div>
                                </div>
                            </div>
                            <MyProgress></MyProgress>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="p-2 mr-6 mb-6 bg-white rounded-2xl shadow-lg">
                        <div class="flex">
                            <input type="button" value="資料繳交" @click="changePageIndex(0)"
                                :class="{ groupActive: pageIndex == 0 }"
                                class="duration-300 text-lg p-2 mx-2 w-full rounded-sm border-0 py-1 bg-basic-200 text-basic-600 font-black hover:bg-basic-100 cursor-pointer"
                                style="border-radius:20px;">
                            <input type="button" value="繳交進度" @click="changePageIndex(1)"
                                :class="{ groupActive: pageIndex == 1 }"
                                class="duration-300 text-lg p-2 mx-2 w-full rounded-sm border-0 py-1 bg-basic-200 text-basic-600 font-black hover:bg-basic-100 cursor-pointer"
                                style="border-radius:20px;">
                            <input type="button" value="資料查詢" @click="changePageIndex(2)"
                                :class="{ groupActive: pageIndex == 2 }"
                                class="duration-300 text-lg p-2 mx-2 w-full rounded-sm border-0 py-1 bg-basic-200 text-basic-600 font-black hover:bg-basic-100 cursor-pointer"
                                style="border-radius:20px;">
                        </div>
                    </div>
                    <HandOver v-if="pageIndex == 0"></HandOver>
                    <TeamProgress v-else-if="pageIndex == 1"></TeamProgress>
                    <DataCheck v-else-if="pageIndex == 2"></DataCheck>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import HandOver from "./HandOver.vue"
import TeamProgress from './TeamProgress.vue';
import MyProgress from './MyProgress.vue'
import TimeInfo from './TimeInfo.vue'
import MyInfo from './MyInfo.vue'
import DataCheck from './DataCheck.vue'

export default {
    components: {
        HandOver,
        TeamProgress,
        MyProgress,
        TimeInfo,
        MyInfo,
        DataCheck,
    },
    setup() {
        const pageIndex = ref(0);
        const changePageIndex = (pIndex) => {
            pageIndex.value = pIndex
        }
        return { pageIndex, changePageIndex }
    }
}
</script>