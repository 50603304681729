<template>
    <div class="flex text-center">
        <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
        <div class="text-2xl font-black">
            心得分享
        </div>
    </div>
    <div class="mt-6">
        <!-- <ul class="ml-6 text-left list-disc">
            <li>組內<span class="text-red-400 font-bold">所有成員</span>皆須填寫。</li>
        </ul> -->
        <ul class="ml-6 text-left list-disc">
            <li v-for="notice in notices" :key="notice.order" v-html="notice.content"></li>
        </ul>
        <div class="my-4 p-3 bg-basic-100 rounded-xl shadow-md">
            <div class="m-auto flex">
                <img class="mr-2 h-7" src="../../../../assets/group/pencil.png" alt="">
                <div class="bg-white rounded-xl flex grow" style="flex-grow:1;">
                    <div class="mr-auto p-1 px-2 flex text-basic">
                        心得分享
                    </div>
                </div>
            </div>
            <div>
                <textarea v-model="ref_data.reflection" name="" id="" class="w-full h-72 rounded-lg mt-3"></textarea>
            </div>
        </div>

        <div class="flex gap-4">
            <button class="w-full rounded-xl mt-3 p-1 bg-mark-orange text-white">暫存</button>
            <button @click="submitRef()" class="w-full rounded-xl mt-3 p-1 bg-main-second text-white">送出</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
    setup() {
        const notices = ref([])
        const ref_data = ref({
            stuId: JSON.parse(sessionStorage.getItem("stuId")).toString(),
            sem: JSON.parse(sessionStorage.getItem("sem")),
            reflection: "",
            groupId: JSON.parse(sessionStorage.getItem("groupId")).toString(),
        })
        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
                .then((response) => {
                    const allNotices = response.data;
                    notices.value = allNotices.filter((notice) => notice.type === "心得分享");
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        const submitRef = () => {

            axios.post(process.env.VUE_APP_api_url + "/studygroup/updatereflection", ref_data.value).then((response) => {
                if (response.data.insert == "finished") {
                    alert("新增成功")
                    location.reload()
                }
            })

        }

        return {
            notices,
            ref_data,
            submitRef,
        };
    },
};
</script>
