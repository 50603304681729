<template>
    <div class="w-full h-full my-0 p-1" style="z-index: -20;">
        <div class="m-1 p-1 rounded-2xl" style="z-index: -10; max-width: 1060px; margin: auto;">

            <div class="m-3 grid grid-cols-2 gap-6 items-start" style="grid-template-columns: 0.7fr 2fr;">

                <div class="grid gap-6">
                    <div class="p-2 px-4 bg-basic-100 font-bold rounded-2xl shadow">
                        <div class="w-full text-center">
                            <img src="../../../assets/group/user.png" width="54" height="54" class="my-4 m-auto" alt="">
                        </div>
                        <div class=" text-gray-600 text-lg flex items-center">
                            <div class="w-max">帳號</div>
                            <input v-model="UserNm" type="text" name="UserNm"
                                class="ml-2 py-1.5 text-gray-900 border-0 rounded-xl shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                                placeholder="同itouch帳號" />
                        </div>

                        <div class="mt-3 text-lg text-gray-600 flex items-center">
                            <div class="w-max">密碼</div>
                            <input v-model="UserPasswd" type="text" name="UserPasswd"
                                class="ml-2 rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                        </div>

                        <div>
                            <button @click="loginbtn()"
                                class="w-full rounded-xl mt-3 p-1 bg-main-second text-white font-bold">學生登入</button>
                        </div>
                    </div>
                    <div class="p-4 bg-basic-100 rounded-2xl shadow">
                        <div class="m-2 text-lg font-black text-black">注意事項</div>
                        <div class="p-2 px-4 bg-white text-left font-bold rounded-2xl">
                            <div v-for="notice in notices" :key="notice.order" class="py-2 " v-html="notice.content"></div>
                        </div>
                    </div>
                </div>

                <div class="p-4 bg-basic-100 rounded-2xl shadow">
                    <div class="grid gap-4">
                        <div v-for="sem in semester" :key="sem.order"
                            class="p-2 bg-white rounded-xl font-bold text-main-primary text-xl">
                            中原大學 {{ sem.content }} 同儕精進讀書會
                        </div>

                        <div class="p-6 bg-white rounded-2xl shadow-lg">
                            <div class="flex text-center">
                                <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
                                <div class="text-xl font-black">
                                    表單填寫
                                </div>
                            </div>
                            <div class="mt-6 grid grid-cols-2 grid-flow-row gap-6">
                                <a class="col-span-2 flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer"
                                    href="/group/apply">
                                    <div class="m-auto p-4 text-xl font-bold">
                                        <div>報名申請</div>
                                        <div class="h-20 flex items-center">
                                            <img src="../../../assets/group/apply.png" alt="" class="m-auto w-16">
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="p-6 bg-white rounded-2xl shadow-lg">
                            <div class="flex text-center">
                                <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
                                <div class="text-xl font-black">
                                    讀書會規範及歷年資料
                                </div>
                            </div>
                            <div class="mt-6 grid grid-cols-2 grid-flow-row gap-6">
                                <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer"
                                    href="/group">
                                    <div class="m-auto p-4 text-xl font-bold">
                                        <div>同儕精進讀書會實施專案</div>
                                        <div class="h-20 flex items-center">
                                            <img src="../../../assets/group/ruling.png" alt="" class="m-auto w-16">
                                        </div>
                                    </div>
                                </a>
                                <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer"
                                    href="/group">
                                    <div class="m-auto p-4 text-xl font-bold">
                                        <div>同儕精進讀書會作業規範</div>
                                        <div class="h-20 flex items-center">
                                            <img src="../../../assets/group/rules.png" alt="" class="m-auto w-16">
                                        </div>
                                    </div>
                                </a>
                                <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer"
                                    href="/group">
                                    <div class="m-auto p-4 text-xl font-bold">
                                        <div> 讀書會Q&A</div>
                                        <div class="h-20 flex items-center">
                                            <img src="../../../assets/group/qa.png" alt="" class="m-auto w-16">
                                        </div>
                                    </div>
                                </a>
                                <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer"
                                    href="/group">
                                    <div class="m-auto p-4 text-xl font-bold">
                                        <div>歷年通過審核資料賞析</div>
                                        <div class="h-20 flex items-center">
                                            <img src="../../../assets/group/history.png" alt="" class="m-auto w-16">
                                        </div>
                                    </div>
                                </a>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
export default {
    setup() {
        const semester = ref([])
        const notices = ref([])
        const UserNm = ref("")

        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
                .then((response) => {
                    const allNotices = response.data;
                    semester.value = allNotices.filter((notice) => notice.type === "學期");
                    sessionStorage.setItem("sem", JSON.stringify(semester.value[0].content));
                    console.log(JSON.parse(sessionStorage.getItem("sem")));
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
                .then((response) => {
                    const allNotices = response.data;
                    notices.value = allNotices.filter((notice) => notice.type === "申請注意事項");
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        const loginbtn = () => {
            sessionStorage.setItem("stuId", JSON.stringify(UserNm.value));
            console.log(JSON.parse(sessionStorage.getItem("stuId")));
            window.location.href = "/group";
        }

        return {
            semester,
            notices,
            UserNm,
            loginbtn,
        }
    }
}
</script>
