<template>
  <div id="loader" class="loader loader-border is-active">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../../assets/Spin-1s-200px.gif" />
        </div>
        <p class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>
  <div class="StudentProgram">
    <div class="program">
      <h1 class="title">就業學程</h1>
      <table class="program-table">
        <tr class="header-table">
          <th style="width: 15%">編號</th>
          <th style="width: 10%">申請學年</th>
          <th style="width: 50%">名稱</th>
          <th style="width: 25%">修習狀態</th>
        </tr>
        <td v-if="userJob.length == 0" colspan="4">無資料</td>
        <tr v-for="(item, index) in userJob" :key="item.sub_dept">
          <td>{{ index + 1 }}</td>
          <td>{{ item.sub_aply_yer }}</td>
          <td>{{ item.sub_dept_name }}</td>
          <td>{{ item.sub_sts }}</td>
        </tr>
      </table>
    </div>

    <div class="program">
      <h1 class="title">微型學程</h1>
      <table class="program-table">
        <tr class="header-table">
          <th style="width: 15%">編號</th>
          <th style="width: 10%">申請學年</th>
          <th style="width: 50%">名稱</th>
          <th style="width: 25%">修習狀態</th>
        </tr>

        <td v-if="userMicro.length == 0" colspan="4">無資料</td>

        <tr v-for="(item, index) in userMicro" :key="item.sub_dept">
          <td>{{ index + 1 }}</td>
          <td>{{ item.sub_aply_yer }}</td>
          <td>{{ item.sub_dept_name }}</td>
          <td>{{ item.sub_sts }}</td>
        </tr>
      </table>
    </div>

    <div class="program">
      <h1 class="title">跨領域學程</h1>
      <table class="program-table">
        <tr class="header-table">
          <th style="width: 15%">編號</th>
          <th style="width: 10%">申請學年</th>
          <th style="width: 50%">名稱</th>
          <th style="width: 25%">修習狀態</th>
        </tr>

        <td v-if="userCross.length == 0" colspan="4">無資料</td>

        <tr v-for="(item, index) in userCross" :key="item.sub_dept">
          <td>{{ index + 1 }}</td>
          <td>{{ item.sub_aply_yer }}</td>
          <td>{{ item.sub_dept_name }}</td>
          <td>{{ item.sub_sts }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { onMounted, ref } from "@vue/runtime-core";

export default {
  setup() {
    const userCross = ref([]);
    const userJob = ref([]);
    const userMicro = ref([]);
    
    onMounted(() => {
      axios
        .post(process.env.VUE_APP_api_url + "/getdata/studentInfo")
        .then((d) => {
          for (let i = 0; i < d.data.stmd_sub.length; i++) {
            if (d.data.stmd_sub[i].sub_sub == "跨") {
              userCross.value.push(d.data.stmd_sub[i]);
            }
            if (d.data.stmd_sub[i].sub_sub == "就") {
              userJob.value.push(d.data.stmd_sub[i]);
            }
            if (d.data.stmd_sub[i].sub_sub == "微") {
              userMicro.value.push(d.data.stmd_sub[i]);
            }
          } 
        })
        .finally(() => {
          const loader = document.getElementById("loader");
          loader.classList.remove("is-active");
        });
    });

    return { userCross, userJob, userMicro }; 
  },
}; 
</script>
