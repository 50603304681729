<template>
    <div class="flex text-center">
        <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
        <div class="text-2xl font-black">
            獎金分配
        </div>
    </div>
    <div class="mt-6">
        <ul class="ml-6 text-left list-disc">
            <li v-for="notice in notices" :key="notice.order" v-html="notice.content"></li>
        </ul>

        <div class="my-4 p-3 bg-basic-100 rounded-xl shadow-md">
            <div class="m-auto p-2 pb-0 flex items-center">
                <div class="flex grow" style="flex-grow:1;">
                    <div class="flex items-center" style="width: 5%;"></div>
                    <div class="w-full p-1 px-2 flex text-base text-center items-center font-bold">
                        <div style="width: 10%;">身分</div>
                        <div style="width: 30%;">學系</div>
                        <div style="width: 15%;">年級</div>
                        <div style="width: 20%;">學號</div>
                        <div style="width: 18%;">姓名</div>
                    </div>
                </div>
                <div class="w-32 font-bold" style="flex-shrink: 0;">
                    分配金額
                </div>
            </div>

            <div v-for="(stu, index) in students" :key="index"
                class="my-2 m-auto p-2 bg-white rounded-xl flex items-center">
                <div class="flex grow" style="flex-grow:1;">
                    <div v-if="stu.identity === '組長'" class="flex items-center" style="width: 5%;">
                        <img src="../../../../assets/group/star-yellow.png" width="24" height="24" class="my-auto" alt="">
                    </div>
                    <div v-if="stu.identity === '組員'" class="flex items-center" style="width: 5%;">
                        <img src="../../../../assets/group/star-blue.png" width="24" height="24" class="my-auto" alt="">
                    </div>
                    <div class="w-full p-1 px-2 flex text-base text-center items-center">
                        <div style="width: 10%;">{{ stu.identity }}</div>
                        <div style="width: 30%;">{{ stu.dept }}</div>
                        <div style="width: 15%;">二年級</div>
                        <div style="width: 20%;">{{ stu.id }}</div>
                        <div style="width: 18%;">{{ stu.name }}</div>
                    </div>
                </div>
                <div style="flex-shrink: 0;">
                    <input v-model="reply.reward[index]" class="rounded-lg p-1 text-right w-32" type="text" name="" id="">
                </div>
            </div>

            <div class="my-2 m-auto p-2 flex items-center font-bold">
                <div v-if="total != 2000" class="text-left p-1 px-2 text-red-400" style="flex-grow:1;">
                    總金額須為2,000元整。
                </div>
                <div class="text-right p-1 px-2" style="flex-grow:1;">
                    合計
                </div>
                <div class="p-1 w-32 text-right"
                    style="flex-shrink: 0; border-top: 1px solid black; border-bottom: 3px double black;">
                    {{ total }}
                </div>
            </div>
        </div>

        <div class="flex gap-4">
            <button class="w-full rounded-xl mt-3 p-1 bg-mark-orange text-white font-bold">修改</button>
            <button @click="saveReward()" class="w-full rounded-xl mt-3 p-1 bg-main-second text-white font-bold">儲存</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import axios from "axios";

export default {
    setup() {
        const notices = ref([])
        const students = ref([])
        const reply = ref({
            stuId: [],
            reward: [],
        })

        const reward_data = ref({
            reply,
            sem: JSON.parse(sessionStorage.getItem("sem")),
        })

        const total = computed(() => {
            return reply.value.reward.reduce((sum, value) => sum + parseInt(value), 0);
        });

        onMounted(() => {
            const id = JSON.parse(sessionStorage.getItem("stuId"))
            console.log(id);
            const groupId = JSON.parse(sessionStorage.getItem("groupId"))
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
                .then((response) => {
                    const allNotices = response.data;
                    notices.value = allNotices.filter((notice) => notice.type === "獎金分配");
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getmember")
                .then((response) => {
                    const allmem = response.data;
                    students.value = allmem.filter((stu) => stu.group_id == groupId);
                    students.value.forEach((stu, index) => {
                        reply.value.stuId[index] = stu.id.toString();
                    });
                    console.log(students)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        const saveReward = () => {
            if (total.value == 2000) {
                axios.post(process.env.VUE_APP_api_url + "/studygroup/insertreward", reward_data.value).then((response) => {
                    if (response.data.insert == "finished") {
                        alert("新增成功")
                        location.reload()
                    }
                })
            } else {
                alert("總金額須為2,000元整")
            }
        }

        return {
            notices,
            total,
            students,
            reply,
            reward_data,
            saveReward,
        };
    },
};
</script>

