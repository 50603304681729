<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50">
    <div class="max-w-3xl w-full mx-auto p-6">
      <div class="bg-white rounded-xl shadow-lg p-6 space-y-6 relative">
        <div 
          v-if="isLoading" 
          class="absolute inset-0 bg-white/80 flex items-center justify-center rounded-xl z-50"
          style="backdrop-filter: blur(2px);"
        >
          <div class="animate-spin rounded-full h-12 w-12 border-4 border-gray-200 border-t-blue-500"></div>
        </div>
        
        <div class="space-y-4">
          <h3 class="text-xl font-semibold text-gray-800">投稿開放學期設定</h3>

          <div class="flex items-center space-x-3">
            <div class="relative flex-1">
              <input
                v-model="newSemester"
                type="text"
                class="w-full px-4 py-2.5 rounded-lg border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 outline-none"
                placeholder="請輸入學期 (例如: 1111)"
                @keyup.enter="addSemester"
              />
            </div>
            <button
              @click="addSemester"
              :disabled="isLoading"
              style="background-color: rgb(45, 179, 179)"
              class="px-6 py-2.5 text-white rounded-lg transition duration-200 flex items-center space-x-2 focus:outline-none focus:ring-4 focus:ring-green-200 dark:focus:ring-green-700 disabled:opacity-50"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              <span>新增學期</span>
            </button>
          </div>

          <div class="flex flex-wrap gap-2 mt-4">
            <div
              v-if="semesters.length === 0"
              class="w-full text-center py-4 text-gray-500"
            >
              目前尚未設定任何學期
            </div>
            <div
              v-else
              v-for="(semester, index) in semesters"
              :key="index"
              class="group flex items-center bg-gray-100 hover:bg-gray-200 rounded-lg px-4 py-2 transition duration-200"
            >
              <span class="text-gray-700">{{ formatSemester(semester) }}</span>
              <button
                @click="removeSemester(index)"
                class="ml-2 text-gray-400 hover:text-red-500 transition duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";

const newSemester = ref("");
const semesters = ref([]);
const isLoading = ref(false);

const addSemester = async () => {
  const semesterValue = newSemester.value.trim();

  if (!/^\d{4}$/.test(semesterValue)) {
    alert("請輸入正確的學期格式 (例如: 1111)");
    return;
  }

  const semester = parseInt(semesterValue.substring(3));

  if (semester !== 1 && semester !== 2) {
    alert("學期必須是 1 或 2");
    return;
  }

  if (!semesters.value.includes(semesterValue)) {
    try {
      isLoading.value = true;
      const response = await axios.post(
        process.env.VUE_APP_api_url + "/feedback/course/semester/insert",
        { semester: semesterValue }
      );
      
      if (response.data.status) {
        semesters.value.push(semesterValue);
        newSemester.value = "";
      } else {
        alert("新增學期失敗");
      }
    } catch (error) {
      console.error("新增學期時出錯:", error);
      alert("新增學期失敗");
    } finally {
      isLoading.value = false;
    }
  }
};

const removeSemester = async (index) => {
  try {
    isLoading.value = true;
    const semesterToDelete = semesters.value[index];
    const response = await axios.post(
      process.env.VUE_APP_api_url + "/feedback/course/semester/delete",
      { semester: semesterToDelete }
    );
    
    if (response.data.status) {
      semesters.value.splice(index, 1);
    } else {
      alert("刪除學期失敗");
    }
  } catch (error) {
    console.error("刪除學期時出錯:", error);
    alert("刪除學期失敗");
  } finally {
    isLoading.value = false;
  }
};

const formatSemester = (semester) => {
  const year = semester.substring(0, 3);
  const term = semester.substring(3) === "1" ? "上" : "下";
  return `${year}學年度 ${term}學期`;
};

onMounted(async () => {
  try {
    isLoading.value = true;
    const response = await axios.post(
      process.env.VUE_APP_api_url + "/feedback/course/semester"
    );
    response.data.forEach((semester) => {
      semesters.value.push(semester.semester);
    });
  } catch (error) {
    console.error("載入學期資料時出錯:", error);
  } finally {
    isLoading.value = false;
  }
});
</script>
