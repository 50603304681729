<template>
  <div class="border rounded-lg text-white p-5 m-5 mt-0 text-left" style="background-color: #2db3b3">
    <div class="widget widget-info flex" style="padding-bottom: 0">
      <h3 class="widget-title">課程資訊 Class Information</h3>
      <div class="flex-1 text-right">
        <button class="backListBtn rounded-lg text-md rounded-lg p-1.5 pl-3.5 pr-3.5" @click="GoListPage()">
          返回課程清單
        </button>
      </div>
    </div>

    <div class="grid gap-6 p-5 lg:grid-cols-3">
      <div>
        <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>開課系級</label>
        <input type="text" id="stuName" name="stuName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          v-model="clsDetail.department" disabled required />
      </div>
      <div>
        <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>課程名稱</label>
        <input type="text" id="stuName" name="stuName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          v-model="clsDetail.className" disabled required />
      </div>
      <div>
        <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>開課教授</label>
        <input type="text" id="stuName" name="stuName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          v-model="clsDetail.classTeacher" disabled required />
      </div>
    </div>

    <div class="grid gap-6 p-5 lg:grid-cols-2">
      <div>
        <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>提供預約時間(一)</label>
        <input type="text" id="stuName" name="stuName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          :value="weekTimePlace.first" disabled required />
      </div>
      <div>
        <label for="stuName" class="block mb-2 text-md font-medium text-white-900 dark:text-gray-300"><span
            style="color: red"> * </span>提供預約時間(二)</label>
        <input type="text" id="stuName" name="stuName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          v-model="weekTimePlace.second" disabled required />
      </div>
    </div>
  </div>

  <div class="mt-4 border rounded-lg text-black p-5 m-5 text-left" style="background-color: rgb(207, 207, 207)">
    <div class="widget widget-info" style="padding-bottom: 0">
      <h3 class="widget-title-black" style="color: #000 !important">
        預約清單 Reserve List
      </h3>
    </div>
    <div v-if="clsDetail.status == 1"
      class="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800" role="alert">
      <span class="text-xl">提醒：該課程目前預約狀態為：<b class="font-bold">關閉預約</b></span>
    </div>
    <div v-else class="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg dark:bg-green-200 dark:text-green-800"
      role="alert">
      <span class="text-xl">提醒：該課程目前預約狀態為：<b class="font-bold">開放預約</b></span>
    </div>
    <DataTable :value="reserveList" :paginator="true" :rows="5"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="顯示 {first} 至  {last} 筆 | 共 {totalRecords} 筆">
      <Column header="#" field="count" headerStyle="width:15%"></Column>
      <Column field="stuName" header="學生姓名" headerStyle="width:20%"></Column>
      <Column field="ReserveDate" header="預約日期" headerStyle="width:15%"></Column>
      <Column field="ReserveTime" header="預約時段" headerStyle="width:15%"></Column>
      <Column field="place" header="預約地點" headerStyle="width:20%"></Column>
      <Column field="status" header="狀態" style="width: 15%">
        <template #body="{ data }">
          <span v-if="data.status == 0"
            class="bg-blue-100 text-blue-800 text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-blue-200 dark:text-blue-800">
            <svg class="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                clip-rule="evenodd"></path>
            </svg>
            等待確認
          </span>

          <span v-else-if="data.status == 1"
            class="bg-green-100 text-green-800 text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-green-200 dark:text-green-800">
            <i class="pi pi-check mr-1"></i>
            完成預約
          </span>

          <span v-else-if="data.status == 2"
            class="bg-red-100 text-red-800 text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-red-200 dark:text-red-800">
            <i class="pi pi-times mr-1"></i>
            預約失敗
          </span>

          <span v-else-if="data.status == 3"
            class="bg-yellow-400  text-white text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-red-200 dark:text-red-800">
            <i class="pi pi-times-circle mr-1"></i>
            預約失敗
          </span>

          <span v-else-if="data.status == -1"
            class="bg-red-100 text-red-800 text-sm font-semibold inline-flex items-center p-1.5 pr-2 pl-2 rounded-full dark:bg-red-200 dark:text-red-800">
            <i class="pi pi-times mr-1"></i>
            取消預約
          </span>
        </template>
      </Column>
      <Column header="操作" style="width: 5%">
        <template #body="slotProps">
          <div v-if="slotProps.data.status == 0">
            <button type="button" @click="openReplyWindow(slotProps.data, slotProps.index)"
              class="text-white reserveReplybutton font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2">
              <i class="pi pi-reply py-1"></i>
            </button>
          </div>
          <div v-else>
            <button @click="openReplyWindow(slotProps.data, slotProps.index)" type="button"
              class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
              <i class="pi pi-eye py-1"></i>
            </button>
          </div>
        </template>
      </Column>
      <template #empty> 無預約紀錄 </template>
    </DataTable>
  </div>

  <widget-container-modal />
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { openModal, container } from "jenesius-vue-modal";
import axios from "axios";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import replyWindow from "./Reply.vue";

export default {
  components: {
    WidgetContainerModal: container,
    DataTable,
    Column,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const clsDetail = ref({});
    const reserveList = ref([]);

    const GoListPage = () => {
      router.push({
        path: "/counselor",
      });
    };

    const openReplyWindow = (data, index) => {
      openModal(replyWindow, {
        index: index + 1,
        data,
      });
    };

    const weekTimePlace = ref({ first: "", second: "" })
    const stuInfo = ref({});
    onMounted(() => {
      stuInfo.value = JSON.parse(sessionStorage.getItem("stuInfo"));
      axios
        .post(process.env.VUE_APP_api_url + "/guidance/clsDetail", {
          id: route.params.id,
        })
        .then((response) => {
          if (response.data[0].tutorId != stuInfo.value.userId) {
            alert("請勿修改參數，您非該堂課課業守護天使")
            history.back(-1)
          } else {
            clsDetail.value = response.data[0];
            weekTimePlace.value.first = clsDetail.value.consultTime1 + "(" + clsDetail.value.place + ")"

            if (clsDetail.value.consultTime2 != null) {
              weekTimePlace.value.second = clsDetail.value.consultTime2 + "(" + clsDetail.value.place2 + ")"
            }

            axios
              .post(process.env.VUE_APP_api_url + "/guidance/reserveList", {
                subject: clsDetail.value.className,
              })
              .then((res) => {
                let reservecount = 1;
                res.data.forEach((e) => {
                  e.count = reservecount;
                  reserveList.value.push(e);
                  reservecount += 1
                });
              });
          }

        });
    });

    return {
      clsDetail,
      GoListPage,
      reserveList,
      openReplyWindow,
      weekTimePlace
    };
  },
};
</script>
