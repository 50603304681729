<template>
    <div class="flex text-center">
        <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
        <div class="text-2xl font-black">
            滿意度問卷
        </div>
    </div>
    <div class="mt-6">

        <ul class="ml-6 text-left list-disc">
            <li v-for="notice in notices" :key="notice.order" v-html="notice.content"></li>
        </ul>

        <div class="my-4 p-3 bg-basic-100 rounded-xl shadow-md">
            <div class="my-2 m-auto flex">
                <div style="flex-grow:1;">

                </div>

                <div class="text-base" style="flex-shrink: 0;">
                    非常同意 同意 沒意見 不同意 非常不同意
                </div>
            </div>


            <div v-for="(question, index) in questions" :key="index" class="my-2 m-auto bg-white rounded-xl">
                <div v-if="question.type === 'radio'" class="flex items-center">
                    <div class=" flex grow" style="flex-grow:1;">
                        <div class="p-1 px-2 pl-6 flex text-base text-left" style="text-indent: -1em;">
                            {{ question.order }}. {{ question.question }}
                        </div>
                    </div>
                    <div style="flex-shrink: 0;">
                        <input v-model="reply[index]" class="m-5 text-gray-500 border-gray-500 focus:ring-gray-500"
                            type="radio" :name="'satisfy' + question.order" value="5">
                        <input v-model="reply[index]" class="m-5 text-gray-500 border-gray-500 focus:ring-gray-500"
                            type="radio" :name="'satisfy' + question.order" value="4">
                        <input v-model="reply[index]" class="m-5 text-gray-500 border-gray-500 focus:ring-gray-500"
                            type="radio" :name="'satisfy' + question.order" value="3">
                        <input v-model="reply[index]" class="m-5 text-gray-500 border-gray-500 focus:ring-gray-500"
                            type="radio" :name="'satisfy' + question.order" value="2">
                        <input v-model="reply[index]" class="m-5 text-gray-500 border-gray-500 focus:ring-gray-500"
                            type="radio" :name="'satisfy' + question.order" value="1">
                    </div>
                </div>
                <div v-if="question.type === 'text'">
                    <div class="pt-5 p-1 px-2 pl-6 flex items-center text-base text-left" style="text-indent: -1em;">
                        {{ question.order }}. {{ question.question }}
                    </div>
                    <div>
                        <textarea v-model="reply[index]" name="" id="" class="w-11/12 h-36 rounded-lg mt-2"></textarea>
                    </div>
                </div>
            </div>


        </div>

        <div class="flex gap-4">
            <button class="w-full rounded-xl mt-3 p-1 bg-mark-orange text-white">暫存</button>
            <button @click="submitSatis()" class="w-full rounded-xl mt-3 p-1 bg-main-second text-white">送出</button>
        </div>
    </div>
</template>
  
<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
    setup() {
        const questions = ref([]);
        const notices = ref([])
        const reply = ref([])

        const satis_data = ref({
            reply,
            stuId: JSON.parse(sessionStorage.getItem("stuId")).toString(),
            sem: JSON.parse(sessionStorage.getItem("sem")),
            groypId: JSON.parse(sessionStorage.getItem("groupId")).toString(),
        })

        onMounted(() => {
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/showquestion")
                .then((response) => {
                    questions.value = response.data;
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getnotice")
                .then((response) => {
                    const allNotices = response.data;
                    notices.value = allNotices.filter((notice) => notice.type === "滿意度問卷");
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });

        const submitSatis = () => {

            axios.post(process.env.VUE_APP_api_url + "/studygroup/updatesatis", satis_data.value).then((response) => {
                if (response.data.insert == "finished") {
                    alert("新增成功")
                    location.reload()
                }
            })

        }

        return {
            questions,
            notices,
            reply,
            submitSatis,

        };
    },
};
</script>
  