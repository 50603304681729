<template>
    <div class="p-6 mr-6 mb-6 bg-white rounded-2xl shadow-lg">
        <div class="flex text-center">
            <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
            <div class="text-xl font-black">
                小組及個人資料
            </div>
        </div>
        <div class="mt-6 grid grid-cols-4 grid-rows-1 gap-4">
            <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer" href="/group">
                <div class="m-auto p-4 text-xl font-bold">
                    <div>組員資料</div>
                    <div class="h-20 flex items-center">
                        <img src="../../../assets/group/lookup-member.png" alt="" class="m-auto">
                    </div>
                </div>
            </a>

            <!-- <div class="m-1 mx-5 bg-basic-200" style="width:2px;"></div> -->

            <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer" href="/group">
                <div class="m-auto p-4 text-xl font-bold">
                    <div>連動帳戶查詢</div>
                    <div class="h-20 flex items-center">
                        <img src="../../../assets/group/lookup-money.png" class="m-auto">
                    </div>
                </div>
            </a>

            <!-- <div class="m-1 mx-5 bg-basic-200" style="width:2px;"></div> -->

            <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer" href="/group">
                <div class="m-auto p-4 text-xl font-bold">
                    <div>本學期總審核結果</div>
                    <div class="h-20 flex items-center">
                        <img src="../../../assets/group/lookup-first.png" alt="" class="m-auto">
                    </div>
                </div>
            </a>

            <!-- <div class="m-1 mx-5 bg-basic-200" style="width:2px;"></div> -->

            <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer" href="/group">
                <div class="m-auto p-4 text-xl font-bold">
                    <div>歷史繳交紀錄</div>
                    <div class="h-20 flex items-center">
                        <img src="../../../assets/group/lookup-history.png" alt="" class="m-auto">
                    </div>
                </div>
            </a>

        </div>
    </div>
    <div class="p-6 mr-6 mb-6 bg-white rounded-2xl shadow-lg">
        <div class="flex">
            <div class="flex-1" style="flex-grow: 1;">
                <div class="flex text-center">
                    <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
                    <div class="text-xl font-black">
                        讀書會規範及歷年資料
                    </div>
                </div>
                <div class="mt-6 grid grid-cols-2 grid-flow-row gap-6">
                    <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer"
                        href="/group">
                        <div class="m-auto p-4 text-xl font-bold">
                            <div>同儕精進讀書會實施專案</div>
                            <div class="h-20 flex items-center">
                                <img src="../../../assets/group/ruling.png" alt="" class="m-auto w-16">
                            </div>
                        </div>
                    </a>
                    <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer"
                        href="/group">
                        <div class="m-auto p-4 text-xl font-bold">
                            <div>同儕精進讀書會作業規範</div>
                            <div class="h-20 flex items-center">
                                <img src="../../../assets/group/rules.png" alt="" class="m-auto w-16">
                            </div>
                        </div>
                    </a>
                    <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer"
                        href="/group">
                        <div class="m-auto p-4 text-xl font-bold">
                            <div> 讀書會Q&A</div>
                            <div class="h-20 flex items-center">
                                <img src="../../../assets/group/qa.png" alt="" class="m-auto w-16">
                            </div>
                        </div>
                    </a>
                    <a class="flex items-center h-full bg-basic-100 rounded-3xl hover:shadow-lg cursor-pointer"
                        href="/group">
                        <div class="m-auto p-4 text-xl font-bold">
                            <div>歷年通過審核資料賞析</div>
                            <div class="h-20 flex items-center">
                                <img src="../../../assets/group/history.png" alt="" class="m-auto w-16">
                            </div>
                        </div>
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
</script>