<template>
  <AdminInfo v-if="IsAdmin"></AdminInfo>
  <AdminLogin v-else></AdminLogin>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted, computed } from "@vue/runtime-core";
import { useCookies } from "vue3-cookies";
import AdminLogin from "./AdminLogin.vue";
import AdminInfo from "./AdminInfo.vue";
import store from "@/store/index.js";
import axios from "axios";

export default {
  components: { AdminLogin, AdminInfo },
  setup() {
    const goBack = () => {
      history.back();
    };

    const IsAdmin = computed(() => {
      return store.state.IsAdmin;
    });

    const router = useRouter();
    const goToVerifyPage = (dest) => {
      router.push({
        path: "/sladmin/" + dest,
      });
    };

    const { cookies } = useCookies();
    onMounted(() => {
      setTimeout(() => {
        const loader = document.getElementById("loader");
        if (loader) {
          loader.classList.remove("is-active");
        }
      }, "1000");

      var AdminToken = cookies.get("isAdmin");
      var adminId = cookies.get("adminId");
      if (window.location.href.includes("localhost")) {
        IsAdmin.value = true;
        store.commit("AdminLogin");
      } else if (AdminToken) {
        if (!localStorage.getItem("jwt-token")) {
          axios
            .get(process.env.VUE_APP_api_url + "/login/register", {
              stuId: cookies.get("adminId"),
            })
            .then((response) => {
              localStorage.setItem("jwt-token", response.data.token);
            });
        }
        sessionStorage.setItem(
          "adminId",
          adminId.split(".")[0].replace("s:", "")
        );
        store.commit("AdminLogin");
      } else {
        store.commit("AdminLogout");
      }
    });

    return { goToVerifyPage, goBack, IsAdmin };
  },
};
</script>

<style>
#admin-header {
  background-color: #2db3b3;
  color: #fff;
  padding: 10px;
  font-size: 20px;
}
</style>
