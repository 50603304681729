<template>
    <div class="flex gap-6 m-6 ml-0">
        <div class="p-4 pb-0 bg-white rounded-2xl shadow-lg font-bold text-xl" style="flex-grow: 1;">
            <div>執行進度表</div>
            <el-progress class="mt-4" type="dashboard" stroke-width="10" :percentage="(progressPass / 8) * 100"
                :color="colors"><template #default="{ percentage }">
                    <span class="percentage-value">{{ percentage * 8 / 100 }}/8</span>
                </template></el-progress>
        </div>
        <div class="p-4 pb-0 bg-white rounded-2xl shadow-lg font-bold text-xl" style="flex-grow: 1;">
            <div>心得分享</div>
            <el-progress class="mt-4" type="dashboard" stroke-width="10"
                :percentage="Math.round((feedbackPass[0].length / students.length) * 100)" :color="colors" />
        </div>
        <div class="p-4 pb-0 bg-white rounded-2xl shadow-lg font-bold text-xl" style="flex-grow: 1;">
            <div>滿意度問卷</div>
            <el-progress class="mt-4" type="dashboard" stroke-width="10"
                :percentage="Math.round((feedbackPass[1].length / students.length) * 100)" :color="colors" />
        </div>
        <div class="p-4 pb-0 bg-white rounded-2xl shadow-lg font-bold text-xl" style="flex-grow: 1;">
            <div>整體完成度</div>
            <el-progress class="mt-4" type="dashboard" stroke-width="10"
                :percentage="Math.round((progressPass + (noReward == 0 ? 1 : 0) + (noSelfeval == 0 ? 1 : 0) + (feedbackPass[0].length) + (feedbackPass[1].length)) / (10 + (students.length * 2)) * 100)"
                :color="colors" />
            <!-- <div class="text-6xl">87%</div> -->
        </div>
    </div>

    <div v-for="(gro, index) in  group " :key="index" class="p-6 mr-6 mb-6 bg-white rounded-2xl shadow-lg">
        <div class="flex">
            <div class="flex-1">
                <div class="flex text-center">
                    <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
                    <div class="text-xl font-black">
                        小組資訊
                    </div>
                </div>
                <div class="text-left text-lg">
                    <div class="m-4 font-bold">指導老師：{{ gro.teacher }}</div>
                    <div class="m-4 font-bold">讀書會性質：</div>
                    <div class="m-4 flex flex-wrap">
                        <div v-for="(type, index) in group_types" :key="index">
                            <label class="mx-3 my-2 flex items-center">
                                <input
                                    class="text-left rounded border-2 border-mark-grassGreen text-mark-grassGreen focus:ring-mark-grassGreen"
                                    type="checkbox" name="" id="" onclick="return false;" :checked="TypeInTypes(type)">
                                <div class="ml-1">{{ type }}</div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-1 mx-5 bg-basic-200" style="width:2px;"></div>

            <div class="flex-1">
                <div class="flex text-center">
                    <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
                    <div class="text-xl font-black">
                        選讀書籍
                    </div>
                </div>

                <div>
                    <div v-for="(book, index) in gro.booklist.split(',')" :key="index"
                        class="py-1 mx-6 my-4 bg-main-second text-white rounded-3xl text-lg">
                        {{ book }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-for="( gro, index ) in  group " :key="index" class="p-6 mr-6 mb-6 bg-white rounded-2xl shadow-lg">
        <div class="flex text-center">
            <div class="m-auto mx-2 bg-main-primary" style="width:3px; height: 24px;"></div>
            <div class="text-xl font-black">
                小組目標
            </div>
        </div>
        <div class="mx-2 p-4 text-lg text-left" style="text-indent: 2em;">
            {{ gro.group_goal }}
        </div>
    </div>

    <div class="p-6 mr-6 mb-6 bg-white rounded-2xl shadow-lg">
        <MemberProgress></MemberProgress>
    </div>
</template>
<script>
import MemberProgress from './MemberProgress.vue'
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
    components: {
        MemberProgress,
    },
    setup() {
        const group = ref([])
        const students = ref([])
        const progress = ref([])
        const progressPass = ref(1)
        const feedback = ref([])
        const feedbackPass = ref([[], []])
        const noReward = ref(1)
        const noSelfeval = ref([])
        const group_types = ['學術專業', '品格培養', '創意思考', '國際世界觀', '跨域學習',]
        const groupId = JSON.parse(sessionStorage.getItem("groupId"))
        const TypeInTypes = (type) => {
            return group.value[0].group_type.includes(type);
        };

        onMounted(() => {


            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getgroup")
                .then((response) => {
                    const allGroups = response.data;
                    group.value = allGroups.filter((group) => group.id == groupId);
                    noSelfeval.value = allGroups.filter((group) => group.id == groupId && (group.selfeval == "" || group.selfeval == null));
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getprogress")
                .then((response) => {
                    const allprogress = response.data;
                    progress.value = allprogress.filter((pro) => pro.group_id == groupId);
                    progressPass.value = (allprogress.filter((pro) => pro.group_id == groupId && pro.state == 2)).length
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getmember")
                .then((response) => {
                    const allmem = response.data;
                    students.value = allmem.filter((stu) => stu.group_id == groupId);
                    noReward.value = (students.value.filter((stu) => stu.reward == null || stu.reward == 0)).length
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getfeedback")
                .then((response) => {
                    const allfeedback = response.data;
                    feedback.value = allfeedback.filter((back) => back.group_id == groupId);
                    for (let i = 0; i < feedback.value.length; i++) {
                        if (feedback.value[i].reflection != null && feedback.value[i].reflection != "") {
                            feedbackPass.value[0].push(feedback.value[i].student_id)
                        }
                        if (feedback.value[i].satisfaction != null && feedback.value[i].satisfaction != "") {
                            feedbackPass.value[1].push(feedback.value[i].student_id)
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });


        return {
            group,
            students,
            progress,
            progressPass,
            feedback,
            feedbackPass,
            noReward,
            noSelfeval,
            group_types,
            TypeInTypes,
        };
    },
}
</script>