<template>
    <div v-for="(stu, index) in students" :key="index">
        <div class="text-xl text-main-primary text-left">
            我的進度
        </div>
        <ul v-for="(back, index) in feedback" :key="index">
            <li v-if="stu.identity == '組長'" class="m-3 bg-basic-200 shadow rounded">
                <label v-for="(gro, index) in group" :key="index" class="m-3 flex items-center">
                    <input
                        class="text-left rounded border-2 border-mark-grassGreen text-mark-grassGreen focus:ring-mark-grassGreen"
                        type="checkbox" name="" id="" onclick="return false;"
                        :checked="gro.selfeval != null && gro.selfeval != ''">
                    <div class="ml-1">自評表</div>
                </label>
            </li>
            <li class="m-3 bg-basic-200 shadow rounded">
                <label class="m-3 flex items-center">
                    <input
                        class="text-left rounded border-2 border-mark-grassGreen text-mark-grassGreen focus:ring-mark-grassGreen"
                        type="checkbox" name="" id="" onclick="return false;"
                        :checked="back.reflection != null && back.reflection != ''">
                    <div class="ml-1">心得分享</div>
                </label>
            </li>
            <li class="m-3 bg-basic-200 shadow rounded">
                <label class="m-3 flex items-center">
                    <input
                        class="text-left rounded border-2 border-mark-grassGreen text-mark-grassGreen focus:ring-mark-grassGreen"
                        type="checkbox" name="" id="" onclick="return false;"
                        :checked="back.satisfaction != null && back.satisfaction != ''">
                    <div class="ml-1">滿意度問卷</div>
                </label>
            </li>
        </ul>

        <ul v-if="feedback == null || feedback == ''">
            <li v-if="stu.identity == '組長'" class="m-3 bg-basic-200 shadow rounded">
                <label v-for="(gro, index) in group" :key="index" class="m-3 flex items-center">
                    <input
                        class="text-left rounded border-2 border-mark-grassGreen text-mark-grassGreen focus:ring-mark-grassGreen"
                        type="checkbox" name="" id="" onclick="return false;"
                        :checked="gro.selfeval != null && gro.selfeval != ''">
                    <div class="ml-1">自評表</div>
                </label>
            </li>
            <li class="m-3 bg-basic-200 shadow rounded">
                <label class="m-3 flex items-center">
                    <input
                        class="text-left rounded border-2 border-mark-grassGreen text-mark-grassGreen focus:ring-mark-grassGreen"
                        type="checkbox" name="" id="" onclick="return false;" :checked="false">
                    <div class="ml-1">心得分享</div>
                </label>
            </li>
            <li class="m-3 bg-basic-200 shadow rounded">
                <label class="m-3 flex items-center">
                    <input
                        class="text-left rounded border-2 border-mark-grassGreen text-mark-grassGreen focus:ring-mark-grassGreen"
                        type="checkbox" name="" id="" onclick="return false;" :checked="false">
                    <div class="ml-1">滿意度問卷</div>
                </label>
            </li>
        </ul>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
    setup() {
        const students = ref([])
        const group = ref([])
        const feedback = ref([])
        onMounted(() => {
            const id = JSON.parse(sessionStorage.getItem("stuId"))
            const groupId = JSON.parse(sessionStorage.getItem("groupId"))
            const sem = JSON.parse(sessionStorage.getItem("sem"))

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getgroup")
                .then((response) => {
                    const allGroups = response.data;
                    group.value = allGroups.filter((group) => group.id == groupId);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getmember")
                .then((response) => {
                    const allmem = response.data;
                    students.value = allmem.filter((stu) => stu.id == id && stu.semester == sem);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });

            axios
                .get(process.env.VUE_APP_api_url + "/studygroup/getfeedback")
                .then((response) => {
                    const allfeedback = response.data;
                    feedback.value = allfeedback.filter((back) => back.student_id == id && back.semester == sem);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        });


        return {
            students,
            group,
            feedback,
        };
    },
};
</script>

