<template>
  <div class="common-layout">
    <el-container>
      <!-- Sidebar -->
      <el-aside width="250px" class="border-r-2">
        <div class="mt-5 px-4 py-6 rounded-lg">
          <i class="pi pi-chart-line mb-4" style="font-size: 3rem"></i>
          <h2 class="title-font font-medium text-2xl text-gray-900">
            後台管理
          </h2>
          <p class="leading-relaxed">Dashboard</p>
        </div>
        <ul>
          <li v-for="item in menuItems" :key="item.route" class="w-full">
            <a
              @click="goToManagerPage(item.route)"
              class="adminLink text-lg inline-block p-4 w-full hover:text-gray-70 rounded-r-lg hover:bg-gray-200 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <i :class="[item.icon, 'mr-2']"></i>
              {{ item.name }}
            </a>
          </li>
          <li class="w-full">
            <a
              @click="Logout"
              class="adminLink text-lg inline-block p-4 w-full bg-red-500 text-white hover:bg-red-600 rounded-r-lg focus:ring-4 focus:ring-red-300 focus:outline-none"
            >
              <i class="pi pi-sign-out mr-2"></i>
              登出
            </a>
          </li>
        </ul>
      </el-aside>

      <!-- Main content -->
      <el-main>
        <!-- Dashboard content -->
        <div class="flex-1 overflow-y-auto m-3 mt-8">
          <!-- Stats grid -->
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8"
          >
            <div
              v-for="(stat, index) in stats"
              :key="index"
              :class="[
                'rounded-lg shadow-md p-6 transition duration-300 ease-in-out transform hover:scale-105',
                gradientColors[index],
              ]"
            >
              <div class="flex items-center justify-between mb-4">
                <div class="p-3 bg-white bg-opacity-20 rounded-full">
                  <i :class="[stat.icon, 'text-white text-2xl']"></i>
                </div>
                <span
                  :class="[
                    'text-sm font-semibold',
                    stat.change > 0 ? 'text-green-300' : 'text-red-300',
                  ]"
                  v-if="stat.change !== null"
                >
                  {{ stat.change > 0 ? "+" : "" }}{{ stat.change }}%
                </span>
              </div>
              <h2 class="text-3xl font-bold mb-2 text-white">
                {{ stat.value }}
              </h2>
              <p class="text-white text-opacity-80">{{ stat.title }}</p>
            </div>
          </div>

          <!-- Login records table -->
          <div class="bg-white rounded-lg shadow-md p-6 mb-8">
            <h2 class="text-2xl font-bold mb-6 text-gray-800 flex items-center">
              <i class="pi pi-users mr-3 text-teal-600"></i>
              使用者登入記錄
            </h2>
            <DataTable
              :value="loginRecord"
              :paginator="true"
              :rows="10"
              :filters="filters"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              responsiveLayout="scroll"
              currentPageReportTemplate="顯示 {first} 到 {last} 筆，共 {totalRecords} 筆"
              class="p-datatable-sm"
            >
              <Column field="ip_addr" header="IP" style="width: 15%"></Column>
              <Column field="userid" header="帳號" style="width: 15%"></Column>
              <Column
                field="username"
                header="姓名"
                style="width: 10%"
              ></Column>
              <Column field="type" header="類型" style="width: 12%">
                <template #body="{ data }">
                  <span
                    :class="[
                      'px-2 py-1 rounded-full text-xs font-semibold',
                      data.type == 'LOGIN'
                        ? 'bg-teal-100 text-teal-800'
                        : 'bg-blue-100 text-blue-800',
                    ]"
                  >
                    {{ data.type == "LOGIN" ? "一般登入" : "管理員登入" }}
                  </span>
                </template>
              </Column>
              <Column field="status" header="登入狀態" style="width: 15%">
                <template #body="{ data }">
                  <span
                    :class="[
                      'px-2 py-1 rounded-full text-xs font-semibold',
                      data.status != 'FAILED'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800',
                    ]"
                  >
                    {{ data.status != "FAILED" ? "登入成功" : "登入失敗" }}
                  </span>
                </template>
              </Column>
              <Column
                field="time"
                header="登入時間"
                style="width: 20%"
              ></Column>
            </DataTable>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import store from "@/store/index.js";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "axios";
import moment from "moment";

const router = useRouter();
const sidebarOpen = ref(true);

const goToManagerPage = (dest) => {
  router.push({ path: `/sladmin/${dest}` });
};

const Logout = () => {
  store.commit("AdminLogout");
  location.href = "/sladmin";
};

const lastMonthCount = ref(0);
const today = moment();
const loginMonth = ref(0);
const loginRecord = ref([]);
const loginRate = ref(0);
const filters = ref({});

const menuItems = [
  { name: "獎勵申請", icon: "pi pi-pencil", route: "awardlist" },
  { name: "線上輔導預約", icon: "pi pi-file", route: "guidance" },
  { name: "課程許願池", icon: "pi pi-book", route: "wishlist" },
  { name: "課程評價", icon: "pi pi-book", route: "feedback/course" },
  { name: "系統設置", icon: "pi pi-sliders-h", route: "setting" },
];

const stats = ref([
  { icon: "pi pi-users", title: "累積上站人數", value: 0, change: null },
  { icon: "pi pi-calendar", title: "本月上站人數", value: 0, change: null },
  { icon: "pi pi-id-card", title: "專業證照申請人數", value: 0, change: null },
  {
    icon: "pi pi-calendar-plus",
    title: "預約輔導申請人數",
    value: 0,
    change: null,
  },
]);

const gradientColors = [
  "bg-gradient-to-br from-teal-400 to-teal-600",
  "bg-gradient-to-br from-green-400 to-green-600",
  "bg-gradient-to-br from-blue-400 to-blue-600",
  "bg-gradient-to-br from-indigo-400 to-indigo-600",
];

onMounted(() => {
  sidebarOpen.value = window.innerWidth >= 1024;
  window.addEventListener("resize", () => {
    sidebarOpen.value = window.innerWidth >= 1024;
  });

  setTimeout(() => {
    const lastMonth = moment().subtract(1, "months");
    axios
      .post(process.env.VUE_APP_api_url + "/admin/getRecord")
      .then((data) => {
        data.data.forEach((item) => {
          item.time = moment(item.time).format("YYYY-MM-DD HH:mm:ss");
          const date = moment({
            year: moment(item.time).year(),
            month: moment(item.time).month(),
          });
          if (date.isSame(today, "month")) {
            loginMonth.value++;
          }
          if (date.isSame(lastMonth, "month")) {
            lastMonthCount.value++;
          }
          loginRecord.value.push(item);
        });
        loginRate.value = (
          ((loginMonth.value - lastMonthCount.value) / lastMonthCount.value) *
          100
        ).toFixed(0);
        stats.value[0].value = loginRecord.value.length;
        stats.value[1].value = loginMonth.value;
        stats.value[1].change = loginRate.value;
      });
    axios
      .post(process.env.VUE_APP_api_url + "/awardapply/pro/getcount")
      .then((response) => {
        stats.value[2].value = response.data[0].semCount;
      });
    axios
      .post(process.env.VUE_APP_api_url + "/guidance/getreservecount")
      .then((response) => {
        stats.value[3].value = response.data[0].R_COUNT;
      });
  }, 300);
});
</script>

<style>
.p-datatable .p-datatable-thead > tr > th {
  background-color: #e6f7f7;
  color: #2d5f5f;
  font-weight: 600;
}

.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f0fafa;
}

.p-datatable .p-datatable-tbody > tr:hover {
  background-color: #d1efef;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #2db3b3;
  color: #ffffff;
}

.el-aside {
  background-color: #f0f8f8;
}

.adminLink:hover {
  background-color: #e0f2f2;
}

.el-main {
  background-color: #f9fdfd;
}
</style>
