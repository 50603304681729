<template>
  <div class="text-left">
    <div style="margin: 0 auto; width: 100%" class="bg-gray-200 pb-2">
      <p class="p-3 pt-6" style="
            text-align: center;
            font-size: 23px;
            font-weight: bolder;
            color: #eb3434;
            max-width: 780px;
            margin: 0 auto;
          ">
        預約注意事項
      </p>

      <div class="p-5" style="
            background: rgba(0, 0, 0, 0.08);
            border-radius: 15px;
            display: flex;
            max-width: 780px;
            margin: 0 auto;
          ">
        <div class="align-middle p-2" style="align-self: center">
          <img class="warning-img" src="../../../assets/number-one.png" />
        </div>
        <div class="flex-1 p-5">
          <h1 class="font-black uppercase truncate title">提供服務時間</h1>
          <p class="mt-2 text-lg" style="max-width: 780px" v-html="warning.first"></p>
        </div>
      </div>
      <div class="p-5" style="
            background: rgba(0, 0, 0, 0.08);
            border-radius: 15px;
            display: flex;
            width: 100%;
            max-width: 780px;
            margin: 10px auto;
          ">
        <div class="align-middle p-2" style="align-self: center">
          <img class="warning-img" src="../../../assets/number-two.png" />
        </div>
        <div class="flex-1 p-5">
          <h1 class="font-black text-lg uppercase truncate title">
            諮詢服務申請應依下列規定
          </h1>
          <p class="mt-2 max-w-lg text-lg" style="max-width: 780px" v-html="warning.second"></p>
        </div>
      </div>

      <div class="p-5" style="
            background: rgba(0, 0, 0, 0.08);
            border-radius: 15px;
            display: flex;
            width: 100%;
            max-width: 780px;
            margin: 10px auto;
          ">
        <div class="align-middle p-2" style="align-self: center">
          <img src="../../../assets/number-three.png" class="warning-img" />
        </div>
        <div class="flex-1 p-5">
          <h1 class="font-black text-lg uppercase truncate title">
            檢視&取消申請
          </h1>
          <p class="mt-2 text-lg" v-html="warning.third"></p>
        </div>
      </div>

      <div class="p-5" style="
            background: rgba(0, 0, 0, 0.08);
            border-radius: 15px;
            display: flex;
            width: 100%;
            max-width: 780px;
            margin: 10px auto;
          ">
        <div class="align-middle p-2" style="align-self: center">
          <img class="warning-img" src="../../../assets/number-four.png" />
        </div>
        <div class="flex-1 p-5">
          <h1 class="font-black text-lg uppercase truncate title">注意事項</h1>
          <p class="mt-2 text-lg max-w-lg" v-html="warning.four"></p>
        </div>
      </div>

      <div class="text-center">
        <input type="checkbox" v-model="Agree" />
        閱讀完成，同意上述規定與聲明
      </div>
    </div>

    <div>
      <button @click="goToReserve" class="next-step w-full" style="padding: 10px; font-size: 20px">
        <span>
          <span class="addAward mr-2">下一步</span>
          <i class="pi pi-chevron-circle-right"></i>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { computed, onMounted, ref } from "@vue/runtime-core";
import store from "@/store";
import axios from "axios";
import moment from "moment";

export default {
  setup() {
    const isAgree = computed(() => {
      return store.state.GuidanceAgree;
    });

    const warning = ref({
      state: 0,
      first: "",
      second: "",
      third: "",
      four: "",
    });

    const opendate = ref({
      start_date: "",
      end_date: "",
    });

    const stuInfo = ref({});
    onMounted(() => {
      stuInfo.value = JSON.parse(sessionStorage.getItem("stuInfo"));
      if (isAgree.value) {
        router.push({
          path: "/counselor/reservepanel",
        });
      }

      axios
        .post(process.env.VUE_APP_api_url + "/guidance/warning", {
          typename: "guidance",
        })
        .then((response) => {
          opendate.value.start_date = response.data[0].start_date;
          opendate.value.end_date = response.data[0].end_date;
          sessionStorage.setItem(
            "guidance_date",
            JSON.stringify(opendate.value)
          );

          const warningInfo = JSON.parse(response.data[0].warning.split(","));
          warning.value.state = response.data[0].state;
          warning.value.first = warningInfo.first;
          warning.value.second = warningInfo.second;
          warning.value.third = warningInfo.third;
          warning.value.four = warningInfo.four;
        });
    });

    const Agree = ref(false);

    const router = useRouter();

    const goToReserve = () => {
      if (Agree.value && warning.value.state == 1) {
        let status = moment(moment()).isBetween(
          opendate.value.start_date,
          opendate.value.end_date
        );
        if (!status) {
          alert("非申請期間！");
        } else {
          store.commit("GuidanceAgree");
          router.push({
            path: "/counselor/reservepanel",
          });
        }
      } else if (warning.value.state == 1) {
        alert("非申請期間！");
      } else {
        alert("請先閱讀完注意事項！");
      }
    };

    return { goToReserve, Agree, warning };
  },
};
</script>
