<template>
  <div
    class="bg-white p-5 text-center"
    style="width: 30% !important; border-top: 10px solid #2db3b3"
  >
    <form
      id="LoginForm"
      action="https://mobile.cycu.edu.tw/api/sso/itouch_login/login1.jsp"
    >
      <div>
        <span class="login-title">帳號：</span
        ><InputText
          type="text"
          name="UserNm"
          v-model="UserNm"
          class="w-64"
          placeholder="學號"
        />
      </div>
      <div class="mt-2">
        <span class="login-title">密碼：</span>
        <Password
          id="UserPasswd"
          class="w-64"
          toggleMask
          :feedback="false"
          type="password"
          name="UserPasswd"
          v-model="UserPasswd"
          placeholder="密碼"
        />
      </div>

      <input type="hidden" name="returnPath" v-model="login_url" />
      <input type="hidden" name="failPath" v-model="error_url" />

      <button type="button" @click="Login()" class="login-button" id="Login">
        登入
      </button>
    </form>
  </div>
</template>

<script setup>
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import { ref } from "vue";

var login_url = "https://selflearning1.cycu.edu.tw/api/login/wishLogin";
var error_url = "https://selflearning1.cycu.edu.tw/api/login/error";

var UserNm = ref("");
var UserPasswd = ref("");

const Login = () => {
  if (UserNm.value.length != 8) {
    alert("非學生無法登入！");
  } else {
    document.getElementById("Login").innerHTML = "登入中...";
    document.getElementById("Login").disabled = true;
    setTimeout(() => {
      document.getElementById("LoginForm").submit();
    }, "1200");
  }
};
</script>

<style scope>
.p-inputtext {
  width: 100%;
}
</style>
