<template>
  <div id="loader" class="loader loader-border">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img src="../assets/Spin-1s-200px.gif" alt="Loading" />
        </div>
        <p class="loader-text">頁面讀取中...</p>
      </div>
    </div>
  </div>
  <div class="mx-auto text-center" style="margin-top: 15px">
    <a href="/wishlist/review" id="wishlist-area" aria-label="View Wishlist">
      <img
        alt="wish-banner"
        src="../assets/wish-banner.png"
        width="320"
        class="mx-auto text-center"
      />
    </a>
  </div>
  <div style="margin-top: 90px">
    <h1 class="text-4xl">學生登入</h1>
    <p style="margin-top: -5px">Login</p>
    <div class="LoginPanel">
      <span class="text-red-600">請使用iTouch帳號密碼登入</span>
      <form
        id="LoginForm"
        action="https://mobile.cycu.edu.tw/api/sso/itouch_login/login1.jsp"
      >
        <div>
          <span class="login-title">帳號：</span
          ><input
            type="text"
            name="UserNm"
            v-model="UserNm"
            class="input-area"
            placeholder="學號"
          />
        </div>
        <div>
          <span class="login-title">密碼：</span>
          <input
            id="UserPasswd"
            type="password"
            class="input-area"
            name="UserPasswd"
            v-model="UserPasswd"
            placeholder="密碼"
          />
          <i
            @click="togglePassword(showPwd)"
            id="togglePassword"
            class="eye-icon pi pi-eye"
          ></i>
        </div>

        <input type="hidden" name="returnPath" v-model="login_url" />
        <input type="hidden" name="failPath" v-model="error_url" />

        <button type="button" @click="Login()" class="login-button" id="Login">
          登入
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    var showPwd = ref(true);
    var login_url = "https://selflearning1.cycu.edu.tw/api/login/userLogin";
    var error_url = "https://selflearning1.cycu.edu.tw/api/login/error";

    const togglePassword = () => {
      showPwd.value = !showPwd.value;
      if (showPwd.value) {
        document.getElementById("UserPasswd").setAttribute("type", "text");
        document.querySelector(".eye-icon").classList.remove("pi-eye");
        document.querySelector(".eye-icon").classList.add("pi-eye-slash");
      } else {
        document.getElementById("UserPasswd").setAttribute("type", "password");
        document.querySelector(".eye-icon").classList.add("pi-eye");
        document.querySelector(".eye-icon").classList.remove("pi-eye-slash");
      }
    };

    setTimeout(() => {
      const loader = document.getElementById("loader");
      if (loader) {
        loader.classList.remove("is-active");
      }
    }, "1000");

    var UserNm = ref("");
    var UserPasswd = ref("");
    const Login = () => {
      if (UserNm.value.length != 8) {
        alert("非學生無法登入！");
      } else {
        document.getElementById("Login").innerHTML = "登入中...";
        document.getElementById("Login").disabled = true;
        setTimeout(() => {
          document.getElementById("LoginForm").submit();
        }, "1200");
      }
    };

    return {
      Login,
      UserNm,
      UserPasswd,
      togglePassword,
      showPwd,
      login_url,
      error_url,
    };
  },
};
</script>
