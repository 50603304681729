<template>
  <div id="submitLoader" class="loader loader-border">
    <div class="loader-area">
      <div class="loader-inner">
        <div class="loader-image">
          <img v-if="state == 0" id="image-src" src="../../../../assets/Spin-1s-200px.gif" />
          <img v-else-if="state == 1" id="image-src" src="../../../../assets/success_icon.png" />
          <img v-else-if="state == 2" id="image-src" src="../../../../assets/error_icon.png" />
          <img v-else-if="state == 3" id="image-src" src="../../../../assets/file-upload.gif" />
        </div>
        <p v-if="state == 0" class="loader-text">送出申請中...</p>
        <p v-else-if="state == 1" class="loader-text">申請成功！</p>
        <p v-else-if="state == 2" class="loader-text">申請失敗！</p>
        <p v-else-if="state == 3" class="loader-text">檔案上傳中！</p>
      </div>
    </div>
  </div>
  <div id="extralarge-modal" tabindex="-1"
    class="overflow-y-auto overflow-x-auto fixed mt-2 mb-2 top-50 right-0 left-50 z-50 w-full md:inset-0">
    <div class="relative p-4 w-full h-full md:h-auto">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="flex justify-between items-center p-5 rounded-t border-b dark:border-gray-600">
          <h1 class="title mt-5 mb-5 text-lg">申請注意事項</h1>
          <button type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="extralarge-modal">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
              @click="closeWindow()">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
        </div>
        <div class="mt-8 mb-8 text-xl ml-8 warning p-3" v-html="warning"></div>
        <div
          class="bg-gray-200 flex items-center p-6 pb-9 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600"
          style="height: 25px">
          <button data-modal-toggle="extralarge-modal" type="button" style="
              padding-top: 7.5px;
              padding-bottom: 7.5px;
              margin-bottom: 18px;
            " class="flex-1 w-64 next-step mt-7 font-medium rounded-lg" @click="submit()">
            送出申請
          </button>

          <button data-modal-toggle="extralarge-modal" type="button"
            class="back-btn text-white hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-right dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            @click="closeWindow()">
            返回
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { closeModal } from "jenesius-vue-modal";
import axios from "axios";
import { ref } from "@vue/reactivity";
import { onMounted } from '@vue/runtime-core';

export default {
  props: {
    ApplyData: Object,
    fileUpload: Function,
    changePageIndex: Function,
  },
  setup(props) {
    const state = ref(0);
    const warning = ref("")

    onMounted(() => {
      warning.value = JSON.parse(sessionStorage.getItem("prolicense_notice")).submitWarning;
    })

    const submit = async () => {
      const loader = document.getElementById("submitLoader");
      loader.classList.add("is-active");

      await axios
        .post(
          process.env.VUE_APP_api_url + "/awardapply/pro/insert",
          props.ApplyData
        )
        .then((data) => {
          state.value = 3;
          props.fileUpload(data.data.id).then((response) => {
            setTimeout(() => {
              if (response == true) {
                state.value = 1;
              } else {
                state.value = 2;
              }
              setTimeout(() => {
                loader.classList.remove("is-active");
                props.changePageIndex(0)
              }, "5000");
              setTimeout(() => {
                location.reload()
              }, "3000");
            }, "15000");
          })
        })
    };
    return { submit, state, warning };
  },
  methods: {
    closeWindow: () => {
      closeModal();
    },
  },
};
</script>
