import Index from "../components/group/Index.vue";
import Dashboard from "../components/group/student/Dashboard.vue";
import Login from "../components/group/student/GroupLogin.vue";
import Apply from "../components/group/student/Apply.vue";
import Teacher from "../components/group/teacher/Home.vue";

export const group_route = {
  path: "/group",
  name: "group",
  component: Index,
  children: [
    {
      path: "/group",
      name: "dashboard",
      component: Dashboard,
    },

    {
      path: "/group/login",
      name: "login",
      component: Login,
    },

    {
      path: "/group/apply",
      name: "apply",
      component: Apply,
    },

    {
      path: "/group/teacher",
      name: "teacher",
      component: Teacher,
    },

    // {
    //   path: "/group/teacher",
    //   name: "teacher",
    //   component: Teacher,
    // },
  ],
};
